import apeLogoUrl from "../assets/images/apecoin.svg";
import blastLogoUrl from "../assets/images/blast.svg";
import ethereumLogoUrl from "../assets/images/ethereum-logo.png";
import polygonLogoUrl from "../assets/images/polygon-logo.svg";

export enum SupportedChainId {
  MAINNET = 1,
  SEPOLIA = 11155111,
  MUMBAI = 80001,
  BLAST = 81457,
  RINKEBY = 4,
  ROPSTEN = 3,
  KOVAN = 42,
  POLYGON = 137,
  ARBITRUM = 42161,
  OPTIMISM = 10,
  AVALANCHE = 43114,
  BLAST_SEPOLIA = 168587773,
  CURTIS = 33111,
  APECHAIN = 33139,
}

export const CHAIN_IDS_TO_EXPLORER_NAME: { [key: number]: string } = {
  [SupportedChainId.MAINNET]: "Etherscan",
  [SupportedChainId.SEPOLIA]: "Etherscan",
  [SupportedChainId.APECHAIN]: "ApeChain",
  [SupportedChainId.CURTIS]: "Blockscout",
  [SupportedChainId.POLYGON]: "Polygonscan",
  [SupportedChainId.BLAST]: "blastscan",
  [SupportedChainId.BLAST_SEPOLIA]: "blastscan",
};

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: "mainnet",
  [SupportedChainId.SEPOLIA]: "sepolia",
  [SupportedChainId.APECHAIN]: "apechain",
  [SupportedChainId.CURTIS]: "curtis",
  [SupportedChainId.POLYGON]: "polygon",
  [SupportedChainId.BLAST]: "blast",
  [SupportedChainId.RINKEBY]: "rinkeby",
  [SupportedChainId.ROPSTEN]: "ropsten",
  [SupportedChainId.KOVAN]: "kovan",
  [SupportedChainId.MUMBAI]: "mumbai",
  [SupportedChainId.ARBITRUM]: "arbitrum",
  [SupportedChainId.OPTIMISM]: "optimism",
  [SupportedChainId.AVALANCHE]: "avalanche",
  [SupportedChainId.BLAST_SEPOLIA]: "blast_sepolia",
};

export const CHAIN_NAMES_TO_IDS = {
  mainnet: SupportedChainId.MAINNET,
  sepolia: SupportedChainId.SEPOLIA,
  apechain: SupportedChainId.APECHAIN,
  curtis: SupportedChainId.CURTIS,
  polygon: SupportedChainId.POLYGON,
  blast: SupportedChainId.BLAST,
  blast_sepolia: SupportedChainId.BLAST_SEPOLIA,
};

export type ISupportedChainNames = keyof typeof CHAIN_NAMES_TO_IDS;

export const CYAN_SUPPORTED_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.SEPOLIA,
  SupportedChainId.APECHAIN,
  SupportedChainId.CURTIS,
  SupportedChainId.POLYGON,
  SupportedChainId.BLAST,
  SupportedChainId.BLAST_SEPOLIA,
] as const;

type ChainInfo = {
  readonly [chainId in typeof CYAN_SUPPORTED_CHAIN_IDS[number]]: {
    readonly explorer: string;
    readonly label: string;
    readonly logoUrl?: string;
    reservoirApiUrl?: string;
  };
};

export const CHAIN_INFO: ChainInfo = {
  [SupportedChainId.MAINNET]: {
    explorer: "https://etherscan.io/",
    label: "Ethereum",
    logoUrl: ethereumLogoUrl,
    reservoirApiUrl: "https://api.reservoir.tools",
  },
  [SupportedChainId.SEPOLIA]: {
    explorer: "https://sepolia.etherscan.io/",
    label: "Sepolia",
    logoUrl: ethereumLogoUrl,
    reservoirApiUrl: "https://api-sepolia.reservoir.tools",
  },
  [SupportedChainId.APECHAIN]: {
    explorer: "https://apechain.explorer.caldera.xyz/",
    label: "ApeChain",
    logoUrl: apeLogoUrl,
    reservoirApiUrl: "https://api-apechain.reservoir.tools",
  },
  [SupportedChainId.CURTIS]: {
    explorer: "https://apescan.io/",
    label: "Curtis",
    logoUrl: apeLogoUrl,
    reservoirApiUrl: "https://api-curtis.reservoir.tools",
  },
  [SupportedChainId.POLYGON]: {
    explorer: "https://polygonscan.com/",
    label: "Polygon",
    logoUrl: polygonLogoUrl,
    reservoirApiUrl: "https://api-polygon.reservoir.tools",
  },
  [SupportedChainId.BLAST]: {
    explorer: "https://blastscan.io/",
    label: "Blast",
    logoUrl: blastLogoUrl,
    reservoirApiUrl: "https://api-blast.reservoir.tools",
  },
  [SupportedChainId.BLAST_SEPOLIA]: {
    explorer: "https://sepolia.blastscan.io/",
    label: "Blast Sepolia",
    logoUrl: blastLogoUrl,
    reservoirApiUrl: "https://api-blast-sepolia.reservoir.tools",
  },
};

export const isSupportedChain = (chainId: number | null | undefined) => {
  return !!chainId && CYAN_SUPPORTED_CHAIN_IDS.includes(chainId);
};
