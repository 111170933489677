import { Provider } from "@ethersproject/providers";
import dayjs from "dayjs";
import { BigNumber, BigNumberish, ethers } from "ethers";

import { factories as f } from "@cyanco/contract";

import { notifyVaultBalanceError } from "@/apis/notification";
import { SupportedChainId } from "@/constants/chains";
import { DAY_SECONDS, HOUR_SECONDS, MINUTE_SECONDS } from "@/hooks/usePlanCreationForm";
import { IGetPricerCalculator } from "@/hooks/usePlanCreationForm.types";
import { IPlanCreatableNft } from "@/types";
import { bigNumToFloat, roundDown } from "@/utils";
import { getVaultBalance } from "@/utils/contract";
import { Experiments } from "@/utils/experimentList";

import { IPawn } from "../Account/pawn.types";
import { IBNPL } from "../Bnpl/bnpl.types";

/*
 * Calculating total interest rate and total price
 */
type IItem = {
  price: BigNumber;
  interestRate: number;
};
export const calculateTotals = (items: IItem[]) => {
  const _initialAmounts = { totalPrice: BigNumber.from(0), totalInterestFee: BigNumber.from(0) };
  const { totalPrice, totalInterestFee } = items.reduce((acc, { price, interestRate }) => {
    const interestFee = price.mul(interestRate);
    return {
      totalPrice: acc.totalPrice.add(price),
      totalInterestFee: acc.totalInterestFee.add(interestFee),
    };
  }, _initialAmounts);
  const totalInterestRate = totalInterestFee.div(totalPrice).toNumber();

  return { totalPrice, totalInterestRate };
};

export const getPricerCalculator: IGetPricerCalculator = (planType, args) => {
  // TODO remove autoRepayStatus if it is not part of calculation logic
  return ({ term, totalNumberOfPayments, loanRate, autoRepayStatus }) => {
    const { config, totalPrice, baseInterestRate } = args;

    const selectedMethod = config.find(([_term, _totalNumberOfPayments, , _loanRate]) => {
      return _term === term && _totalNumberOfPayments === totalNumberOfPayments && _loanRate === loanRate;
    });
    if (!selectedMethod) return;

    const [_term, _totalNumberOfPayments, serviceFeeRate, _loanRate, multiplier] = selectedMethod;
    const interestRate = Math.ceil(baseInterestRate * multiplier);
    const downpaymentRate = planType === "bnpl" ? 100_00 - loanRate : 0;
    const counterPaidPayments = planType === "bnpl" ? 1 : 0;
    const { downpaymentAmount, monthlyAmount, totalInterestFee, totalServiceFee } =
      f.PaymentPlanV2Contract.getExpectedPlanSync({
        amount: totalPrice.mul(downpaymentRate + loanRate).div(100_00),
        downpaymentRate,
        interestRate,
        serviceFeeRate,
        totalNumberOfPayments,
      });

    return {
      term,
      autoRepayStatus,
      serviceFeeRate,
      interestRate,
      totalPrice,
      loanRate,
      loaningAmount: totalPrice.mul(loanRate).div(100_00),
      downpaymentRate,
      downpaymentAmount,
      monthlyAmount,
      counterPaidPayments,
      totalNumberOfPayments,
      totalInterestFee,
      totalServiceFee,
    };
  };
};

export const getPaymentInterval = (termInSeconds: number): string => {
  if (termInSeconds >= DAY_SECONDS) {
    const up = Math.ceil(termInSeconds / DAY_SECONDS) - termInSeconds / DAY_SECONDS;
    const down = termInSeconds / DAY_SECONDS - roundDown(termInSeconds / DAY_SECONDS, 0);
    const days = up > down ? roundDown(termInSeconds / DAY_SECONDS, 0) : Math.ceil(termInSeconds / DAY_SECONDS);
    return `${days} ${days === 1 ? "day" : "days"}`;
  } else if (termInSeconds >= HOUR_SECONDS) {
    const hours = Math.ceil(termInSeconds / HOUR_SECONDS);
    return `${hours} ${hours === 1 ? "hour" : "hours"}`;
  } else if (termInSeconds >= MINUTE_SECONDS) {
    const minutes = Math.ceil(termInSeconds / MINUTE_SECONDS);
    return `${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
  } else {
    return `${termInSeconds} seconds`;
  }
};

export const checkIsPriceChanged = (
  initialPrice: BigNumber,
  currentPrice: BigNumber,
  currencyAddress: string,
  decimals: number,
) => {
  if (currencyAddress === ethers.constants.AddressZero) {
    return !initialPrice.eq(currentPrice);
  }
  const priceChangeRate =
    ((bigNumToFloat(currentPrice, decimals) - bigNumToFloat(initialPrice, decimals)) /
      bigNumToFloat(initialPrice, decimals)) *
    100;
  return priceChangeRate > 1; // price can be changed up to 1% except for native currency
};

export const getPlanApr = ({
  term,
  interestRate,
  totalNumberOfPayments,
}: {
  term: number;
  interestRate: number;
  totalNumberOfPayments: number;
}) => {
  return ((365 / ((term * totalNumberOfPayments) / 60 / 60 / 24)) * interestRate) / 100;
};

export const getPlanInterestRate = ({
  loanValue,
  totalCost,
  decimal,
}: {
  totalCost: BigNumberish;
  loanValue: BigNumberish; // bnpl purchase price or pawned amount
  decimal: number;
}) => {
  return Math.round((bigNumToFloat(totalCost, decimal) / bigNumToFloat(loanValue, decimal) - 1) * 100_00);
};

export const getDueForPayment = ({
  paymentNumber,
  createdAt,
  isBnpl,
  term,
}: {
  paymentNumber: number;
  createdAt: Date;
  isBnpl: boolean;
  term: number;
}) => {
  const _paymentNumber = !isBnpl ? paymentNumber + 1 : paymentNumber;
  const dueDate = dayjs(createdAt).add(term * _paymentNumber, "second");
  const difference = dueDate.diff(dayjs(new Date()), "second");
  return getPaymentInterval(difference);
};

export const checkVaultBalance = async (
  paymentPlans: Array<
    IPlanCreatableNft & {
      vaultAddress: string;
      loanAmount: BigNumber;
    }
  >,
  planType: "Pawn" | "BNPL",
  provider: Provider,
  requestedWallet: string,
) => {
  if (!paymentPlans.length) return;
  const plansByVaults = paymentPlans.reduce<{
    [key: string]: Array<
      IPlanCreatableNft & {
        vaultAddress: string;
        loanAmount: BigNumber;
      }
    >;
  }>((acc, cur) => {
    const vault = acc[cur.vaultAddress];
    return vault
      ? {
          ...acc,
          [cur.vaultAddress]: [...vault, cur],
        }
      : {
          ...acc,
          [cur.vaultAddress]: [cur],
        };
  }, {});
  let hasLowBalance = false;
  for (const vaultAddress of Object.keys(plansByVaults)) {
    const vaultPlans = plansByVaults[vaultAddress];
    const vaultTotalLoanAmount = vaultPlans.reduce((acc, cur) => acc.add(cur.loanAmount), BigNumber.from(0));
    const vaultBalance = await getVaultBalance({ vaultAddress, provider });
    if (vaultBalance.lt(vaultTotalLoanAmount)) {
      const requestedItems = vaultPlans.reduce<{ [key: string]: string[] }>((acc, cur) => {
        const item = acc[cur.collectionName];
        return item
          ? { ...acc, [cur.collectionName]: [...item, cur.tokenId] }
          : { ...acc, [cur.collectionName]: [cur.tokenId] };
      }, {});
      hasLowBalance = true;
      await notifyVaultBalanceError({
        vaultAddress,
        vaultBalance,
        loanAmount: vaultTotalLoanAmount,
        planType,
        requestedItems: JSON.stringify(requestedItems, null, "\t").replaceAll('],\n\t"', '],\n\n\t"'),
        requestedWallet,
      });
    }
  }
  if (hasLowBalance) {
    throw new Error("Not enough balance in the Vault");
  }
};

// Check if there is any item with interest rate >= 10000 (100%)
const EXPENSIVE_ITEM_INTEREST_RATE_THRESHOLD = 10000;

export const hasExpensiveItems = (items: { interestRate: number }[]) => {
  return items.some(({ interestRate }) => interestRate >= EXPENSIVE_ITEM_INTEREST_RATE_THRESHOLD);
};

export const isExpensiveItem = ({ interestRate }: { interestRate?: number }) => {
  return !!interestRate && interestRate >= EXPENSIVE_ITEM_INTEREST_RATE_THRESHOLD;
};

export const getInterestRate = ({
  payAmount,
  principleAmount,
  decimals,
}: {
  principleAmount: BigNumber;
  payAmount: BigNumber;
  decimals: number;
}) => {
  return (bigNumToFloat(payAmount, decimals) / bigNumToFloat(principleAmount, decimals) - 1) * 100_00;
};

export const getApr = ({ maturity, interestRate }: { maturity: number; interestRate: number }) => {
  return (365 * 24 * 6 * 6 * interestRate) / maturity;
};

export const getPlanPrincipleAmount = (plan: IPawn | IBNPL) => {
  const isBnpl = plan.planType === "BNPL";
  return isBnpl
    ? BigNumber.from(plan.price)
        .mul(100_00 - plan.downpaymentPercent)
        .div(100_00)
    : BigNumber.from(plan.pawnedAmount);
};

export const autoLiquidationExperimentFlag = (chainId: SupportedChainId) => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return Experiments.AUTO_LIQUIDATION_MAINNET;
    case SupportedChainId.SEPOLIA:
      return Experiments.AUTO_LIQUIDATION_SEPOLIA;
    case SupportedChainId.POLYGON:
      return Experiments.AUTO_LIQUIDATION_POLYGON;
    case SupportedChainId.BLAST:
      return Experiments.AUTO_LIQUIDATION_BLAST;
    case SupportedChainId.BLAST_SEPOLIA:
      return Experiments.AUTO_LIQUIDATION_BLAST_SEPOLIA;
    default:
      return Experiments.AUTO_LIQUIDATION_MAINNET;
  }
};
