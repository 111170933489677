import { Check } from "react-feather";
import styled from "styled-components";

import { Button, Card, Flex, SkeletonLine, SystemMessage, Text } from "@cyanco/components/theme";
import { AlertTriangle } from "@cyanco/components/theme/v3/icons";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { useAppContext } from "@/components/AppContextProvider";
import { bigNumToFixedStr, bigNumToFloat, displayInUSD } from "@/utils";
import { REQUEST_MORE_INFO } from "@/utils/error/msgs";

import { ItemsMetadata, NftImageWrapper, StyledNftImage } from "../ItemsMetadata";
import { hasExpensiveItems, isExpensiveItem } from "../utils";
import { IItemWithPricer, IItemsWithPricer, PlanCreationSteps } from "./PlanCreationModal";

export const PlanCreationItemsMetadata = ({
  items,
  loading,
  removeItem,
  currentStep,
  selectItem,
  selectedItem,
  planType,
}: {
  items: IItemsWithPricer;
  loading: boolean;
  removeItem: (_address: string, _tokenId: string, _privateSaleId?: number, _isCyanWalletAsset?: boolean) => void;
  currentStep: PlanCreationSteps;
  selectItem: React.Dispatch<React.SetStateAction<IItemWithPricer | undefined>>;
  selectedItem?: IItemWithPricer;
  planType: "bnpl" | "pawn";
}) => {
  const { usdPrice } = useAppContext();
  const getUsdPrice = (price: number, currencySymbol: string) => {
    if (!usdPrice || !usdPrice[currencySymbol]) return 0;
    return usdPrice[currencySymbol] * price;
  };
  return (
    <>
      {currentStep === PlanCreationSteps.SelectTerm &&
        (items.length > 1 ? (
          <Card p="8px">
            <Flex direction="column" gap="8px">
              <Text color="gray0" size="sm" weight="500">
                Select NFTs to set loan terms
              </Text>
              <NftImageWrapper style={{ padding: 0, cursor: "pointer" }}>
                {items.map(item => (
                  <ImageWrapper key={`${item.address}:${item.tokenId}:${item.isCyanWalletAsset ? "cyan" : "main"}`}>
                    <StyledNftImage
                      src={item.imageUrl || NoImage}
                      key={item.tokenId}
                      hasImage={!!item.imageUrl}
                      onClick={() => selectItem(item)}
                      hasError={!!item.error || (planType === "bnpl" && !item.isPossibleToBuy)}
                      isSelected={
                        selectedItem &&
                        selectedItem.tokenId === item.tokenId &&
                        selectedItem.address === item.address &&
                        selectedItem.isCyanWalletAsset === item.isCyanWalletAsset
                      }
                    />
                    <ItemStatusCircle isChecked={!!item.chosenConfig}>
                      {item.chosenConfig && <Check color="black" size={8} strokeWidth="4" />}
                    </ItemStatusCircle>
                    {items.length > 1 && (
                      <RemoveFromCart
                        onClick={e => {
                          e.stopPropagation();
                          removeItem(item.address, item.tokenId, item.privateSaleId, item.isCyanWalletAsset);
                        }}
                      >
                        <Text color="white" size="xxs" weight="600">
                          Remove
                        </Text>
                      </RemoveFromCart>
                    )}
                  </ImageWrapper>
                ))}
              </NftImageWrapper>
            </Flex>
          </Card>
        ) : (
          items.length === 1 && (
            <ItemsMetadata
              planType={planType}
              items={items}
              onRemoveItem={() =>
                removeItem(items[0].address, items[0].tokenId, items[0].privateSaleId, items[0].isCyanWalletAsset)
              }
            />
          )
        ))}
      {currentStep === PlanCreationSteps.Pricer1Result && (
        <>
          {items.length > 1 && items.some(item => item.isBendDao) && (
            <SystemMessage variant="error" title="Loan Limit" msg={`A maximum of 1 NFTs can be migrated at a time.`} />
          )}
          {items.length > 1 && items.some(item => item.existingPlan) && (
            <SystemMessage
              variant="error"
              title="Loan Limit"
              msg={`A maximum of 1 NFTs can be refinanced at a time.`}
            />
          )}
          {!loading && (hasExpensiveItems(items) || (planType === "bnpl" && items.some(i => !i.isPossibleToBuy))) && (
            <SystemMessage
              variant="error"
              title="Some items are likely to be rejected"
              msg={
                <Text size="xs" weight="500" color="black">
                  {`Please check red bordered items. Higher priced items may not be approved by the protocol. Please note gas fees to initialize the loan are nonrefundable.`}
                </Text>
              }
              description={REQUEST_MORE_INFO}
            />
          )}
          {loading ? (
            <ItemsContainer direction="column" style={{ width: "100%" }} gap="8px">
              {items.map(item => {
                return (
                  <ItemWrapper
                    justifyContent="space-between"
                    key={`${item.address}:${item.tokenId}`}
                    alignItems="center"
                  >
                    <Flex gap="7px">
                      <Flex style={{ position: "relative" }}>
                        <ImageWrapperItem src={item.imageUrl || NoImage} />
                      </Flex>
                      <Flex direction="column">
                        <Text size="md" color="secondary" weight="600">
                          #{item.tokenId}
                        </Text>
                        <Text size="xs" color="gray0" weight="600">
                          {item.collectionName}
                        </Text>
                      </Flex>
                    </Flex>
                    <ItemPriceWrapper alignItems="center" gap="6px">
                      <SkeletonLine h="34px" w="50px" />
                      {item.isFlaggedByOS && <AlertTriangle color="red" width={24} height={24} />}
                    </ItemPriceWrapper>
                  </ItemWrapper>
                );
              })}
            </ItemsContainer>
          ) : (
            <ItemsContainer direction="column" style={{ width: "100%" }} gap="8px">
              {items.map(item => {
                return (
                  <ItemErrorWrapper direction="column" key={`${item.address}:${item.tokenId}`}>
                    <Flex direction="column">
                      <ItemWrapper
                        justifyContent="space-between"
                        alignItems="center"
                        hasError={
                          !item.price ||
                          item.price.eq(0) ||
                          !item?.config ||
                          item.config.length === 0 ||
                          isExpensiveItem({ interestRate: item.interestRate }) ||
                          (planType === "bnpl" && !item.isPossibleToBuy)
                        }
                      >
                        <Flex gap="7px">
                          <Flex style={{ position: "relative" }}>
                            <ImageWrapperItem src={item.imageUrl || NoImage} />
                            {item.isFlaggedByOS && (
                              <FlagAlertWrapper>
                                <AlertTriangle strokeWidth={2.5} width={24} height={24} color="red" />
                              </FlagAlertWrapper>
                            )}
                          </Flex>
                          <Flex direction="column">
                            <Text size="md" color="secondary" weight="600">
                              #{item.tokenId}
                            </Text>
                            <Text size="xs" color="gray0" weight="600">
                              {item.collectionName}
                            </Text>
                          </Flex>
                        </Flex>
                        <ItemPriceWrapper alignItems="center" gap="6px">
                          <Flex direction="column" alignItems="flex-end">
                            <Text color="secondary" weight="600" size="md">
                              {bigNumToFixedStr(item.price ?? 0, 2, item.currency.decimal)} {item.currency.symbol}
                            </Text>
                            <Text color="gray0" weight="600" size="xs">
                              {displayInUSD(
                                getUsdPrice(
                                  bigNumToFloat(item.price ?? 0, item.currency.decimal),
                                  item.currency.symbol,
                                ),
                              )}
                            </Text>
                          </Flex>
                          {item.isFlaggedByOS && <AlertTriangle color="red" width={24} height={24} />}
                        </ItemPriceWrapper>
                        <RemoveButton
                          onClick={() =>
                            removeItem(item.address, item.tokenId, item.privateSaleId, item.isCyanWalletAsset)
                          }
                          disabled={items.length <= 1}
                        >
                          <Text color="secondary" size="sm" weight="600">
                            Remove
                          </Text>
                        </RemoveButton>
                      </ItemWrapper>
                    </Flex>
                    {item.error && (
                      <ErrorElement>
                        <Text color="red" size="xxs">
                          {item.error}
                        </Text>
                      </ErrorElement>
                    )}
                  </ItemErrorWrapper>
                );
              })}
            </ItemsContainer>
          )}
        </>
      )}
    </>
  );
};

const RemoveButton = styled(Button)`
  right: 8px;
  position: absolute;
  opacity: 0;
  border-radius: 4px;
  height: 31px;
  width: 90px;
  background-color: ${({ theme }) => (theme.theme == "dark" ? "#2c2c2c" : "#C8C8C8")};
  transition: opacity 0.2s ease;
  border: none;
  :hover {
    border: 1px #1c1c1c solid;
    background-color: ${({ theme }) => (theme.theme == "dark" ? "#2c2c2c" : "#C8C8C8")};
  }
`;

const ItemPriceWrapper = styled(Flex)`
  opacity: 1;
  transition: opacity 0.2s ease;
`;

const ItemWrapper = styled(Flex)<{ hasError?: boolean }>`
  border-radius: 7px;
  padding: 7px;
  position: relative;
  cursor: default;
  transition: background-color 0.2s ease;
  border: 1px solid ${({ hasError }) => (hasError ? "red" : "transparent")};
  :hover {
    ${RemoveButton} {
      opacity: 1;
    }
    ${ItemPriceWrapper} {
      opacity: 0;
    }
    background-color: ${({ theme }) => (theme.theme == "dark" ? "#1c1c1c" : "#EEEEEE")};
  }
`;
const ErrorElement = styled(Flex)`
  display: none;
`;
const ItemErrorWrapper = styled(Flex)`
  :hover {
    ${ErrorElement} {
      display: block;
    }
  }
`;
const ImageWrapperItem = styled.img`
  border-radius: 6px;
  width: 35px;
  height: 35px;
`;

const ItemsContainer = styled(Flex)`
  max-height: 400px;
  overflow: auto;
`;

const FlagAlertWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const ItemStatusCircle = styled.div<{ isChecked?: boolean }>`
  border: 2px solid ${({ isChecked, theme }) => (isChecked ? theme.colors.cyan : theme.colors.gray0)};
  border-radius: 50%;
  width: 10.67px;
  height: 10.67px;
  position: absolute;
  right: 10px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isChecked, theme }) => (isChecked ? theme.colors.cyan : theme.colors.transparent)};
`;

const RemoveFromCart = styled.div`
  cursor: pointer;
  display: none;
  position: absolute;
  bottom: 10px;
  padding: 5px 10px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: #1c1c1c;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: fit-content;
  transition: 0.2s;
`;
const ImageWrapper = styled.div`
  position: relative;
  &:hover ${RemoveFromCart} {
    display: flex;
  }
`;
