import { BigNumber, constants, ethers } from "ethers";
import { useContext, useEffect, useMemo, useState } from "react";
import { Bell } from "react-feather";
import styled from "styled-components";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";
import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { ArrowRight } from "@cyanco/components/theme/v3/icons";
import { UpdatedBNPLEvent } from "@cyanco/contract/abis/CyanPaymentPlanV2";

import { useBNPLPositions } from "@/components/Account/AccountDataContext";
import { useApeCoinStatsContext } from "@/components/ApeCoinStaking/new/ApeCoinStatsContext";
import { ApeCoinStakingApyButton } from "@/components/ApeCoinStaking/new/components/common";
import { AuthContext } from "@/components/AuthContext/AuthContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { BAKCAddress, BAYCAddress, MAYCAddress, nonNativeSupportedCurrenciesData } from "@/config";
import { usePlanCreation } from "@/hooks";
import { useApproval } from "@/hooks/useApproval";
import { usePaymentPlanContract } from "@/hooks/usePaymentPlanContract";
import { IStep1ResultV2 } from "@/hooks/usePlanCreation.types";
import { IAutoRepayStatuses, IPlanCreatableNft } from "@/types";
import { getChainExplorerTextForTxn, getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";
import { IMappedError } from "@/utils/error/msgs";

import { ItemsMetadata } from "../../ItemsMetadata";
import { PlanNotificationSettings } from "../../PlanNotificationSettings";
import { checkVaultBalance } from "../../utils";
import { IPlanConfig, PlanCreationModal, PlanCreationSteps } from "../PlanCreationModal";

type IProps = {
  items: Array<
    IPlanCreatableNft & {
      vaultAddress: string;
      interestRate: number;
      planId: number;
      signature: string;
      chosenConfig: IPlanConfig;
      serviceFeeRate: number;
    }
  >;
  autoRepayStatus: number;
  expiryDate: number;
  step1Result: IStep1ResultV2;
  initialStep?: BnplCreateSteps;
  selectedWalletAddress: string;
  onClose?: () => void;
};
export const BnplCreationProgressV2: React.FC<IProps> = args => {
  const { autoRepayStatus, expiryDate, items, step1Result, initialStep, selectedWalletAddress, onClose } = args;
  const { chainId, provider, account, signer } = useWeb3React();
  const { contract: paymentPlanContract, filters: paymentPlanEventFilters } = usePaymentPlanContract();
  const { fetchBNPLPositions } = useBNPLPositions();
  const { setModalContent, onBackModal } = useModal();
  const { fetchUserBe } = useContext(AuthContext);
  const { cyanWallet, createNewWallet } = useCyanWalletContext();
  const { giveErc20Approval } = useApproval();
  const { transactions } = useTransactionContext();
  const { createPlans } = usePlanCreation("bnpl");
  const [selectedStep, setSelectedStep] = useState<BnplCreateSteps>(initialStep ?? BnplCreateSteps.TokenApproval);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [activeTx, setActiveTx] = useState<string | null>(null);

  const paymentPlans = args.items.map(pricedItem => ({
    item: {
      amount: pricedItem.amount,
      tokenId: pricedItem.tokenId,
      contractAddress: pricedItem.address,
      cyanVaultAddress: pricedItem.vaultAddress,
      itemType: pricedItem.itemType,
    },
    plan: {
      amount: pricedItem.price,
      downPaymentPercent: 100_00 - pricedItem.chosenConfig.loanRate,
      interestRate: pricedItem.interestRate,
      serviceFeeRate: pricedItem.serviceFeeRate,
      term: pricedItem.chosenConfig.term,
      totalNumberOfPayments: pricedItem.chosenConfig.totalNumberOfPayments,
      counterPaidPayments: 1,
      autoRepayStatus,
    },
    planId: pricedItem.planId,
    currency: pricedItem.currency,
    autoRepayStatus,
    signature: pricedItem.signature,
    expiryDate,
  }));

  const stepMarkFiltered = useMemo(() => {
    const isNativeCurrencyTransaction = paymentPlans[0].currency.address === ethers.constants.AddressZero;
    const steps = getBnplCreateStepMarks(chainId, selectedStep);
    return steps.filter(
      item =>
        !(
          (!autoRepayStatus && item.value === BnplCreateSteps.SettingUpAutoRepayments) ||
          (cyanWallet && item.value === BnplCreateSteps.CreatingCyanWallet) ||
          (isNativeCurrencyTransaction && item.value === BnplCreateSteps.TokenApproval)
        ),
    );
  }, [autoRepayStatus, cyanWallet, chainId, selectedStep]);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(BnplCreateSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  useEffect(() => {
    if (!chainId || !signer) return;

    const onUpdateBnplEvent = (planId: BigNumber, status: number) => {
      if (planId.toNumber() !== paymentPlans[0].planId) return;
      if (status === 1) {
        // status for "funded"
        setSelectedStep(BnplCreateSteps.PurchasingNft);
      }

      if (status === 2) {
        // status for "activated"
        setSelectedStep(BnplCreateSteps.Done);
        setTimeout(() => {
          // fetchOnSaleAssets();
        }, 2000);
      }
    };

    const onStepChange = async (step: BnplCreateSteps) => {
      if (!account || !provider) return;
      try {
        switch (step) {
          case BnplCreateSteps.TokenApproval: {
            await checkVaultBalance(
              args.items.map(item => ({
                ...item,
                loanAmount: item.price,
              })),
              "BNPL",
              provider,
              account,
            );
            const downpayment = paymentPlans[0].plan.amount.mul(paymentPlans[0].plan.downPaymentPercent).div(10000);
            const currencyData = step1Result.currency;
            const paymentPlanAddrs = paymentPlanContract?.address ?? "";
            if (
              currencyData.address !== ethers.constants.AddressZero &&
              selectedWalletAddress.toLowerCase() !== (cyanWallet?.walletAddress ?? "").toLowerCase()
            ) {
              await giveErc20Approval(
                {
                  currencyAddress: currencyData.address,
                  amount: downpayment,
                },
                paymentPlanAddrs,
              );
            }
            setSelectedStep(BnplCreateSteps.CreatingCyanWallet);
            return;
          }
          case BnplCreateSteps.CreatingCyanWallet: {
            if (!cyanWallet && paymentPlans.length > 1) {
              await createNewWallet(signer);
            }
            setSelectedStep(BnplCreateSteps.SettingUpAutoRepayments);
            return;
          }

          case BnplCreateSteps.SettingUpAutoRepayments: {
            if (autoRepayStatus === IAutoRepayStatuses.FromMainWallet) {
              const paymentPlanContractAddress = paymentPlanContract?.address ?? "";
              const currency = paymentPlans[0]?.currency;
              if (currency) {
                const totalAmount = items.reduce((acc, cur) => {
                  return acc.add(cur.price);
                }, BigNumber.from(0));
                if (currency.address.toLowerCase() !== constants.AddressZero.toLowerCase()) {
                  await giveErc20Approval(
                    { currencyAddress: currency.address, amount: totalAmount },
                    paymentPlanContractAddress,
                  );
                } else {
                  const wethAddress = nonNativeSupportedCurrenciesData[chainId][SupportedCurrencies.WETH].address;
                  await giveErc20Approval(
                    { currencyAddress: wethAddress, amount: totalAmount },
                    paymentPlanContractAddress,
                  );
                }
              }
            }
            setSelectedStep(BnplCreateSteps.SettlingDownPayment);
            return; // This case is only for UX improvement
          }

          case BnplCreateSteps.SettlingDownPayment: {
            const tx = await createPlans({
              paymentPlans,
              withdrawFromCyanWallet:
                selectedWalletAddress.toLowerCase() === (cyanWallet?.walletAddress ?? "").toLowerCase(),
            });
            if (tx) setActiveTx(tx.hash);
            await fetchBNPLPositions();
            setSelectedStep(BnplCreateSteps.VaultFunding);
            return;
          }
          case BnplCreateSteps.VaultFunding: {
            setTimeout(() => setSelectedStep(BnplCreateSteps.PurchasingNft), 1000);
            return;
          }
          case BnplCreateSteps.PurchasingNft: {
            paymentPlanContract?.on<UpdatedBNPLEvent>(paymentPlanEventFilters.UpdatedBNPL(), onUpdateBnplEvent);
            return;
          }
        }
      } catch (e) {
        const mappedError = mapAndLogError(e, account);
        openPlanCreationStep1(mappedError);
      }
    };

    onStepChange(selectedStep);
    return () => {
      paymentPlanContract?.off<UpdatedBNPLEvent>(paymentPlanEventFilters.UpdatedBNPL(), onUpdateBnplEvent);
    };
  }, [selectedStep, cyanWallet]);

  const openSetupAlertsModal = async () => {
    try {
      await fetchUserBe();
      setModalContent({
        title: `BNPL Started`,
        content: <PlanNotificationSettings items={args.items} planType="bnpl" />,
        onBack: onBackModal,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const openPlanCreationStep1 = (err: IMappedError) => {
    setModalContent({
      title: `Pay Later`,
      hideHeader: true,
      content: (
        <PlanCreationModal
          planType="bnpl"
          items={items}
          currency={step1Result.currency}
          pricePlanStep1={step1Result.pricePlanStep1}
          pricePlanStep2={step1Result.pricePlanStep2}
          pricePlanStep1Result={step1Result.pricerPlan}
          triggeredError={err}
          currentStep={PlanCreationSteps.SelectTerm}
          onClose={onClose}
        />
      ),
      onClose: onClose,
    });
  };
  const { poolsWithBorrow } = useApeCoinStatsContext();

  const apy = useMemo(() => {
    if (items.some(item => item.address.toLowerCase() == BAYCAddress.toLowerCase())) {
      return poolsWithBorrow.BAYC.apy;
    }
    if (items.some(item => item.address.toLowerCase() == MAYCAddress.toLowerCase())) {
      return poolsWithBorrow.MAYC.apy;
    }
    if (items.some(item => item.address.toLowerCase() == BAKCAddress.toLowerCase())) {
      return poolsWithBorrow.BAKC.apy;
    }
    return null;
  }, [poolsWithBorrow, items]);

  const handleApyClick = () => {
    window.open("/ape-coin", "_blank");
  };

  return (
    <Flex gap="5x" direction="column">
      <ItemsMetadata items={args.items} planType="bnpl" isCreating={selectedStep !== BnplCreateSteps.Done} />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>

      {selectedStep === BnplCreateSteps.Done && (
        <Flex direction="column" gap="10px">
          <StyledConfirmButton onClick={openSetupAlertsModal}>
            <Flex gap="4px" justifyContent="center" alignItems="center">
              <Bell height={16} width={16} strokeWidth="3px" />
              <Text color="black" size="sm" weight="700">
                {`Setup Notifications`}
              </Text>
              <ArrowRight />
            </Flex>
          </StyledConfirmButton>
          {!!apy && <ApeCoinStakingApyButton onClick={handleApyClick} apy={apy} />}
        </Flex>
      )}
    </Flex>
  );
};

enum BnplCreateSteps {
  TokenApproval = 1,
  CreatingCyanWallet = 2,
  SettingUpAutoRepayments = 3,
  SettlingDownPayment = 4,
  VaultFunding = 5,
  PurchasingNft = 6,
  Done = 7,
}

const getBnplCreateStepMarks = (chainId: number, step: BnplCreateSteps) => {
  return [
    {
      value: BnplCreateSteps.TokenApproval,
      title: `Approve Token`,
    },
    {
      value: BnplCreateSteps.CreatingCyanWallet,
      description: `A new wallet is created on the first purchase on Cyan`,
      title: `Creating Cyan Wallet`,
    },
    {
      value: BnplCreateSteps.SettingUpAutoRepayments,
      title: `Setting up auto-repayments`,
    },
    {
      value: BnplCreateSteps.SettlingDownPayment,
      title: `Down payment settled`,
    },
    {
      value: BnplCreateSteps.VaultFunding,
      title: `Funding from Vault`,
      description: `This step requires an automated execution. This may take up to 15 minutes to process.`,
    },
    {
      value: BnplCreateSteps.PurchasingNft,
      title: `Purchasing NFT`,
      description:
        step === BnplCreateSteps.PurchasingNft
          ? `This step requires an automated execution. This may take up to 15 minutes to process.`
          : getChainExplorerTextForTxn(chainId),
    },
  ];
};

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
  transition: all 0.2s ease-in-out;
  :hover {
    background-color: ${({ disabled }) => !disabled && "#79FFFF"};
    border-color: ${({ disabled }) => !disabled && "#79FFFF"};
  }
`;
