import { BigNumber, ethers, utils } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { ArrowRight } from "react-feather";
import { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";
import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper, Text, getCurrencyLogoSrc, useModal } from "@cyanco/components/theme/v3";
import { CyanC } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { IVault } from "@/apis/vault/types";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { SupportedCurrenciesByChain } from "@/config";
import { getChainExplorerTextForTxn, getChainExplorerURL, numberWithCommas } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { useVaultPositions } from "../../VaultDataProvider";
import { VaultContractAbiNames, VaultDecimalFormatMap } from "../../types";
import { UnstakingModal } from "./UnstakingModal";
import { StyledConfirmButton, StyledImg, VaultImage, VaultImageWrapper } from "./common";

export const UnstakingProgressModal = ({
  unstakingAmount,
  vault,
  selectedWallet,
  selectedReleaseWallet,
  setUnstakingAmount,
  setAmountInToken,
}: {
  unstakingAmount: number;
  vault: IVault;
  selectedWallet: string;
  selectedReleaseWallet: string;
  setUnstakingAmount: (a: string) => void;
  setAmountInToken: (a: string) => void;
}) => {
  const cyanWallet = useCyanWallet();
  const { chainId, account, signer } = useWeb3React();
  const { usdPrice, setFireConfetti } = useAppContext();
  const theme = useTheme();
  const { setModalContent, unsetModal } = useModal();
  const { addTransaction, transactions } = useTransactionContext();
  const { fetchPositions } = useVaultPositions();
  const [selectedStep, setSelectedStep] = useState<Steps>(Steps.Unstaking);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const formatNumber = useMemo(() => {
    return VaultDecimalFormatMap.get(vault.decimals) || 5;
  }, [vault.decimals]);
  const unstakingAmountInToken = useMemo(() => {
    const amount = (unstakingAmount * vault.priceUsd) / usdPrice[vault.currency];
    return Math.trunc(amount * Math.pow(10, formatNumber)) / Math.pow(10, formatNumber);
  }, [unstakingAmount]);
  const isTransferRequired = selectedWallet.toLowerCase() !== selectedReleaseWallet.toLowerCase();

  useEffect(() => {
    if (!activeTx || !account) return;
    const intervalId = setInterval(() => {
      const isTransferStepRequired = isTransferRequired && account.toLowerCase() === selectedWallet.toLowerCase();
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setUnstakingAmount("");
        setAmountInToken("");
        if (!isTransferStepRequired) {
          setFireConfetti(true);
          fetchPositions();
        }
        setSelectedStep(isTransferStepRequired ? Steps.Transfer : Steps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);
  useEffect(() => {
    const onStepChange = async (step: Steps) => {
      try {
        if (!chainId || !signer || !account) return;
        const currency = SupportedCurrenciesByChain[vault.chainId].find(item => item.symbol === vault.currency);
        if (!currency) {
          throw new Error(`Currency not found for ${vault.currency}`);
        }
        let vaultContractWriter;
        switch (vault.abiName) {
          case VaultContractAbiNames.CyanVaultV2:
          case VaultContractAbiNames.CyanVaultV2_1: {
            vaultContractWriter = f.CyanVaultV2Factory.connect(vault.contractAddress, signer);
            break;
          }
          case VaultContractAbiNames.ApeCoinVaultV1: {
            vaultContractWriter = f.CyanApeCoinVaultV1Factory.connect(vault.contractAddress, signer);
            break;
          }
          default:
            throw new Error(`Invalid vault abi ${vault.abiName}`);
        }
        const iErc20 = f.SampleERC20TokenFactory.createInterface();
        const amount = utils.parseUnits(unstakingAmount.toString(), vault.decimals);
        const isNativeToken = currency.address.toLowerCase() === ethers.constants.AddressZero.toLowerCase();
        const isCyanWalletUnstake =
          cyanWallet && cyanWallet.walletAddress.toLowerCase() === selectedWallet.toLowerCase();
        switch (step) {
          case Steps.Unstaking: {
            let tx: ethers.ContractTransaction;
            if (isCyanWalletUnstake) {
              const amountInVaultCurrency = await vaultContractWriter.calculateCurrencyByToken(amount);
              const encodedFnVault = vaultContractWriter.interface.encodeFunctionData("withdraw", [amount]);
              if (isTransferRequired) {
                if (isNativeToken) {
                  tx = await cyanWallet.executeBatch([
                    {
                      to: vault.contractAddress,
                      value: BigNumber.from(0),
                      data: encodedFnVault,
                    },
                    {
                      to: selectedReleaseWallet,
                      value: amountInVaultCurrency,
                      data: "0x",
                    },
                  ]);
                } else {
                  const transferTxnData = iErc20.encodeFunctionData("transfer", [
                    selectedReleaseWallet,
                    amountInVaultCurrency,
                  ]);
                  tx = await cyanWallet.executeBatch([
                    {
                      to: vault.contractAddress,
                      value: BigNumber.from(0),
                      data: encodedFnVault,
                    },
                    {
                      to: currency.address,
                      value: BigNumber.from(0),
                      data: transferTxnData,
                    },
                  ]);
                }
              } else {
                tx = await cyanWallet.execute(vault.contractAddress, BigNumber.from(0), encodedFnVault);
              }
            } else {
              tx = await vaultContractWriter.withdraw(amount);
            }
            addTransaction({
              type: "vault-unstake",
              hash: tx.hash,
              data: {
                vaultId: vault.id,
              },
            });
            setActiveTx(tx.hash);
            return;
          }
          case Steps.Transfer: {
            if (!isTransferRequired || isCyanWalletUnstake) return;
            let tx: ethers.ContractTransaction;
            const amountInVaultCurrency = await vaultContractWriter.calculateCurrencyByToken(amount);
            if (isNativeToken) {
              tx = await signer.sendTransaction({
                to: selectedReleaseWallet,
                value: amountInVaultCurrency,
              });
            } else {
              const contractWriter = f.SampleERC20TokenFactory.connect(currency.address, signer);
              tx = await contractWriter.transfer(selectedReleaseWallet, amountInVaultCurrency);
            }
            await tx.wait();
            setFireConfetti(true);
            fetchPositions();
            setSelectedStep(Steps.Done);
            return;
          }
        }
      } catch (err) {
        const mappedError = mapAndLogError(err, account);
        setModalContent({
          title: `Confirm Unstake`,
          content: (
            <UnstakingModal
              unstakingAmount={unstakingAmount}
              vault={vault}
              selectedWallet={selectedWallet}
              triggeredError={mappedError}
              setUnstakingAmount={setUnstakingAmount}
              setAmountInToken={setAmountInToken}
            />
          ),
        });
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);
  const stepMarks = useMemo(() => {
    return StepMarks.map(step => {
      switch (step.value) {
        case Steps.Unstaking:
          return { ...step, title: `Swapping ${vault.symbol} for ${vault.currency}` };

        case Steps.Success:
          return {
            ...step,
            title: `${vault.currency} successfully settled`,
            description: getChainExplorerTextForTxn(chainId),
          };
        default:
          return step;
      }
    });
  }, [vault.currency, vault.symbol, chainId]);
  return (
    <Flex gap="2rem" direction="column">
      <Flex alignItems="center" gap="0.8rem">
        <Flex gap="0.4rem">
          {" "}
          <VaultImageWrapper style={{ background: vault.colorCode }}>
            <VaultImage src={CyanC} alt={vault.name} />
          </VaultImageWrapper>{" "}
          <Text weight="400" color="secondary" size="md">
            {`${numberWithCommas(unstakingAmount, 2)} ${vault.symbol}`}
          </Text>
        </Flex>
        <ArrowRight color={theme.colors.secondary} size={15} />
        <Flex gap="0.4rem">
          <StyledImg src={getCurrencyLogoSrc(vault.symbol as SupportedCurrencies)} alt={vault.name} />

          <Text weight="400" color="secondary" size="md">
            {`${numberWithCommas(unstakingAmountInToken, 2)} ${vault.currency}`}
          </Text>
        </Flex>
      </Flex>
      <Box pb="1.8rem" pt="1rem">
        <Stepper
          marks={stepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === Steps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};

enum Steps {
  Unstaking = 1,
  Transfer = 2,
  Success = 3,
  Done = 4,
}

const StepMarks = [
  {
    value: Steps.Unstaking,
    title: `Swapping APE for CV11`,
    description: `A small amount of gas is required to approve`,
  },
  {
    value: Steps.Transfer,
    title: `Token Transfer`,
  },
  {
    value: Steps.Success,
    description: `View on Etherscan`,
    title: `CV11 successfully settled`,
  },
];
