import { Interface } from "ethers/lib/utils";

export type Override<T1, T2> = Omit<T1, keyof T2> & T2;
export const AlertTypes = {
  Email: 0,
  Discord: 1,
  Telegram: 2,
};
export type IAlertTypes = typeof AlertTypes[keyof typeof AlertTypes];

export const StatusBoxTypes = {
  Error: "error",
  Success: "success",
  Warning: "warning",
};
export type IStatusBoxTypes = typeof StatusBoxTypes[keyof typeof StatusBoxTypes];

export type IBatchReaderData = {
  interface: Interface;
  params: any[];
  functionName: string;
  contractAddress: string;
};

export enum SourceType {
  event = "event",
  props = "prop",
}
