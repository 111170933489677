import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import styled from "styled-components";

import { Button, Flex, Loader, Text, useModal } from "@cyanco/components/theme";
import { CyanLogo } from "@cyanco/components/theme/v3/icons";

import { applyAffiliateCode } from "@/apis/user";
import { createHashSHA256, shortenAddress } from "@/utils";
import { mapAndLogError } from "@/utils/error";
import { IMappedError } from "@/utils/error/msgs";

import { useAuthContext } from "../AuthContext/AuthContextProvider";
import { useWeb3React } from "../Web3ReactProvider";

export const AffiliateApplyModal = ({ wallet, affiliateCode }: { wallet: string; affiliateCode: string }) => {
  const { unsetModal } = useModal();
  const { signer, account, chainId } = useWeb3React();
  const [error, setError] = useState<IMappedError>();
  const { fetchUserBe, userProfile } = useAuthContext();
  const [checkedCooldown, setCheckedCooldown] = useState<boolean>(false);
  useEffect(() => {
    fetchUserBe();
  }, [account]);
  useEffect(() => {
    const { referrerCodeCooldownDate } = userProfile;
    if (referrerCodeCooldownDate && dayjs().isBefore(referrerCodeCooldownDate)) {
      setError({
        title: "Referrer cooldown",
        msg: `You recently updated your referrer. You can renew it after ${referrerCodeCooldownDate}`,
      });
    } else {
      setCheckedCooldown(true);
    }
  }, [userProfile]);
  const { execute, loading } = useAsyncCallback(async () => {
    if (!error && signer && account) {
      const message = [affiliateCode, wallet.toLowerCase()].join(",");
      const hashMessage = await createHashSHA256(message);
      try {
        const signature = await signer.signMessage(hashMessage);
        await applyAffiliateCode({ wallet: account, affiliateCode, signature, chainId });
        unsetModal();
      } catch (e) {
        const mappedError = mapAndLogError(e);
        setError(mappedError);
      }
    }
  });

  return (
    <Flex direction="column" w="100%" alignItems="center" gap="2rem">
      <Flex p="2rem" alignItems="center" direction="column" gap="2rem">
        <CyanLogo width={125} height={125} />
        <Text color="secondary" size="xxl" weight="700">
          Affiliate Code
        </Text>
        <Flex alignItems="center" gap="10px">
          <Jazzicon seed={jsNumberForAddress(wallet)} diameter={30} />
          <Text color="secondary" weight="500" size="xl">
            {shortenAddress(wallet)}
          </Text>
        </Flex>
      </Flex>
      <Text color="secondary" textAlign="center" size="md">
        You used <b>{affiliateCode}</b> code, do you wanna accept and apply the affiliate code?
      </Text>
      {error && (
        <Text color="red" textAlign="center" size="xs">
          <b>{error.title}</b> {error.msg}
        </Text>
      )}
      <Flex w="100%" gap="1rem">
        <CancelButton
          onClick={() => {
            unsetModal();
          }}
        >
          <Text size="md" weight="600" color="black">
            Cancel
          </Text>
        </CancelButton>
        <AccepButton size="md" onClick={execute} disabled={!!error || !checkedCooldown}>
          {loading ? (
            <Loader size="16px" />
          ) : (
            <Text size="md" weight="600" color="black">
              Accept and Sign
            </Text>
          )}
        </AccepButton>
      </Flex>
    </Flex>
  );
};

const CancelButton = styled(Button)`
  border-radius: 10px;
  background: ${({ theme }) => theme.colors.gray20};
  border-color: ${({ theme }) => theme.colors.gray20};
  height: 50px;
  :hover {
    background: ${({ theme }) => theme.colors.gray30};
    border-color: ${({ theme }) => theme.colors.gray30};
  }
`;

const AccepButton = styled(Button)`
  border-radius: 10px;
  height: 50px;
`;
