import { useAsync } from "react-async-hook";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";

import { getVaultLoanSettings } from "@/apis/vault/admin";
import {
  ILtvController,
  IPoolSettings,
  IVaultLoanSettings,
  IVaultSupportedProjectWithAppraisal,
} from "@/apis/vault/admin/types";
import { IVault } from "@/apis/vault/types";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { useVaults } from "@/components/Vault/VaultDataProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apeVaultContract } from "@/config";

import { SuppyAPYChart } from "../SuppyAPYChart";
import { UtilizationRateChart } from "../UtilizationRateChart";
import { VaultCharts } from "../charts";
import { PoolManagement } from "./PoolManagement";
import { SupportedCollections } from "./SupportedCollections";
import { VaultAssetsManagement } from "./VaultAssets";

export const VaultDetailsPrivate = ({ vault }: { vault: IVault }) => {
  const { vaultAdditionalMetrics, fetchingVaultAdditionalMetrics } = useVaults();
  const isApeVault = vault.contractAddress.toLowerCase() === apeVaultContract;
  const { account } = useWeb3React();
  const { user } = useAuthContext();
  const {
    result = {
      ltvSettings: [],
      pricerSettings: [],
      baseInterestRate: 0,
      requestedProjects: [],
      supportedProjects: [],
    },
    loading,
    merge,
    execute,
  } = useAsync<IVaultLoanSettings>(async () => {
    if (!account || !user?.token)
      return {
        ltvSettings: [],
        pricerSettings: [],
        baseInterestRate: 0,
        requestedProjects: [],
        supportedProjects: [],
      };
    return getVaultLoanSettings(vault.contractAddress, user.token);
  }, [vault, account]);
  const setLtvController = (controllers: ILtvController[]) => {
    merge({
      result: {
        ...result,
        ltvSettings: controllers,
      },
    });
  };
  const setPoolSettings = (settings: IPoolSettings[], baseInterestRate: number) => {
    merge({
      result: {
        ...result,
        baseInterestRate,
        pricerSettings: settings,
      },
    });
  };
  const setSupportedProjects = (projects: IVaultSupportedProjectWithAppraisal[]) => {
    merge({
      result: {
        ...result,
        supportedProjects: projects,
      },
    });
  };
  const refresh = async () => {
    const data = await execute();
    merge({
      result: data,
    });
  };

  return (
    <Flex direction="column" gap="1rem">
      <VaultAssetsManagement vault={vault} loading={loading} />
      {!isApeVault && (
        <SupportedCollections
          vault={{
            ...vault,
            supportedProjects: result.supportedProjects,
          }}
          requestedProjects={result.requestedProjects}
          refreshProjects={refresh}
        />
      )}
      <PoolManagement
        vault={vault}
        pricerSettings={result.pricerSettings}
        supportedProjects={result.supportedProjects}
        ltvSettings={result.ltvSettings}
        baseInterestRate={result.baseInterestRate}
        setLtvController={setLtvController}
        setPoolSettings={setPoolSettings}
        setSupportedProjects={setSupportedProjects}
        loading={loading}
      />
      <UtilizationRateChart history={vault.history} />
      <SuppyAPYChart history={vault.history} />
      {!isApeVault && (
        <VaultCharts
          vault={vault}
          vaultAdditionalMetrics={vaultAdditionalMetrics}
          loading={fetchingVaultAdditionalMetrics}
        />
      )}
    </Flex>
  );
};

export const VaultDetailSectionContainer = styled(Flex)`
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 4.5rem;
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0 5px" }])}
`;
