import { BigNumber, constants, ethers } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useAsyncCallback } from "react-async-hook";

import { useCyanWallet } from "@usecyan/cyan-wallet";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Card, SystemMessage, Text, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { checkIsRevivalPossible } from "@/components/Account/components/PositionView/utils";
import { IPawn } from "@/components/Account/pawn.types";
import { useApeStakingUserAssets } from "@/components/ApeCoinStaking/new/ApeCoinDataContext";
import { IBNPL, isBnplPlan } from "@/components/Bnpl/bnpl.types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { usePersistedWalletTypeForRelease } from "@/hooks";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, numberWithCommas } from "@/utils";
import { executeBatchRead, getAccountBalanceOfErc20 } from "@/utils/contract";
import { IMappedError } from "@/utils/error/msgs";
import { IBatchReaderData } from "@/utils/types";

import { BulkEarlyRepaymentBreakdown } from "../BulkRepayment/EarlyRepaymentBreakdown";
import { PlanMetadata } from "../PlanMetadata";
import { WalletOptionModal } from "../WalletOptionModal";
import { WalletSelectorForNFTRelease } from "../WalletSelectorForNFTRelease";
import { BulkRevivalPaymentLoading } from "./BulkRevivalPaymentLoading";
import { BulkRevivalProgressModal } from "./BulkRevivalProgressModal";

export type INextPaymentRevival = {
  payAmountForCollateral: BigNumber;
  payAmountForInterest: BigNumber;
  payAmountForService: BigNumber;
  currentPayment: BigNumber;
  nextPaymentDate?: Date;
  penaltyAmount: BigNumber;
  isNativeCurrency: boolean;
};
export type IRevivablePlan = (IPawn | IBNPL) & {
  nextPayment: INextPaymentRevival;
  totalLoanAmount: BigNumber;
  totalLeftAmount: BigNumber;
  bnplOriginalPrice: string;
  totalNumOfPaymentsLeft: number;
  hasError?: boolean;
  isBnpl: boolean;
};

export const BulkRevivalModal = ({
  plans,
  error: _error,
  onClose,
  removePlan,
}: {
  plans: (IPawn | IBNPL)[];
  error?: IMappedError;
  onClose: () => void;
  removePlan: (plan: { address: string; tokenId: string }) => void;
}) => {
  const { setModalContent } = useModal();
  const { provider, chainId, account, signer } = useWeb3React();
  const { loading } = useApeStakingUserAssets();
  const [error, setError] = useState<IMappedError | null>(_error || null);
  const [warning, setWarning] = useState<{
    msg: string;
    title: string;
    description?: string;
  } | null>(null);
  const [plansWithRevivalPayment, setPlansWithRevivalPayment] = useState<Array<IRevivablePlan>>([]);
  const cyanWallet = useCyanWallet();
  const plansWithDetail = plans.map(plan => {
    const isBnpl = isBnplPlan(plan) && plan !== undefined;
    const bnplOriginalPrice = (plan as IBNPL)?.price;
    const totalNumOfPaymentsByPlanType = isBnpl ? plan.totalNumOfPayments - 1 : plan.totalNumOfPayments;
    const totalNumOfPaymentsLeft = plan.totalNumOfPayments - plan.currentNumOfPayments;
    const totalLoanAmount = BigNumber.from(plan.monthlyAmount).mul(totalNumOfPaymentsByPlanType);
    const totalLeftAmount = BigNumber.from(plan.monthlyAmount).mul(totalNumOfPaymentsLeft);
    return {
      ...plan,
      totalLoanAmount,
      totalLeftAmount,
      bnplOriginalPrice,
      totalNumOfPaymentsLeft,
      isBnpl,
    };
  });
  const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
  const { walletTypeForRelease } = usePersistedWalletTypeForRelease();
  const [releaseWallet, setReleaseWallet] = useState<string>(
    (walletTypeForRelease === "main" ? account : cyanWallet?.walletAddress) ?? "",
  );
  const [isNotifiedBulkWarning, setIsNotifiedBulkWarning] = useState<boolean>(false);

  useEffect(() => {
    const _setNextPayment = async () => {
      if (!signer || !chainId || !provider) return;
      const batchPaymentsInfo: IBatchReaderData[] = [];
      const iPaymentPlanContract = f.PaymentPlanV2Factory.createInterface();
      plansWithRevivalPayment.map(plan => {
        if (!plan.revivalInfo) {
          setError({
            title: `Payment Error`,
            msg: `Could not proceed payments for some of the plans, please remove red colored items`,
          });
          return {
            ...plan,
            hasError: true,
          };
        }
        return plan;
      });
      for (const plan of plans) {
        batchPaymentsInfo.push({
          interface: iPaymentPlanContract,
          contractAddress: paymentPlanV2,
          functionName: "getPaymentInfoByPlanId",
          params: [plan.planId, false],
        });
      }
      const batchPaymentsInfoResult = await executeBatchRead(chainId, provider, batchPaymentsInfo);

      const result = plansWithDetail.map((plan, index) => {
        const [payAmountForCollateral, payAmountForInterest, payAmountForService, currentPayment, dueDate] =
          batchPaymentsInfoResult[index];
        const nextPayment: INextPaymentRevival = {
          nextPaymentDate: new Date(dueDate.toNumber() * 1000),
          currentPayment,
          payAmountForCollateral,
          payAmountForService,
          payAmountForInterest,
          isNativeCurrency: plan.currency.address.toLowerCase() === constants.AddressZero.toLowerCase(),
          penaltyAmount: BigNumber.from(plan.revivalInfo?.penaltyAmount ?? 0),
        };
        return {
          ...plan,
          nextPayment,
        };
      });
      setPlansWithRevivalPayment(result);
    };
    _setNextPayment();
  }, [plans]);

  const checkPlans = async () => {
    setPlansWithRevivalPayment(
      plansWithRevivalPayment.map(plan => {
        if (!plan.nextPayment || !checkIsRevivalPossible(plan) || !plan.revivalInfo) {
          setError({
            title: `Payment Error`,
            msg: `Could not proceed payments for some of the plans, please remove red colored items`,
          });
          return {
            ...plan,
            hasError: true,
          };
        }
        return plan;
      }),
    );
  };
  const totalRevivalAmountByCurrency = useMemo(() => {
    return plansWithRevivalPayment.reduce<{
      [key: string]: {
        totalCost: BigNumber;
        currency: ICurrency;
        formatNumber: number;
        isNativeCurrency: boolean;
        totalPenalty: BigNumber;
      };
    }>((acc, cur) => {
      if (acc[cur.currency.address.toLowerCase()]) {
        acc[cur.currency.address.toLowerCase()] = {
          totalCost: acc[cur.currency.address.toLowerCase()].totalCost.add(cur.nextPayment?.currentPayment || 0),
          currency: cur.currency,
          totalPenalty: acc[cur.currency.address.toLowerCase()].totalPenalty.add(cur.nextPayment?.penaltyAmount || 0),
          formatNumber: PlanCreationDecimalFormatMap.get(cur.currency.decimal) || 4,
          isNativeCurrency: cur.currency.address.toLowerCase() === ethers.constants.AddressZero.toLowerCase(),
        };
      } else {
        acc[cur.currency.address.toLowerCase()] = {
          totalCost: cur.nextPayment?.currentPayment || BigNumber.from(0),
          totalPenalty: cur.nextPayment?.penaltyAmount || BigNumber.from(0),
          currency: cur.currency,
          formatNumber: PlanCreationDecimalFormatMap.get(cur.currency.decimal) || 4,
          isNativeCurrency: cur.currency.address.toLowerCase() === ethers.constants.AddressZero.toLowerCase(),
        };
      }
      return acc;
    }, {});
  }, [plansWithRevivalPayment]);
  const openProgressModal = ({ selectedWalletAddress }: { selectedWalletAddress: string }) => {
    setModalContent({
      title: `Confirm Revival`,
      content: (
        <BulkRevivalProgressModal
          plans={plansWithRevivalPayment}
          onClose={onClose}
          removePlan={removePlan}
          totalRevivalAmountByCurrency={totalRevivalAmountByCurrency}
          selectedWalletAddress={selectedWalletAddress}
        />
      ),
      onClose,
    });
  };
  const checkAccountBalanceAndProceed = async (totalAmount: BigNumber, currency: ICurrency) => {
    if (!provider || !account) return;
    const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
      currencyAddress: currency.address,
      provider: provider,
      mainWallet: account,
      chainId,
    });
    if (totalAmount.gt(mainWalletBalance) && totalAmount.gt(cyanWalletBalance)) {
      setError({
        title: `Not enough funds`,
        msg: `Your payment didn’t go through due to lack of funds`,
        description: ` Please double check the amount of crypto in your main wallet.`,
      });
      return;
    }
    if (cyanWallet) {
      setModalContent({
        content: (
          <WalletOptionModal
            items={plans}
            mainWalletBalance={mainWalletBalance}
            cyanWalletBalance={cyanWalletBalance}
            currency={currency}
            paymentAmount={totalAmount}
            totalAmount={BigNumber.from(0)}
            leftAmount={BigNumber.from(0)}
            onNext={({ selectedWalletAddress }: { selectedWalletAddress: string }) => {
              openProgressModal({
                selectedWalletAddress,
              });
            }}
          />
        ),
        title: "Select wallet to pay",
      });
    }
  };

  const { execute: onPay, loading: onPayLoading } = useAsyncCallback(async () => {
    const currencyValues = Object.values(totalRevivalAmountByCurrency);
    if (currencyValues.length === 0) return;
    checkPlans();
    if (plansWithRevivalPayment.some(plan => plan.hasError)) return;
    if (currencyValues.length === 1) {
      const item = Object.values(totalRevivalAmountByCurrency)[0];
      await checkAccountBalanceAndProceed(item.totalCost.add(item.totalPenalty), item.currency);
      return;
    } else {
      if (currencyValues.some(item => !item.isNativeCurrency) && !isNotifiedBulkWarning) {
        setWarning({
          title: `Bulk Revival Notice`,
          msg: `For ETH based plans, payment will be taken from your Main Wallet by default. For ERC-20 based plans, payment will be taken from your Cyan Wallet. If you wish to change the wallet you pay with, please make loan revivals separately.`,
        });
        setIsNotifiedBulkWarning(true);
        return;
      }
      openProgressModal({
        selectedWalletAddress: account || "",
      });
    }
  });

  if (loading) {
    return <BulkRevivalPaymentLoading plans={plansWithDetail} />;
  }
  return (
    <Flex gap="18px" direction="column">
      <PlanMetadata
        plans={plansWithDetail.map(plan => ({
          ...plan,
          imageUrl: plan.metadata.imageUrl,
          collectionName: plan.metadata.collection.name,
          totalAmount: plan.totalLoanAmount,
          leftAmount: plan.totalLeftAmount,
          currency: plan.currency,
          address: plan.metadata.collectionAddress,
          purchasedPrice: plan.bnplOriginalPrice,
        }))}
        removePlan={removePlan}
      />
      {error && <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />}
      {warning && (
        <SystemMessage variant="warning" title={warning.title} msg={warning.msg} description={warning.description} />
      )}
      <BulkEarlyRepaymentBreakdown
        title="Bulk Revival Breakdown"
        plans={plansWithRevivalPayment}
        totalRepaymentAmountByCurrency={totalRevivalAmountByCurrency}
      />
      <WalletSelectorForNFTRelease
        selectedWallet={releaseWallet}
        onReleaseWalletChange={(wallet: string) => setReleaseWallet(wallet)}
        plans={plans}
        isFullPayment={true}
        onMainWalletStateChange={disabled => {
          if (disabled) {
            setWarning({
              title: `Release Options Limited`,
              msg: `One or more items you have selected have an existing staking plan. Releasing to your Main Wallet will become available once the staked items have been removed.`,
            });
          } else {
            setWarning(null);
          }
        }}
      />
      <Card p={"10px"}>
        <Flex justifyContent="space-between" w="100%">
          <Flex direction="column" gap="4px" w="100%">
            <Text size="sm" weight="500" color="gray0">
              {`Make bulk revival:`}
            </Text>
            <Flex gap="0px" direction="column">
              {Object.values(totalRevivalAmountByCurrency).map(
                ({ totalCost, totalPenalty, currency, formatNumber }) => {
                  return (
                    <Text size="lg" weight="500" color="secondary" key={currency.address}>
                      {`${numberWithCommas(
                        bigNumToFixedStr(totalCost.add(totalPenalty), formatNumber, currency.decimal),
                        formatNumber,
                      )}
                                ${currency.symbol}`}
                    </Text>
                  );
                },
              )}
            </Flex>
          </Flex>
          <Box w="120px" pt={Object.values(totalRevivalAmountByCurrency).length > 1 ? "16px" : "0"}>
            <Button onClick={onPay} loading={onPayLoading}>{`Pay`}</Button>
          </Box>
        </Flex>
      </Card>
    </Flex>
  );
};
