import { BigNumber } from "ethers";
import { UseAsyncReturn } from "react-async-hook";

import { priceBnplStep1, priceBnplStep2, pricePawnStep1, pricePawnStep2 } from "@/apis/pricer/index-v2";
import { IResult } from "@/apis/pricer/pricer-pawn-step1-v2";
import { IPricerMethod } from "@/apis/types";
import { ICurrency } from "@/types";
import { IMappedError } from "@/utils/error/msgs";

import { IItemWithPricer, IItemsWithPricer, PlanCreationSteps, getPricerStep2Result } from "./v2/PlanCreationModal";

export const PlanCreationDecimalFormatMap = new Map<number, number>([
  [6, 2],
  [18, 4],
]);

export type IPlanCreationModal = {
  items: IItemsWithPricer;
  selectedItem: IItemWithPricer;
  planType: "bnpl" | "pawn";
  currency: ICurrency;
  loading: boolean;
  pricePlanStep1: typeof priceBnplStep1 | typeof pricePawnStep1;
  pricePlanStep2: typeof priceBnplStep2 | typeof pricePawnStep2;
  setCreationStep: React.Dispatch<React.SetStateAction<PlanCreationSteps>>;
  setItemsWithPricer: React.Dispatch<React.SetStateAction<IItemsWithPricer>>;
  setTriggeredError: (a?: IMappedError) => void;
  selectItem: (a: IItemWithPricer) => void;
  pricePlanStep1Result?: IResult;
  triggeredError?: IMappedError;
  pricerStep2: UseAsyncReturn<Awaited<ReturnType<typeof getPricerStep2Result>> | undefined>;
  onChangeCurrency: (newAddress: string) => void;
  supportedCurrencies: ICurrency[];
  baseConfig: IPricerMethod[];
  onChangeAutoLiquidationState: (selectedItem: IItemWithPricer, isAutoLiquidated: boolean) => void;
  getAutoLiquidationOptions: (
    address: string,
    loanAmount: BigNumber,
  ) => {
    alVaults: {
      exist: boolean;
      balance: boolean;
    };
    nonAlVaults: {
      exist: boolean;
      balance: boolean;
    };
  };
  onClose?: () => void;
};
