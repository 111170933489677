import { BigNumber, constants, ethers } from "ethers";
import { useContext, useEffect, useMemo, useState } from "react";
import { Bell } from "react-feather";
import styled from "styled-components";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";
import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, SystemMessage, Text, useModal } from "@cyanco/components/theme/v3";
import { ArrowRight } from "@cyanco/components/theme/v3/icons";
import { factories as f } from "@cyanco/contract";
import { factories as factoryDeprecated } from "@cyanco/contract-old";

import { getPlanRevivalSignatureBulk } from "@/apis/revival";
import { useBNPLPositions, usePawnPositions } from "@/components/Account/AccountDataContext";
import { useAppContext } from "@/components/AppContextProvider";
import { AuthContext } from "@/components/AuthContext/AuthContextProvider";
import { ItemsMetadata } from "@/components/PlanCreation/ItemsMetadata";
import { PlanNotificationSettings } from "@/components/PlanCreation/PlanNotificationSettings";
import { checkVaultBalance } from "@/components/PlanCreation/utils";
import { IPlanConfig } from "@/components/PlanCreation/v2/PlanCreationModal";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { nonNativeSupportedCurrenciesData } from "@/config";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { PlanTypes } from "@/constants/plans";
import { useApproval } from "@/hooks/useApproval";
import { IStep1ResultV2 } from "@/hooks/usePlanCreation.types";
import { IAutoRepayStatuses, IPlanCreatableNft } from "@/types";
import { getChainExplorerTextForTxn, getChainExplorerURL } from "@/utils";
import { getAccountBalanceOfErc20 } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";
import { getPaymentPlanUpgradeExperiment } from "@/utils/experimentList";

import { PaymentOptions, PlanRepayment } from "../PlanRepayment";
import { RevivalWithRefinance } from "../Revival/ReviveAndRefinance";

type IProps = {
  items: Array<
    IPlanCreatableNft & {
      vaultAddress: string;
      interestRate: number;
      planId: number;
      signature: string;
      serviceFeeRate: number;
      chosenConfig: IPlanConfig;
    }
  >;
  autoRepayStatus: number;
  expiryDate: number;
  step1Result: IStep1ResultV2;
  selectedWalletAddress: string;
  onClose?: () => void;
};
export const RefinanceProgressV2: React.FC<IProps> = args => {
  const { autoRepayStatus, expiryDate, items, selectedWalletAddress, onClose } = args;
  const { chainId, provider, account: wallet, signer } = useWeb3React();
  const { experiment } = useAppContext();
  const { activePawnPositions, pawnPositions } = usePawnPositions();
  const { activeBnplPositions, bnplPositions } = useBNPLPositions();
  const { transactions, addTransaction } = useTransactionContext();
  const { setModalContent, onBackModal } = useModal();
  const { giveErc20Approval, getErc20ApprovalFunctionData } = useApproval();
  const { fetchUserBe } = useContext(AuthContext);
  const { cyanWallet } = useCyanWalletContext();
  const [selectedStep, setSelectedStep] = useState<PawnCreateSteps>(PawnCreateSteps.TokenApproval);
  const [error, setError] = useState<string>("");
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [revival, setRevival] = useState<{
    signature: string;
    expiryDateInSeconds: number;
    penaltyAmount: BigNumber;
  } | null>(null);

  const isPayingFromMainWallet = selectedWalletAddress.toLowerCase() === (wallet ?? "").toLowerCase();

  const paymentPlans = args.items.map(pricedItem => ({
    item: {
      amount: pricedItem.amount,
      tokenId: pricedItem.tokenId,
      contractAddress: pricedItem.address,
      cyanVaultAddress: pricedItem.vaultAddress,
      itemType: pricedItem.itemType,
    },
    plan: {
      amount: pricedItem.price.mul(pricedItem.chosenConfig.loanRate).div(100_00),
      downPaymentPercent: 0,
      interestRate: pricedItem.interestRate,
      serviceFeeRate: pricedItem.serviceFeeRate,
      term: pricedItem.chosenConfig.term,
      totalNumberOfPayments: pricedItem.chosenConfig.totalNumberOfPayments,
      counterPaidPayments: 0,
      autoRepayStatus,
    },
    planId: pricedItem.planId,
    currency: pricedItem.currency,
    autoRepayStatus,
    signature: pricedItem.signature,
    expiryDate,
    isCyanWalletAsset: pricedItem?.existingPlan ? true : pricedItem.isCyanWalletAsset,
    existingPlan: pricedItem?.existingPlan,
  }));

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(PawnCreateSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);

  const paymentPlanV2 = getPaymentPlanFromChainId(chainId);

  const checkAndApproveNonNativeCurrencyAllowanceMain = async () => {
    const item = paymentPlans[0];
    if (!chainId || !wallet || !provider || !item || !signer) return;
    if (!item.existingPlan || item.existingPlan.totalPay.lte(item.plan.amount)) return;
    const isNativeCurrency = constants.AddressZero.toLowerCase() === item.currency.address.toLowerCase();
    if (item?.existingPlan) {
      const amount = item.existingPlan.totalPay.sub(item.plan.amount);
      if (amount.gt(0)) {
        const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
          currencyAddress: item.currency.address,
          mainWallet: wallet,
          provider,
          chainId,
        });
        // checking wallet balance
        if (
          (!isPayingFromMainWallet && cyanWalletBalance.lt(amount)) ||
          (isPayingFromMainWallet && mainWalletBalance.lt(amount))
        ) {
          openPlanCreationStep1({
            title: `Not enough funds`,
            msg: `Your payment didn’t go through due to lack of funds`,
            description: ` Please double check the amount of crypto in your main wallet. In order to facilitate payments for non-native currency plans, it is imperative that the payment amount be drawn from the Cyan wallet.`,
          });
          return;
        }
        // granting token approval from main wallet
        if (!isNativeCurrency && isPayingFromMainWallet) {
          await giveErc20Approval(
            {
              currencyAddress: item.currency.address,
              amount,
            },
            paymentPlanV2,
          );
        }
      }
    }
  };

  const handleRefinance = async () => {
    const item = paymentPlans[0];
    if (!item || !signer || !item?.existingPlan || !cyanWallet) return;
    let tx: ethers.ContractTransaction;
    const paymentContractWriter = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    const paymentContractWriterDeprecated = factoryDeprecated.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    const isNativeCurrency = constants.AddressZero.toLowerCase() === item.currency.address.toLowerCase();
    const differenceAmount = item.existingPlan.totalPay.gt(item.plan.amount)
      ? item.existingPlan.totalPay.sub(item.plan.amount)
      : BigNumber.from(0);
    const hasRevivalFee = item.existingPlan.revivalFee !== undefined;
    const isPaymentPlanUpdated = experiment.result && experiment.result[getPaymentPlanUpgradeExperiment(chainId)];
    if (hasRevivalFee) {
      if (!revival) {
        openPlanCreationStep1({
          title: `Penalty rate changed`,
          msg: `Please check the penalty amount before proceeding with the plan revival.`,
        });
        return;
      }
    }
    if (isPayingFromMainWallet) {
      if (hasRevivalFee) {
        if (!revival) {
          openPlanCreationStep1({
            title: `Penalty rate changed`,
            msg: `Please check the penalty amount before proceeding with the plan revival.`,
          });
          return;
        }
        tx = isPaymentPlanUpdated
          ? await paymentContractWriter.reviveByRefinance(
              item.item,
              item.plan,
              item.planId,
              item.existingPlan.planId,
              {
                signature: item.signature,
                expiryDate: item.expiryDate,
              },
              {
                signature: revival.signature,
                signatureExpiryDate: revival.expiryDateInSeconds,
                penaltyAmount: revival.penaltyAmount,
              },
              { value: isNativeCurrency && differenceAmount.gt(0) ? differenceAmount.toString() : "0" },
            )
          : await paymentContractWriterDeprecated.reviveByRefinance(
              item.item,
              item.plan,
              item.planId,
              item.existingPlan.planId,
              item.expiryDate,
              item.signature,
              {
                signature: revival.signature,
                signatureExpiryDate: revival.expiryDateInSeconds,
                penaltyAmount: revival.penaltyAmount,
              },
              { value: isNativeCurrency && differenceAmount.gt(0) ? differenceAmount.toString() : "0" },
            );
      } else {
        tx = isPaymentPlanUpdated
          ? await paymentContractWriter.createPawnByRefinance(
              item.item,
              item.plan,
              item.planId,
              item.existingPlan.planId,
              { expiryDate: item.expiryDate, signature: item.signature },
              { value: isNativeCurrency && differenceAmount.gt(0) ? differenceAmount.toString() : "0" },
            )
          : await paymentContractWriterDeprecated.createPawnByRefinance(
              item.item,
              item.plan,
              item.planId,
              item.existingPlan.planId,
              item.expiryDate,
              item.signature,
              { value: isNativeCurrency && differenceAmount.gt(0) ? differenceAmount.toString() : "0" },
            );
      }
    } else {
      let encodedRefinanceFnData = isPaymentPlanUpdated
        ? paymentContractWriter.interface.encodeFunctionData("createPawnByRefinance", [
            item.item,
            item.plan,
            item.planId,
            item.existingPlan.planId,
            { expiryDate: item.expiryDate, signature: item.signature },
          ])
        : paymentContractWriterDeprecated.interface.encodeFunctionData("createPawnByRefinance", [
            item.item,
            item.plan,
            item.planId,
            item.existingPlan.planId,
            item.expiryDate,
            item.signature,
          ]);
      if (hasRevivalFee && revival) {
        encodedRefinanceFnData = isPaymentPlanUpdated
          ? paymentContractWriter.interface.encodeFunctionData("reviveByRefinance", [
              item.item,
              item.plan,
              item.planId,
              item.existingPlan.planId,
              { expiryDate: item.expiryDate, signature: item.signature },
              {
                signature: revival.signature,
                signatureExpiryDate: revival.expiryDateInSeconds,
                penaltyAmount: revival.penaltyAmount,
              },
            ])
          : paymentContractWriterDeprecated.interface.encodeFunctionData("reviveByRefinance", [
              item.item,
              item.plan,
              item.planId,
              item.existingPlan.planId,
              item.expiryDate,
              item.signature,
              {
                signature: revival.signature,
                signatureExpiryDate: revival.expiryDateInSeconds,
                penaltyAmount: revival.penaltyAmount,
              },
            ]);
      }

      const encodedPayFnDataFormatted = [
        paymentPlanV2,
        isNativeCurrency && differenceAmount.gt(0) ? differenceAmount.toString() : "0",
        encodedRefinanceFnData,
      ];

      let encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("execute", encodedPayFnDataFormatted);
      if (!isNativeCurrency && differenceAmount.gt(0)) {
        const encodedAllowanceFnDataFormatted = await getErc20ApprovalFunctionData(
          {
            amount: differenceAmount,
            currencyAddress: item.currency.address,
            cyanWallet,
          },
          paymentPlanV2,
        );
        if (encodedAllowanceFnDataFormatted) {
          encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [
            [encodedAllowanceFnDataFormatted, encodedPayFnDataFormatted],
          ]);
        }
      }
      tx = await signer.sendTransaction({
        to: cyanWallet.walletAddress,
        value: 0,
        data: encodedCyanExecuteFnData,
      });
    }
    await tx.wait();
    addTransaction({
      hash: tx.hash,
      type: "plan-refinance",
      data: {
        planId: item.planId,
        tokenId: item.item.tokenId,
        contractAddress: item.item.contractAddress,
      },
    });
    setActiveTx(tx.hash);
  };

  const checkRevivalFee = async () => {
    if (!items[0].existingPlan?.revivalFee) return;
    const [{ signature, expiryDateInSeconds, penaltyAmount: penaltyAmountBe }] = await getPlanRevivalSignatureBulk(
      [{ planId: items[0].existingPlan.planId }],
      chainId,
    );
    if (!items[0].existingPlan.revivalFee.eq(penaltyAmountBe)) {
      openPlanCreationStep1({
        title: `Penalty rate changed`,
        msg: `Please check the penalty amount before proceeding with the plan revival.`,
      });
      return;
    }
    setRevival({ signature, expiryDateInSeconds, penaltyAmount: penaltyAmountBe });
  };

  useEffect(() => {
    const onStepChange = async (step: PawnCreateSteps) => {
      if (!chainId || !signer || !wallet || !provider) return;
      if (paymentPlans.length > 1) {
        setError("A maximum of 1 NFT can be refinanced at a time.");
        return;
      }
      try {
        switch (step) {
          case PawnCreateSteps.TokenApproval: {
            await checkRevivalFee();
            await checkVaultBalance(
              args.items.map(item => ({
                ...item,
                loanAmount: item.price.mul(item.chosenConfig.loanRate).div(100_00),
              })),
              "Pawn",
              provider,
              wallet,
            );
            await checkAndApproveNonNativeCurrencyAllowanceMain();
            setSelectedStep(PawnCreateSteps.SettingUpAutoRepayments);
            return;
          }
          case PawnCreateSteps.SettingUpAutoRepayments: {
            if (autoRepayStatus === IAutoRepayStatuses.FromMainWallet) {
              const paymentPlanContractAddress = getPaymentPlanFromChainId(chainId).toLowerCase();
              const currency = paymentPlans[0]?.currency;
              if (currency) {
                const totalAmount = items.reduce((acc, cur) => {
                  return acc.add(cur.price.mul(cur.chosenConfig.loanRate).div(100_00));
                }, BigNumber.from(0));
                if (currency.address.toLowerCase() !== constants.AddressZero.toLowerCase()) {
                  await giveErc20Approval(
                    { currencyAddress: currency.address, amount: totalAmount },
                    paymentPlanContractAddress,
                  );
                } else {
                  const wethAddress = nonNativeSupportedCurrenciesData[chainId][SupportedCurrencies.WETH].address;
                  await giveErc20Approval(
                    { currencyAddress: wethAddress, amount: totalAmount },
                    paymentPlanContractAddress,
                  );
                }
              }
            }
            setSelectedStep(PawnCreateSteps.PawningNft);
            return;
          }
          case PawnCreateSteps.PawningNft: {
            await handleRefinance();
          }
        }
      } catch (error) {
        openPlanCreationStep1(error);
        return;
      }
    };

    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openSetupAlertsModal = async () => {
    try {
      await fetchUserBe();
      setModalContent({
        title: `Loan Started`,
        content: <PlanNotificationSettings items={args.items} planType="pawn" />,
        onBack: onBackModal,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const openPlanCreationStep1 = (err: any) => {
    const plan = paymentPlans[0];
    const bnpl = activeBnplPositions.find(
      item =>
        item.tokenId === plan.item.tokenId &&
        item.metadata.collectionAddress.toLowerCase() === plan.item.contractAddress.toLowerCase() &&
        item.planId === plan.existingPlan?.planId,
    );
    const pawn = activePawnPositions.find(
      item =>
        item.tokenId === plan.item.tokenId &&
        item.metadata.collectionAddress.toLowerCase() === plan.item.contractAddress.toLowerCase() &&
        item.planId === plan.existingPlan?.planId,
    );
    const planType = pawn ? "pawn" : "bnpl";
    const error = mapAndLogError(err);
    if (plan.existingPlan?.revivalFee) {
      const bnplDefaulted = pawnPositions.find(
        item =>
          item.tokenId === plan.item.tokenId &&
          item.metadata.collectionAddress.toLowerCase() === plan.item.contractAddress.toLowerCase() &&
          item.planId === plan.existingPlan?.planId,
      );
      const pawnDefaulted = bnplPositions.find(
        item =>
          item.tokenId === plan.item.tokenId &&
          item.metadata.collectionAddress.toLowerCase() === plan.item.contractAddress.toLowerCase() &&
          item.planId === plan.existingPlan?.planId,
      );
      const item = bnplDefaulted ?? pawnDefaulted;
      if (!item) {
        setError("An error occurred while refinancing the plan. Please try again later.");
        return;
      }
      setModalContent({
        title: `Revive`,
        content: (
          <RevivalWithRefinance
            plan={item}
            planType={planType === "pawn" ? PlanTypes.Pawn : PlanTypes.BNPL}
            error={error}
          />
        ),
        onClose,
      });
      return;
    }
    setModalContent({
      title: `Loan Details`,
      content: (
        <PlanRepayment
          pawn={pawn}
          bnpl={bnpl}
          planType={planType}
          onClose={() => {}}
          error={error}
          paymentOption={PaymentOptions.refinance}
        />
      ),
      onClose,
    });
  };

  const stepMarkFiltered = useMemo(() => {
    const steps = getPawnCreateStepMarks(chainId);
    return steps.filter(item => {
      if (!autoRepayStatus && item.value === PawnCreateSteps.SettingUpAutoRepayments) {
        return false;
      }
      return true;
    });
  }, [autoRepayStatus, cyanWallet, chainId]);

  return (
    <Flex gap="5px" direction="column">
      <ItemsMetadata
        items={args.items}
        planType="pawn"
        isCreating={selectedStep !== PawnCreateSteps.Done}
        planAmount={paymentPlans[0].plan.amount}
      />
      {error && <SystemMessage variant="error" title={error} msg={error} />}
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === PawnCreateSteps.Done && (
        <Flex direction="column" gap="10px">
          <StyledConfirmButton onClick={openSetupAlertsModal}>
            <Flex gap="4px" justifyContent="center" alignItems="center">
              <Bell height={16} width={16} strokeWidth="3px" />
              <Text color="black" size="sm" weight="700">
                {`Setup Notifications`}
              </Text>
              <ArrowRight />
            </Flex>
          </StyledConfirmButton>
        </Flex>
      )}
    </Flex>
  );
};

enum PawnCreateSteps {
  TokenApproval = 1,
  SettingUpAutoRepayments = 2,
  PawningNft = 3,
  Done = 4,
}

const getPawnCreateStepMarks = (chainId: number) => {
  return [
    {
      value: PawnCreateSteps.TokenApproval,
      title: `Token Approval`,
    },
    {
      value: PawnCreateSteps.SettingUpAutoRepayments,
      title: `Setting up auto-repayments`,
    },
    {
      value: PawnCreateSteps.PawningNft,
      title: `NFT successfully refinanced`,
      description: getChainExplorerTextForTxn(chainId),
    },
  ];
};

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
