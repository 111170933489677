import { useMemo } from "react";
import { Clock, Users } from "react-feather";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme/components";
import { Button, Text, useModal } from "@cyanco/components/theme/v3";

import { usePrivateSales } from "@/components/Account/AccountDataContext";
import { ApeCoinStakingButtonOnNft } from "@/components/ApeCoinStaking/ApeCoinStakingButtonOnNFT";
import { useAppContext } from "@/components/AppContextProvider";
import { PrivateSaleButtonOnNft } from "@/components/PrivateSale/PrivateSaleButtonOnNFT";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { Experiments } from "@/utils/experimentList";

import { APE_COIN_STAKABLE_ADDRESSES } from "../../../../../config";
import { isApeCoinStakingPossible } from "../../../../../utils";
import { ISelectedNft } from "../NftCard";
import { UserNftMetadata } from "./UserNftMetadata";

export const UserNftDetails = ({
  nft,
  onNftTransfer,
  onPawnNft,
  onClose,
}: {
  nft: ISelectedNft;
  onNftTransfer?: () => void;
  onPawnNft: () => void;
  onClose: () => void;
}) => {
  const { unsetModal } = useModal();
  const navigate = useNavigate();
  const { account, chainId } = useWeb3React();
  const enableApeCoinStaking = isApeCoinStakingPossible(chainId) && APE_COIN_STAKABLE_ADDRESSES.includes(nft.address);
  const { experiment } = useAppContext();

  const { privateSales } = usePrivateSales();
  const privateSale = useMemo(() => {
    if (!account || !nft.tokenId) return;
    return privateSales.find(
      p => p.privateSale?.collectionAddress == nft.address.toLowerCase() && p.privateSale.tokenId == nft.tokenId,
    )?.privateSale;
  }, [nft, experiment, privateSales]);

  return (
    <Flex direction="column" gap="0.5rem">
      <UserNftMetadata
        nft={{
          collectionName: nft.collectionName,
          address: nft.address,
          tokenId: nft.tokenId,
          tokenType: nft.tokenType,
          appraisalValue: nft.appraisalValue,
          currency: nft.currency,
          imageUrl: nft.imageUrl,
          isCyanWalletAsset: nft.isCyanWallet,
          ownership: nft.ownership,
          hasPrivateSale: !!nft?.privateSale || !!privateSale,
        }}
        onNftTransfer={onNftTransfer}
      />
      <FeatureContainer>
        <FeatureButton>
          <StyledConfirmButton onClick={onPawnNft} disabled={!nft.appraisalValue || nft.isBendDao}>
            <Flex gap="0.5rem" justifyContent="center" alignItems="center">
              <Clock width={13} height={13} strokeWidth="3px" />
              <Text color="black" size="sm" weight="600">
                Instant loan
              </Text>
            </Flex>
          </StyledConfirmButton>
        </FeatureButton>

        {experiment.result && experiment.result[Experiments.P2P] && (
          <FeatureButton>
            <StyledConfirmButton
              onClick={() => {
                unsetModal();
                navigate(`/loans/${CHAIN_IDS_TO_NAMES[chainId as SupportedChainId]}/${nft.address}/${nft.tokenId}`);
              }}
            >
              <Flex gap="0.5rem" justifyContent="center" alignItems="center">
                <Users width={13} height={13} strokeWidth="3px" />
                <Text color="black" size="sm" weight="600">
                  {`P2P loans`}
                </Text>
              </Flex>
            </StyledConfirmButton>
          </FeatureButton>
        )}

        <FeatureButton>
          <PrivateSaleButtonOnNft nft={nft} privateSale={privateSale} />
        </FeatureButton>

        {enableApeCoinStaking && (
          <FeatureButton>
            <ApeCoinStakingButtonOnNft onClose={onClose} />
          </FeatureButton>
        )}
      </FeatureContainer>
    </Flex>
  );
};
const StyledConfirmButton = styled(Button)`
  height: 36px;
  padding: 1rem 0;
`;

const FeatureContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 14px;
`;

const FeatureButton = styled(Flex)`
  height: 36px;
  min-width: 40%;
  max-width: 50%;
  flex: 1;
`;
