// Add new experiment name into this enum. (e.g: COUPON_SURVEY = 'coupon survey')
import { SupportedChainId } from "@/constants/chains";

export enum Experiments {
  P2P = "p2p",
  AUTO_LIQUIDATION_MAINNET = "auto_liquidation_mainnet",
  AUTO_LIQUIDATION_SEPOLIA = "auto_liquidation_sepolia",
  AUTO_LIQUIDATION_POLYGON = "auto_liquidation_polygon",
  AUTO_LIQUIDATION_BLAST = "auto_liquidation_blast",
  AUTO_LIQUIDATION_BLAST_SEPOLIA = "auto_liquidation_blast_sepolia",
  AUTO_LIQUIDATION_VAULT = "auto_liquidation_vault",
  UI_V2 = "ui_v2",
}

export const getPaymentPlanUpgradeExperiment = (chainId: SupportedChainId) => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
      return "payment_plan_upgrade_mainnet";
    case SupportedChainId.SEPOLIA:
      return "payment_plan_upgrade_sepolia";
    case SupportedChainId.POLYGON:
      return "payment_plan_upgrade_polygon";
    case SupportedChainId.BLAST:
      return "payment_plan_upgrade_blast";
    case SupportedChainId.BLAST_SEPOLIA:
      return "payment_plan_upgrade_blast_sepolia";
    case SupportedChainId.APECHAIN:
      return "payment_plan_upgrade_apechain";
    case SupportedChainId.CURTIS:
      return "payment_plan_upgrade_curtis";
    default:
      return "payment_plan_upgrade_mainnet";
  }
};
