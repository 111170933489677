import { ErrorBoundary } from "@sentry/react";
import { useWeb3React } from "@web3-react/core";
import { useEffect } from "react";
import Confetti from "react-confetti";
import ReactGA from "react-ga4";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

import { Flex, ModalProvider as ModalProviderV3 } from "@cyanco/components/theme";
import { breakpoints } from "@cyanco/components/theme/config";

import { SelectedItemsProvider } from "@/components/Account/SelectedItemsContext";
import { UserItemsMultiSelection } from "@/components/Account/components/SelectItems/MultiSelectionBox";
import { ApeStakingMultiSelection } from "@/components/ApeCoinStaking/new/components/MultiSelectionBox";
import { Footer } from "@/components/Footer";
import { Header } from "@/components/Header";
import { useQueryParams } from "@/hooks/useQueryParams";

import { ApeCoinPage } from "../components/ApeCoinStaking/new";
import { useAppContext } from "../components/AppContextProvider";
import { TokenContextProvider } from "../components/Token/TokenContextProvider";
import { TransactionContextProvider } from "../components/TransactionContextProvider";
import { WalletContextProvider } from "../components/Wallet/WalletProvider";
import { metaMask, walletConnect } from "../connectors";
import { isSupportedChain } from "../constants/chains";
import { useLocalStorage } from "../hooks";
import usePrevious from "../hooks/usePrevious";
import { NotFoundPage } from "./404";
import AccountHistory from "./AccountHistory";
import BnplCollectionPage from "./BNPL/collection";
import { BNPLLoanPricer } from "./BNPL/pricer";
import BargainShopPage from "./Bargain";
import HomePage from "./HomePage";
import LoanPage from "./Loan";
import BorrowCollectionAssetPage from "./Loan/nft";
import P2P from "./P2P";
import P2PCollection from "./P2P/collection";
import P2PCollectionAssetPage from "./P2P/nft";
import CyanPointsPage from "./Points";
import TestDrivePage from "./Testdrive";
import Vault from "./Vault";
import VaultDetailPage from "./Vault/vault";
import VaultDetailPrivatePage from "./Vault/vaultAdmin";

// eslint-disable-next-line
declare let window: any;

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: clip;
  @media only screen and (max-width: 414px) {
    height: auto;
    padding: 0;
  }
  @media only screen and (max-width: ${breakpoints.tablet}) {
    height: auto;
    padding: 0;
  }
  position: relative;
  height: 100%;
  overflow-y: auto;
`;

const GlobalStyle = createGlobalStyle`
  body, html {
    background-color: ${({ theme }) => theme.backgroundColor};
    ::-webkit-scrollbar{
      display: none;
    }
    *::selection {
      background: ${({ theme }) => theme.theme == "dark" && "#FFFFFF"};
      color: ${({ theme }) => theme.theme == "dark" && "#000000"};
    }
  }
  #root {
    height: 100vh;
    overflow: hidden;
    @media only screen and (max-width: 414px) {
      height: auto;
      overflow:auto;
    }
  }
`;
const App = () => {
  const { collectionTagId } = useQueryParams();
  const { account, chainId } = useWeb3React();
  const location = useLocation();
  const { fireConfetti, setFireConfetti } = useAppContext();
  const previousAccount = usePrevious(account);
  const previousChain = usePrevious(chainId);
  const [_userData, setUserData] = useLocalStorage<{ token: string; wallet: string }>("user", {
    token: "",
    wallet: "",
  });

  useEffect(() => {
    const appWrapper = window.document.getElementById("custom-scroll-parent");
    if (appWrapper) appWrapper.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname, collectionTagId]);

  useEffect(() => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.initialize(process.env.REACT_APP_GA_ID, { testMode: process.env.NODE_ENV !== "production" });
      ReactGA.send({ hitType: "pageview", page: window.location.hash });
    }
  }, []);

  useEffect(() => {
    if (!isSupportedChain(chainId)) return;

    if (previousAccount !== undefined && account !== previousAccount) {
      setUserData(() => ({ token: "", wallet: "" }));
    }

    if (previousChain !== undefined && chainId !== previousChain) {
      setUserData(() => ({ token: "", wallet: "" }));
    }
  }, [account, chainId]);

  useEffect(() => {
    (async () => {
      if (!!window.ethereum) {
        try {
          await metaMask.connectEagerly();
        } catch (e) {
          console.log("Cannot connect metamask eagerly");
        }
      }

      try {
        await walletConnect.connectEagerly();
      } catch (e) {
        console.log("Cannot connect walletconnect eagerly");
      }
    })();
  }, []);

  return (
    <>
      <GlobalStyle />
      <ErrorBoundary
        onError={(error, info) => {
          console.error(error);
          console.error(info);
        }}
      >
        <AppWrapper id="custom-scroll-parent">
          <Confetti
            style={{
              height: "100%",
              width: "100%",
              position: "fixed",
              pointerEvents: "none",
              top: 0,
              zIndex: 100,
            }}
            numberOfPieces={fireConfetti ? 500 : 0}
            gravity={0.1}
            recycle={false}
            initialVelocityY={10}
            onConfettiComplete={confetti => {
              setFireConfetti(false);
              confetti?.reset();
            }}
          />
          <TokenContextProvider>
            <SelectedItemsProvider>
              <TransactionContextProvider>
                <WalletContextProvider>
                  <ModalProviderV3>
                    <Header />
                    {(location.pathname.startsWith("/account") ||
                      location.pathname === "/loans" ||
                      location.pathname === "/testdrive") && <UserItemsMultiSelection />}
                    {location.pathname.startsWith("/ape-coin") && <ApeStakingMultiSelection />}
                    <Flex direction="column">
                      <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/bnpl/loan-pricer" element={<BNPLLoanPricer />} />
                        <Route path="/bnpl/:chain/:collectionSlug" element={<BnplCollectionPage />} />
                        <Route path="/bargain" element={<BargainShopPage />} />
                        <Route path="/vault/:chain/:vaultAddress" element={<VaultDetailPage />} />
                        <Route path="/vault-admin/:chain/:vaultAddress" element={<VaultDetailPrivatePage />} />
                        <Route path="/vault" element={<Vault />} />
                        <Route path="/vault-admin" element={<Vault />} />
                        <Route path="/lend/:chain/:collectionAddress/:tokenId" element={<P2PCollectionAssetPage />} />
                        <Route path="/lend/:chain/:collectionAddress" element={<P2PCollection />} />
                        <Route path="/lend" element={<P2P />} />
                        <Route path="/account/:panel" element={<AccountHistory />} />
                        <Route path="/account" element={<AccountHistory />} />
                        <Route path="/loans" element={<LoanPage />} />
                        <Route path="/points" element={<CyanPointsPage />} />
                        <Route
                          path="/loans/:chain/:collectionAddress/:tokenId"
                          element={<BorrowCollectionAssetPage />}
                        />
                        <Route path="/ape-coin/:panel" element={<ApeCoinPage />} />
                        <Route path="/ape-coin" element={<ApeCoinPage />} />
                        <Route path="/testdrive" element={<TestDrivePage />} />
                        <Route path="/404" element={<NotFoundPage />} />
                        <Route path="*" element={<Navigate to="/404" />} />
                      </Routes>
                      <Footer showArticlesSection={location.pathname === "/"} />
                    </Flex>
                  </ModalProviderV3>
                </WalletContextProvider>
              </TransactionContextProvider>
            </SelectedItemsProvider>
          </TokenContextProvider>
        </AppWrapper>
      </ErrorBoundary>
    </>
  );
};

export default App;
