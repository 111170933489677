import { BigNumber } from "ethers";

import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

export enum IRequestedProjectStatus {
  Pending,
  Approved,
  Rejected,
}
export interface IVaultLatestLoan {
  planId: number;
  tokenId: string;
  collectionAddress: string;
  collectionName: string;
  nftImageUrl: string;
  totalNumOfPayments: number;
  currentNumOfPayments: number;
  monthlyAmount: string;
  amountToCompleteLoan: string;
  appraisalValue: string;
  loanAmount: string;
  createdAt: Date;
  term: number;
  interestRate: number;
  creatorWallet: string;
  status: number;
}

export interface IVaultAdditionalMetrics {
  totalDefaultRate: number;
  collections: {
    name: string;
    total: string;
  }[];
  latestLoans: IVaultLatestLoan[];
  cashflowData: {
    month: string;
    principalAmount: string;
    interestAmount: string;
  }[];
}

export type IVaultSupportedProject = {
  address: string;
  name: string;
  slug: string;
  imageUrl: string;
  isPawnAllowed: boolean;
  isBnplAllowed: boolean;
};

export type IVaultRequestedProject = {
  id: number;
  status: IRequestedProjectStatus;
  name: string;
  address: string;
  imageUrl: string;
  isPawnAllowed: boolean;
  isBnplAllowed: boolean;
};

export interface IVaultBE {
  id: number;
  colorCode: string;
  name: string;
  decimals: number;
  abiName: string;
  transactionFee: number;
  contractAddress: string;
  contractTokenAddress: string;
  symbol: string;
  chainId: number;
  currency: SupportedCurrenciesType;
  numOfBorrowers?: number;
  numOfNFTs?: number;
  numOfStakers: number;
  description: string;
  createdAt: Date;
  volume?: BigNumber;

  price?: BigNumber;
  estimatedYield?: number;
  utilizationRate?: number;
  totalValueLocked?: BigNumber;
  totalLoanedAmount?: BigNumber;
  defaultedNftAmount: BigNumber;

  supportedProjects: Array<IVaultSupportedProject>;

  history: {
    price: BigNumber;
    ethUsdPrice: number;
    estimatedYield: number;
    utilizationRate: number;
    totalSupply?: string;
    totalValueLocked?: BigNumber;
    balanceEth?: BigNumber;
    totalLoan?: BigNumber;
    createdAt: string;
  }[];
  isAutoLiquidated: boolean;
}

export interface IVault extends IVaultBE {
  priceUsd: number;
  totalValueLockedUsd: number;
  withdrawLockTerm: number | BigNumber;
}

export type FnFetchVaults = () => Promise<Array<IVaultBE>>;
export type FnFetchVaultAdditionalMetrics = (contractAddress: string) => Promise<IVaultAdditionalMetrics>;
