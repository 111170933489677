import { BigNumber } from "ethers";

import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { INft } from "@/types";

import { axios } from "../axios";
import { IPricerErrored, IPricerResult, isPricerErrored } from "./pricer-pawn-step1-v2";
import { IPricerConfig } from "./types";

interface IParams {
  wallet?: string;
  chainId: SupportedChainId;
  currentCurrencyAddress: string;
  targetCurrencyAddress: string;
  items: Array<
    INft & {
      price: BigNumber;
      isAutoLiquidated: boolean;
    }
  >;
}
interface IRequestData {
  chain: string;
  wallet?: string;
  currencyAddress: string;
  items: Array<
    INft & {
      price: {
        amount: string;
        currency: string;
      };
      isAutoLiquidated: boolean;
    }
  >;
}
interface IRequestDataDeprecated {
  chain: string;
  wallet?: string;
  currencyAddress: string;
  items: Array<
    INft & {
      price: {
        amount: string;
        currency: string;
      };
    }
  >;
}

const serializeRequestData = (data: IParams): IRequestData => {
  const chain = CHAIN_IDS_TO_NAMES[data.chainId];
  return {
    chain,
    currencyAddress: data.targetCurrencyAddress,
    wallet: data.wallet,
    items: data.items.map(item => ({
      address: item.address,
      tokenId: item.tokenId,
      itemType: item.itemType,
      amount: item.amount,
      price: {
        amount: item.price.toString(),
        currency: data.currentCurrencyAddress,
      },
      isAutoLiquidated: item.isAutoLiquidated,
    })),
  };
};

const serializeRequestDataDeprecated = (data: IParams): IRequestDataDeprecated => {
  const chain = CHAIN_IDS_TO_NAMES[data.chainId];
  return {
    chain,
    currencyAddress: data.targetCurrencyAddress,
    wallet: data.wallet,
    items: data.items.map(item => ({
      address: item.address,
      tokenId: item.tokenId,
      itemType: item.itemType,
      amount: item.amount,
      price: {
        amount: item.price.toString(),
        currency: data.currentCurrencyAddress,
      },
    })),
  };
};

export const priceBnplStep1 = async (args: IParams): Promise<IResult> => {
  const data = serializeRequestData(args);
  const response = (await axios.post<IResponseData>("/v2/pricer/bnpl-step1-v2", data)).data;
  return {
    ...response,
    items: response.items.map(item => {
      if (isPricerErrored(item)) {
        return { error: item.error };
      } else {
        const i = item as {
          interestRate: number;
          price: string;
          config: IPricerConfig;
          vaultId: number;
        };
        return {
          baseInterestRate: i.interestRate,
          price: BigNumber.from(i.price),
          config: i.config,
          vaultId: i.vaultId,
        };
      }
    }),
  };
};

export const priceBnplStep1Deprecated = async (args: IParams): Promise<IResult> => {
  const data = serializeRequestDataDeprecated(args);
  const response = (await axios.post<IResponseData>("/v2/pricer/bnpl-step1-v2", data)).data;
  return {
    ...response,
    items: response.items.map(item => {
      if (isPricerErrored(item)) {
        return { error: item.error };
      } else {
        const i = item as {
          interestRate: number;
          price: string;
          config: IPricerConfig;
          vaultId: number;
        };
        return {
          baseInterestRate: i.interestRate,
          price: BigNumber.from(i.price),
          config: i.config,
          vaultId: i.vaultId,
        };
      }
    }),
  };
};

type IResponseData = {
  couponDiscountRate: number;
  items: (
    | {
        interestRate: number;
        price: string;
        config: IPricerConfig;
        vaultId: number;
      }
    | IPricerErrored
  )[];
  baseConfig: {
    [vaultId: number]: IPricerConfig;
  };
};

export type IResult = {
  items: (IPricerResult | IPricerErrored)[];
  baseConfig: {
    [vaultId: number]: IPricerConfig;
  };
};
