import { BigNumber, constants } from "ethers";
import { useContext, useEffect, useMemo, useState } from "react";
import { Bell } from "react-feather";
import styled from "styled-components";

import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";
import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Box, Flex } from "@cyanco/components/theme";
import { Button, Stepper, Text, useModal } from "@cyanco/components/theme/v3";
import { ArrowRight } from "@cyanco/components/theme/v3/icons";
import { factories as f } from "@cyanco/contract";

import { AuthContext } from "@/components/AuthContext/AuthContextProvider";
import { BAKCAddress, BAYCAddress, MAYCAddress, nonNativeSupportedCurrenciesData } from "@/config";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { usePlanCreation } from "@/hooks";
import { useApproval } from "@/hooks/useApproval";
import { IStep1ResultV2 } from "@/hooks/usePlanCreation.types";
import { IAutoRepayStatuses, INftType, IPlanCreatableNft } from "@/types";
import { getChainExplorerTextForTxn, getChainExplorerURL } from "@/utils";
import { mapAndLogError } from "@/utils/error";

import { usePawnPositions } from "../../../Account/AccountDataContext";
import { useApeCoinStatsContext } from "../../../ApeCoinStaking/new/ApeCoinStatsContext";
import { ApeCoinStakingApyButton } from "../../../ApeCoinStaking/new/components/common";
import { useTransactionContext } from "../../../TransactionContextProvider";
import { useWeb3React } from "../../../Web3ReactProvider";
import { ItemsMetadata } from "../../ItemsMetadata";
import { PlanNotificationSettings } from "../../PlanNotificationSettings";
import { checkVaultBalance } from "../../utils";
import { IPlanConfig, PlanCreationModal, PlanCreationSteps } from "../PlanCreationModal";

type IProps = {
  items: Array<
    IPlanCreatableNft & {
      vaultAddress: string;
      interestRate: number;
      planId: number;
      signature: string;
      chosenConfig: IPlanConfig;
      serviceFeeRate: number;
    }
  >;
  autoRepayStatus: number;
  expiryDate: number;
  step1Result: IStep1ResultV2;
  onClose?: () => void;
};
export const PawnCreationProgressV2: React.FC<IProps> = args => {
  const { autoRepayStatus, expiryDate, items, step1Result, onClose } = args;
  const { chainId, provider, account: wallet, signer } = useWeb3React();
  const { fetchPawnPositions } = usePawnPositions();
  const { transactions } = useTransactionContext();
  const { setModalContent, onBackModal } = useModal();
  const { giveNftPermission, giveErc20Approval } = useApproval();
  const { fetchUserBe } = useContext(AuthContext);
  const { cyanWallet, createNewWallet } = useCyanWalletContext();
  const { createPlans } = usePlanCreation("pawn");
  const [selectedStep, setSelectedStep] = useState<PawnCreateSteps>(PawnCreateSteps.CreatingCyanWallet);
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);

  const paymentPlans = args.items.map(pricedItem => ({
    item: {
      amount: pricedItem.amount,
      tokenId: pricedItem.tokenId,
      contractAddress: pricedItem.address,
      cyanVaultAddress: pricedItem.vaultAddress,
      itemType: pricedItem.itemType,
    },
    plan: {
      amount: pricedItem.price.mul(pricedItem.chosenConfig.loanRate).div(100_00),
      downPaymentPercent: 0,
      interestRate: pricedItem.interestRate,
      serviceFeeRate: pricedItem.serviceFeeRate,
      term: pricedItem.chosenConfig.term,
      totalNumberOfPayments: pricedItem.chosenConfig.totalNumberOfPayments,
      counterPaidPayments: 0,
      autoRepayStatus,
    },
    planId: pricedItem.planId,
    currency: pricedItem.currency,
    autoRepayStatus,
    signature: pricedItem.signature,
    expiryDate,
    isCyanWalletAsset: pricedItem.isCyanWalletAsset,
  }));

  const transferRequired =
    args.items.length > 1 && args.items.some(item => !item.isCyanWalletAsset && item.itemType === INftType.ERC1155);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(PawnCreateSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);
  useEffect(() => {
    const onStepChange = async (step: PawnCreateSteps) => {
      if (!chainId || !signer || !wallet || !provider) return;
      try {
        switch (step) {
          case PawnCreateSteps.CreatingCyanWallet: {
            await checkVaultBalance(
              args.items.map(item => ({
                ...item,
                loanAmount: item.price.mul(item.chosenConfig.loanRate).div(100_00),
              })),
              "Pawn",
              provider,
              wallet,
            );
            if (!cyanWallet && paymentPlans.length > 1) {
              await createNewWallet(signer);
            }
            setSelectedStep(PawnCreateSteps.GivingPermissionToCyanProtocol);
            return;
          }
          case PawnCreateSteps.GivingPermissionToCyanProtocol: {
            const paymentPlanContractAddress = getPaymentPlanFromChainId(chainId).toLowerCase();
            const isBendDao = items.length === 1 && items[0]?.isBendDao;
            if (isBendDao) {
              await giveNftPermission(
                {
                  itemType: items[0].itemType,
                  tokenId: items[0].tokenId,
                  collectionAddress: items[0].address,
                  ignoreOwnerCheck: true,
                },
                paymentPlanContractAddress,
              );
              setSelectedStep(PawnCreateSteps.SettingUpAutoRepayments);
              return;
            }
            for (const { item, isCyanWalletAsset } of paymentPlans) {
              if (
                (item.itemType === INftType.ERC721 && !isCyanWalletAsset) ||
                (paymentPlans.length === 1 &&
                  item.itemType === INftType.ERC1155 &&
                  !isCyanWalletAsset &&
                  !transferRequired) ||
                (item.itemType === INftType.CryptoPunks && !isCyanWalletAsset)
              ) {
                await giveNftPermission(
                  {
                    itemType: item.itemType,
                    tokenId: item.tokenId,
                    collectionAddress: item.contractAddress,
                  },
                  paymentPlanContractAddress,
                );
              }
            }
            setSelectedStep(transferRequired ? PawnCreateSteps.TransferNft : PawnCreateSteps.SettingUpAutoRepayments);
            return;
          }
          case PawnCreateSteps.TransferNft: {
            if (!cyanWallet) {
              throw new Error("Cyan wallet not found");
            }
            const transferRequiredItems = args.items.filter(
              item => !item.isCyanWalletAsset && item.itemType === INftType.ERC1155,
            );
            const nftsByAddress = transferRequiredItems.reduce<{
              [key: string]: IPlanCreatableNft[];
            }>((acc, cur) => {
              const item = acc[cur.address];
              return {
                ...acc,
                [cur.address]: item ? [...item, cur] : [cur],
              };
            }, {});
            for (const address of Object.keys(nftsByAddress)) {
              const contractIFace = f.SampleERC1155TokenFactory.connect(address, signer);
              const nfts = nftsByAddress[address];
              const tx = await contractIFace.safeBatchTransferFrom(
                wallet,
                cyanWallet.walletAddress,
                nfts.map(nft => nft.tokenId),
                nfts.map(nft => nft.amount),
                [],
              );
              await tx.wait();
            }

            setSelectedStep(PawnCreateSteps.SettingUpAutoRepayments);
            return;
          }
          case PawnCreateSteps.SettingUpAutoRepayments: {
            if (autoRepayStatus === IAutoRepayStatuses.FromMainWallet) {
              const paymentPlanContractAddress = getPaymentPlanFromChainId(chainId).toLowerCase();
              const currency = paymentPlans[0]?.currency;
              if (currency) {
                const totalAmount = items.reduce((acc, cur) => {
                  return acc.add(cur.price.mul(cur.chosenConfig.loanRate).div(100_00));
                }, BigNumber.from(0));
                if (currency.address.toLowerCase() !== constants.AddressZero.toLowerCase()) {
                  await giveErc20Approval(
                    { currencyAddress: currency.address, amount: totalAmount },
                    paymentPlanContractAddress,
                  );
                } else {
                  const wethAddress = nonNativeSupportedCurrenciesData[chainId][SupportedCurrencies.WETH].address;
                  await giveErc20Approval(
                    { currencyAddress: wethAddress, amount: totalAmount },
                    paymentPlanContractAddress,
                  );
                }
              }
            }
            setSelectedStep(PawnCreateSteps.VaultFunding);
            return; // This case is only for UX improvement
          }
          case PawnCreateSteps.VaultFunding: {
            const tx = await createPlans({ paymentPlans, isBendDao: items[0]?.isBendDao });
            if (tx) setActiveTx(tx.hash);
            await fetchPawnPositions();
            return;
          }
        }
      } catch (e) {
        openPlanCreationStep1(e);
      }
    };

    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);

  const openSetupAlertsModal = async () => {
    try {
      await fetchUserBe();
      setModalContent({
        title: `Loan Started`,
        content: <PlanNotificationSettings items={args.items} planType="pawn" />,
        onBack: onBackModal,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const openPlanCreationStep1 = (err: any) => {
    const mappedError = mapAndLogError(err, wallet);
    setModalContent({
      title: "Borrow",
      hideHeader: true,
      content: (
        <PlanCreationModal
          planType="pawn"
          items={items}
          currency={step1Result.currency}
          pricePlanStep1={step1Result.pricePlanStep1}
          pricePlanStep2={step1Result.pricePlanStep2}
          pricePlanStep1Result={step1Result.pricerPlan}
          triggeredError={mappedError}
          currentStep={PlanCreationSteps.SelectTerm}
          onClose={onClose}
        />
      ),
      onClose: onClose,
    });
  };

  const stepMarkFiltered = useMemo(() => {
    const steps = getPawnCreateStepMarks(chainId);
    return steps.filter(item => {
      if (!autoRepayStatus && item.value === PawnCreateSteps.SettingUpAutoRepayments) {
        return false;
      }
      if (cyanWallet && item.value === PawnCreateSteps.CreatingCyanWallet) {
        return false;
      }
      if (!transferRequired && item.value === PawnCreateSteps.TransferNft) {
        return false;
      }
      return true;
    });
  }, [autoRepayStatus, cyanWallet, transferRequired, chainId]);

  const { poolsWithBorrow } = useApeCoinStatsContext();

  const apy = useMemo(() => {
    if (items.some(item => item.address.toLowerCase() == BAYCAddress.toLowerCase())) {
      return poolsWithBorrow.BAYC.apy;
    }
    if (items.some(item => item.address.toLowerCase() == MAYCAddress.toLowerCase())) {
      return poolsWithBorrow.MAYC.apy;
    }
    if (items.some(item => item.address.toLowerCase() == BAKCAddress.toLowerCase())) {
      return poolsWithBorrow.BAKC.apy;
    }
    return null;
  }, [poolsWithBorrow, items]);

  const handleApyClick = () => {
    window.open("/ape-coin", "_blank");
  };

  return (
    <Flex gap="5px" direction="column">
      <ItemsMetadata
        items={args.items}
        planType="pawn"
        isCreating={selectedStep !== PawnCreateSteps.Done}
        planAmount={paymentPlans[0].plan.amount}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={stepMarkFiltered}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === PawnCreateSteps.Done && (
        <Flex direction="column" gap="10px">
          <StyledConfirmButton onClick={openSetupAlertsModal}>
            <Flex gap="4px" justifyContent="center" alignItems="center">
              <Bell height={16} width={16} strokeWidth="3px" />
              <Text color="black" size="sm" weight="700">
                {`Setup Notifications`}
              </Text>
              <ArrowRight />
            </Flex>
          </StyledConfirmButton>
          {!!apy && <ApeCoinStakingApyButton onClick={handleApyClick} apy={apy} />}
        </Flex>
      )}
    </Flex>
  );
};

enum PawnCreateSteps {
  CreatingCyanWallet = 1,
  GivingPermissionToCyanProtocol = 2,
  TransferNft = 3,
  SettingUpAutoRepayments = 4,
  VaultFunding = 5,
  SendingEth = 6,
  PawningNft = 7,
  Done = 8,
}

const getPawnCreateStepMarks = (chainId: number) => {
  return [
    {
      value: PawnCreateSteps.CreatingCyanWallet,
      description: `A new wallet is created on the first purchase on Cyan`,
      title: `Creating Cyan Wallet`,
    },
    {
      value: PawnCreateSteps.GivingPermissionToCyanProtocol,
      description: `To move NFT to use as collateral, gas required`,
      title: `Give Permission to Cyan Protocol`,
    },
    {
      value: PawnCreateSteps.TransferNft,
      description: `To transfer NFT, gas required`,
      title: `Transfer ERC-1155 tokens to Cyan Wallet`,
    },
    {
      value: PawnCreateSteps.SettingUpAutoRepayments,
      title: `Setting up auto-repayments`,
    },
    {
      value: PawnCreateSteps.VaultFunding,
      title: `Funding from Vault`,
    },
    {
      value: PawnCreateSteps.SendingEth,
      title: `ETH sent to user`,
    },
    {
      value: PawnCreateSteps.PawningNft,
      title: `NFT successfully pawned`,
      description: getChainExplorerTextForTxn(chainId),
    },
  ];
};

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
