import { captureException } from "@sentry/react";
import dayjs from "dayjs";
import { BigNumber, constants, ethers } from "ethers";
import orderBy from "lodash.orderby";
import { useMemo, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";
import { HelpCircle } from "react-feather";
import styled, { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";
import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Box, Flex } from "@cyanco/components/theme";
import {
  Card,
  SwitchButton,
  SwitchButtonGroup,
  SystemMessage,
  Text,
  Tooltip,
  TooltipText,
  useModal,
} from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";

import { fetchPlanTopOffer } from "@/apis/early-unwind";
import { IOffer } from "@/apis/early-unwind/types";
import {
  pricePawnStep1Deprecated,
  pricePawnStep1 as pricePawnStep1V2,
  pricePawnStep2Deprecated,
  pricePawnStep2 as pricePawnStep2V2,
} from "@/apis/pricer/index-v2";
import { IEarlyPaymentInfo, INextPayment } from "@/apis/types";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { nonNativeSupportedCurrenciesData } from "@/config";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { usePersistedWalletTypeForRelease } from "@/hooks";
import { useApproval } from "@/hooks/useApproval";
import { IAutoRepayStatuses, INftType, ITEM_AMOUNT_BY_NFT_TYPE } from "@/types";
import { bigNumToFixedStr } from "@/utils";
import { getAccountBalanceOfErc20 } from "@/utils/contract";
import { IMappedError } from "@/utils/error/msgs";
import { Experiments } from "@/utils/experimentList";

import { IPawn, PawnStatuses } from "../Account/pawn.types";
import { IPayment } from "../Account/payment.types";
import { useApeStakingUserAssets } from "../ApeCoinStaking/new/ApeCoinDataContext";
import { useAppContext } from "../AppContextProvider";
import { BNPLStatuses, IBNPL } from "../Bnpl/bnpl.types";
import { autoLiquidationExperimentFlag, getInterestRate, getPlanPrincipleAmount } from "../PlanCreation/utils";
import { PlanCreationModal, PlanCreationSteps } from "../PlanCreation/v2/PlanCreationModal";
import { EarlyRepayment } from "./EarlyRepayment";
import { SellNft, SellNftDefault } from "./EarlyUnwind/EarlyUnwindDetail";
import {
  EARLY_UNWIND_MINUTE_THRESHOLD,
  IPayerWallet,
  PlanEarlyUnwindProgress,
} from "./EarlyUnwind/PlanEarlyUnwindProgress";
import { WalletOptionEarlyUnwind } from "./EarlyUnwind/WalletOptionEarlyUnwind";
import {
  AutoRepaymentApprove,
  AutoRepaymentBox,
  AutoRepaymentSwitch,
  AutoRepaymentWarning,
  AutoRepaymentWarningNonNative,
} from "./PlanAutoRepayment";
import { DefaultedPlanMetadata, PlanMetadata } from "./PlanMetadata";
import { PayBox } from "./PlanPayBox";
import { PlanPaymentLoading } from "./PlanPaymentLoading";
import { PlanRepaymentProgress } from "./PlanRepaymentProgress";
import { PositionDefaulted } from "./PlanStatuses";
import { RegularPayment } from "./RegularPayment";
import { WalletOptionModal } from "./WalletOptionModal";
import { WalletSelectorForNFTRelease } from "./WalletSelectorForNFTRelease";

export type IPaymentsMapped = IPayment & {
  isRevival: boolean;
};
export enum PaymentOptions {
  regularPay = "pay",
  earlyPay = "earlyPay",
  sell = "sell",
  refinance = "refinance",
}

export const switch_btn_height = "22px";

export const PlanRepayment = ({
  pawn,
  bnpl,
  planType,
  onClose,
  error: _error,
  paymentOption: _paymentOption,
}: {
  pawn?: IPawn;
  bnpl?: IBNPL;
  error?: IMappedError;
  planType: "pawn" | "bnpl";
  paymentOption?: PaymentOptions;
  onClose: () => void;
}) => {
  const plan = pawn ?? bnpl;
  const isBnpl = planType === "bnpl" && bnpl !== undefined;
  if (!plan) {
    throw new Error("Plan is not defined");
  }
  const { provider, chainId, account, signer } = useWeb3React();
  const { experiment } = useAppContext();
  const { loading } = useApeStakingUserAssets();
  const theme = useTheme();
  const { setModalContent } = useModal();
  const { transactions } = useTransactionContext();
  const cyanWallet = useCyanWallet();
  const { giveErc20Approval, isErc20ApprovalRequired } = useApproval();

  const { result: nextPayment, loading: nextPaymentLoading } = useAsync<INextPayment | undefined>(async () => {
    if (!signer || !chainId) return;
    const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
    const paymentPlanReader = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    const { payAmountForCollateral, payAmountForInterest, payAmountForService, dueDate, currentPayment } =
      await paymentPlanReader.getPaymentInfoByPlanIdMapped(plan.planId, false);
    if (dueDate && dueDate.getTime() < Date.now()) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
      return;
    }
    return {
      payAmountForCollateral,
      payAmountForInterest,
      payAmountForService,
      nextPaymentDate: dueDate,
      currentPayment,
    };
  }, [plan, signer]);

  const { result: earlyRepayment, loading: earlyRepaymentLoading } = useAsync<
    IEarlyPaymentInfo | undefined
  >(async () => {
    if (!signer || !chainId) return;
    const totalNumOfPaymentsLeft = plan.totalNumOfPayments - plan.currentNumOfPayments;
    if (totalNumOfPaymentsLeft <= 1 || !isActivePlan || plan.totalNumOfPayments <= 1) return;
    const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
    const paymentPlanReader = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    const {
      payAmountForCollateral,
      payAmountForInterest,
      payAmountForService,
      dueDate,
      currentPayment: remainingPayment,
    } = await paymentPlanReader.getPaymentInfoByPlanIdMapped(plan.planId, true);
    return {
      payAmountForCollateral,
      payAmountForInterest,
      payAmountForService,
      dueDate,
      remainingPayment,
    };
  }, [plan, signer]);

  const {
    result: planTopBid,
    loading: planTopBidLoading,
    execute: getTopBid,
  } = useAsyncCallback<IOffer | null>(async () => {
    if (!nextPayment) return null;
    if (nextPayment.nextPaymentDate && nextPayment.nextPaymentDate.getTime() < Date.now()) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
      return null;
    }
    return await fetchPlanTopOffer({ planId: plan.planId });
  });

  const { result: isAutoRepayApprovalRequired, execute: checkAutoRepayApproval } = useAsync<boolean>(async () => {
    if (!signer) return false;
    const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
    const paymentPlanReader = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    const paymentPlan = await paymentPlanReader.paymentPlan(plan.planId);
    if (paymentPlan.plan.autoRepayStatus !== IAutoRepayStatuses.FromMainWallet) return false;
    const wethAddress = nonNativeSupportedCurrenciesData[chainId][SupportedCurrencies.WETH].address;
    const result = await isErc20ApprovalRequired(
      {
        currencyAddress: isNativeCurrencyPlan ? wethAddress : plan.currency.address,
        amount: totalLeftAmount,
      },
      plan.paymentPlan.address,
    );
    return result;
  }, [signer]);

  const {
    result: autoRepaymentStatus = IAutoRepayStatuses.Disabled,
    execute: checkAutoRepayStatusFromBlockchain,
    merge: setAutoRepaymentStatus,
  } = useAsync<IAutoRepayStatuses>(async () => {
    if (!signer || !chainId) return IAutoRepayStatuses.Disabled;
    const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
    const paymentPlanReader = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    const paymentPlan = await paymentPlanReader.paymentPlan(plan.planId);
    return paymentPlan.plan.autoRepayStatus;
  }, [plan, signer]);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [paymentOption, setPaymentOption] = useState<PaymentOptions>(_paymentOption ?? PaymentOptions.regularPay);
  const [defaultedError, setDefaultedError] = useState<string | null>();
  const { walletTypeForRelease } = usePersistedWalletTypeForRelease();
  const [releaseWallet, setReleaseWallet] = useState<string>(
    (walletTypeForRelease === "main" ? account : cyanWallet?.walletAddress) ?? "",
  );
  const [error, setError] = useState<IMappedError | null>(_error || null);
  const [warning, setWarning] = useState<{
    msg: string;
    title: string;
    description?: string;
  } | null>(null);
  const [isNotifiedTransferWarning, setIsNotifiedTransferWarning] = useState<boolean>(false);

  const bnplOriginalPrice = bnpl?.price;
  const isActivePlan = isBnpl ? plan.status === BNPLStatuses.Activated : plan.status === PawnStatuses.Activated;
  const isDefaultedPlan = isBnpl
    ? plan.status === BNPLStatuses.Defaulted || plan.status === BNPLStatuses.Liquidated
    : plan.status === PawnStatuses.Defaulted || plan.status === PawnStatuses.Liquidated;
  const totalNumOfPaymentsLeft = plan.totalNumOfPayments - plan.currentNumOfPayments;
  const totalNumOfPaymentsByPlanType = planType === "bnpl" ? plan.totalNumOfPayments - 1 : plan.totalNumOfPayments;
  const totalLoanAmount = BigNumber.from(plan.monthlyAmount).mul(totalNumOfPaymentsByPlanType);
  const totalLeftAmount = BigNumber.from(plan.monthlyAmount).mul(totalNumOfPaymentsLeft);
  const totalCost = isBnpl ? totalLoanAmount.add(BigNumber.from(bnpl.downpaymentAmount)) : totalLoanAmount;
  const principleAmount = getPlanPrincipleAmount(plan);
  const totalRepayAmount = BigNumber.from(plan.serviceFeeAmount).add(plan.interestFee).add(principleAmount);
  const _interestRate = getInterestRate({
    payAmount: totalRepayAmount,
    principleAmount,
    decimals: plan.currency.decimal,
  });
  const nextPaymentDate = nextPayment?.nextPaymentDate;
  // checking if plan's due date within 30min
  const isPlanDueDateClose =
    !!nextPaymentDate &&
    !defaultedError &&
    dayjs(nextPaymentDate).diff(new Date(), "minutes") <= EARLY_UNWIND_MINUTE_THRESHOLD;

  const openProgressModal = ({
    selectedWalletAddress,
    isEarlyRepayment,
    isNativeCurrency,
    paymentAmount,
  }: {
    selectedWalletAddress: string;
    isEarlyRepayment: boolean;
    isNativeCurrency: boolean;
    paymentAmount: BigNumber;
  }) => {
    setModalContent({
      title: `Confirm Payment`,
      content: (
        <PlanRepaymentProgress
          plan={{
            ...plan,
            isBnpl,
            totalNumOfPaymentsLeft,
            address: plan.metadata.collectionAddress,
            collectionName: plan.metadata.collection.name,
            totalAmount: totalLoanAmount,
            leftAmount: totalLeftAmount,
            imageUrl: plan.metadata.imageUrl,
            purchasedPrice: bnplOriginalPrice,
          }}
          selectedWalletAddress={selectedWalletAddress}
          currency={plan.currency}
          payment={{
            isEarlyRepayment,
            amount: paymentAmount,
            isNativeCurrency,
            releaseWallet,
          }}
          onClose={onClose}
          bnpl={bnpl}
          pawn={pawn}
        />
      ),
      onClose,
    });
  };

  const checkAccountBalanceAndProceed = async ({
    paymentAmount,
    isEarlyRepayment,
    isNativeCurrency,
  }: {
    paymentAmount: BigNumber;
    isEarlyRepayment: boolean;
    isNativeCurrency: boolean;
  }) => {
    if (!provider || !account) return;
    const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
      currencyAddress: plan.currency.address,
      provider: provider,
      mainWallet: account,
      chainId,
    });
    if (paymentAmount.gt(mainWalletBalance) && paymentAmount.gt(cyanWalletBalance)) {
      setError({
        title: `Not enough funds`,
        msg: `Your payment didn’t go through due to lack of funds`,
        description: ` Please double check the amount of crypto in your main wallet.`,
      });
      return;
    }
    if (cyanWallet) {
      setModalContent({
        content: (
          <WalletOptionModal
            items={[plan]}
            mainWalletBalance={mainWalletBalance}
            cyanWalletBalance={cyanWalletBalance}
            currency={plan.currency}
            paymentAmount={paymentAmount}
            totalAmount={totalLoanAmount}
            leftAmount={totalLeftAmount}
            purchasedPrice={bnplOriginalPrice}
            onNext={({ selectedWalletAddress }: { selectedWalletAddress: string }) => {
              openProgressModal({
                selectedWalletAddress,
                paymentAmount,
                isEarlyRepayment,
                isNativeCurrency,
              });
            }}
          />
        ),
        title: "Select wallet to pay",
      });
    } else {
      openProgressModal({
        selectedWalletAddress: account,
        paymentAmount,
        isEarlyRepayment,
        isNativeCurrency,
      });
    }
    return;
  };

  const handlePaymentV2 = async (isEarlyRepayment: boolean, paymentAmount: BigNumber) => {
    setError(null);
    if (isProcessing || !signer || defaultedError || !chainId || !account || !cyanWallet) return;
    setIsProcessing(true);
    let isNativeCurrency = true;
    const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
    const paymentContractWriter = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
    isNativeCurrency = ethers.constants.AddressZero.toLowerCase() === plan.currency.address.toLowerCase();
    if (!isNativeCurrency) {
      const currencyAddress = await paymentContractWriter.getCurrencyAddressByPlanId(plan.planId);
      const contract = f.SampleERC20TokenFactory.connect(currencyAddress, signer);
      if (!isNotifiedTransferWarning && releaseWallet.toLowerCase() !== cyanWallet.walletAddress.toLowerCase()) {
        // checking non-native currency balance of cyan wallet
        const userBalance = await contract.balanceOf(cyanWallet.walletAddress);
        if (userBalance.lt(paymentAmount)) {
          setWarning({
            title: `Main Wallet Release`,
            msg: `You have selected to release the NFT to your Main wallet.`,
            description: `When releasing to your Main wallet, you will need to have your Cyan Wallet funded with the payment, in the loan currency.`,
          });
          setIsNotifiedTransferWarning(true);
          setIsProcessing(false);
          return;
        }
      }
    }
    await checkAccountBalanceAndProceed({
      paymentAmount,
      isEarlyRepayment,
      isNativeCurrency,
    });
    setIsProcessing(false);
  };

  const handleRegularPay = async () => {
    if (!nextPayment) return;
    if (nextPaymentDate && nextPaymentDate.getTime() < Date.now()) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
      return;
    }
    handlePaymentV2(false, nextPayment.currentPayment);
  };

  const handleEarlyPay = async () => {
    if (!earlyRepayment) return;
    if (earlyRepayment.dueDate && earlyRepayment.dueDate.getTime() < Date.now()) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
      return;
    }
    handlePaymentV2(true, earlyRepayment.remainingPayment);
  };
  const amountToComplete = useMemo(() => {
    if (nextPaymentDate) {
      if (earlyRepayment) return earlyRepayment.remainingPayment;
      if (nextPayment && totalNumOfPaymentsLeft === 1) return nextPayment.currentPayment;
    }
    return null;
  }, [earlyRepayment, nextPayment]);

  const isNativeCurrencyPlan = plan.currency.address.toLowerCase() === constants.AddressZero.toLowerCase();

  const handleAutoRepayApproval = async (status: IAutoRepayStatuses) => {
    try {
      if (status === IAutoRepayStatuses.FromMainWallet) {
        const wethAddress = nonNativeSupportedCurrenciesData[chainId][SupportedCurrencies.WETH].address;
        setIsProcessing(true);
        await giveErc20Approval(
          {
            currencyAddress: isNativeCurrencyPlan ? wethAddress : plan.currency.address,
            amount: totalLeftAmount,
          },
          plan.paymentPlan.address,
        );
        await checkAutoRepayApproval();
        setIsProcessing(false);
        return;
      }
    } catch (e) {
      setIsProcessing(false);
    }
  };

  const handleAutoRepay = async (status: IAutoRepayStatuses) => {
    if (!chainId || !signer || status == autoRepaymentStatus) return;
    const previousStatus = autoRepaymentStatus;
    const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
    setIsProcessing(true);
    try {
      setAutoRepaymentStatus({ result: status });
      await handleAutoRepayApproval(status);
      const paymentContractWriter = f.PaymentPlanV2Factory.connect(paymentPlanV2, signer);
      const tx = await paymentContractWriter.setAutoRepayStatus(plan.planId, status);
      await tx.wait();
      await checkAutoRepayStatusFromBlockchain();
      setIsProcessing(false);
    } catch (err) {
      captureException(err);
      setAutoRepaymentStatus({ result: previousStatus });
      setIsProcessing(false);
      console.error(err);
    }
  };

  const openEarlyUnwindModal = ({
    payerWallet,
    offer,
    amountToComplete,
    nextPaymentDate,
  }: {
    payerWallet?: IPayerWallet;
    offer: IOffer;
    amountToComplete: BigNumber;
    nextPaymentDate: Date;
  }) => {
    setModalContent({
      title: `Early Unwind`,
      content: (
        <PlanEarlyUnwindProgress
          plan={{
            ...plan,
            isBnpl,
            address: plan.metadata.collectionAddress,
            collectionName: plan.metadata.collection.name,
            totalAmount: totalLoanAmount,
            purchasedPrice: bnplOriginalPrice,
            leftAmount: totalLeftAmount,
            imageUrl: plan.metadata.imageUrl,
          }}
          offer={offer}
          currency={plan.currency}
          payment={{
            amount: amountToComplete,
            dueDate: nextPaymentDate,
          }}
          onClose={onClose}
          bnpl={bnpl}
          pawn={pawn}
          payerWallet={payerWallet}
        />
      ),
      onClose,
    });
  };
  const checkAccountBalanceAndProceedEarlyUnwind = async (isUserPaying: boolean) => {
    if (!chainId || !signer || !amountToComplete || !planTopBid || !nextPaymentDate) return;
    if (nextPaymentDate && nextPaymentDate.getTime() < Date.now()) {
      setDefaultedError(`This plan has defaulted, and will not accept payments.`);
      return;
    }
    const now = new Date().getTime() / 1000;
    if (planTopBid.expireAt < now) {
      setDefaultedError(`The offer is expired, get new offer by refreshing the modal.`);
      return;
    }
    if (isUserPaying) {
      setModalContent({
        content: (
          <WalletOptionEarlyUnwind
            plan={plan}
            currency={plan.currency}
            totalAmount={totalLoanAmount}
            purchasedPrice={bnplOriginalPrice}
            onNext={({ payerWallet }: { payerWallet: IPayerWallet }) =>
              openEarlyUnwindModal({ payerWallet, offer: planTopBid, amountToComplete, nextPaymentDate })
            }
            isBnpl={isBnpl}
            buyOffer={planTopBid}
            amountToComplete={amountToComplete}
          />
        ),
        title: "Select wallet to pay",
      });
    } else {
      openEarlyUnwindModal({ offer: planTopBid, amountToComplete, nextPaymentDate });
    }
  };
  const isAutoLiquidationEnabled = useMemo(() => {
    return (
      experiment.result &&
      experiment.result[autoLiquidationExperimentFlag(chainId)] &&
      experiment.result[Experiments.AUTO_LIQUIDATION_VAULT]
    );
  }, [experiment, chainId]);

  const hasPendingTxn = transactions.some(txn => txn.data?.planId === plan.planId);
  const _isProcessing = isProcessing || hasPendingTxn;

  const paymentsWithSplittedRevival = useMemo(() => {
    return orderBy(plan.payments, "createdAt", "asc").reduce<IPaymentsMapped[]>((acc, cur, index) => {
      const hasRevivalFee =
        plan.planType === "BNPL" && index === 0 ? false : BigNumber.from(cur.amount).gt(cur.expectedAmount);
      if (hasRevivalFee) {
        return [
          ...acc,
          {
            ...cur,
            amount: BigNumber.from(cur.amount).sub(cur.expectedAmount).toString(),
            isRevival: true,
          },
          {
            ...cur,
            amount: cur.expectedAmount.toString(),
            isRevival: false,
          },
        ];
      }
      return [...acc, { ...cur, isRevival: false }];
    }, []);
  }, [plan.payments, plan.monthlyAmount]);

  if (loading || nextPaymentLoading || earlyRepaymentLoading) {
    return (
      <PlanPaymentLoading
        planType={planType}
        plan={plan}
        totalLoanAmount={totalLoanAmount}
        totalLeftAmount={totalLeftAmount}
        bnplOriginalPrice={bnplOriginalPrice}
      />
    );
  }

  return (
    <Flex gap="12px" direction="column">
      <PlanMetadata
        plans={[
          {
            imageUrl: plan.metadata.imageUrl,
            collectionName: plan.metadata.collection.name,
            tokenId: plan.tokenId,
            totalAmount: totalLoanAmount,
            leftAmount: totalLeftAmount,
            currency: plan.currency,
            address: plan.metadata.collectionAddress,
            purchasedPrice: bnplOriginalPrice,
            isBnpl: planType === "bnpl",
          },
        ]}
      />
      {!defaultedError && error && (
        <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />
      )}
      {!defaultedError && warning && (
        <SystemMessage variant="warning" title={warning.title} msg={warning.msg} description={warning.description} />
      )}
      {isAutoLiquidationEnabled && plan.priceAtAutoLiquidation && (
        <>
          <AutoLiquidationStatusBox>
            <Flex justifyContent="space-between">
              <Flex gap="2px">
                <Text>Auto-liquidation price</Text>
                <Tooltip>
                  <HelpCircle height={16} width={16} color={theme.colors.primary} />
                  <TooltipText showArrow position="top" top="-55px" right="-72px" style={{ width: "150px" }}>
                    <Flex direction="column" gap="7px">
                      <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                        <div>{`This represents the price at which your loan will be liquidated.`}</div>
                      </Text>
                    </Flex>
                  </TooltipText>
                </Tooltip>
              </Flex>
              <Text weight="600">
                {bigNumToFixedStr(plan.priceAtAutoLiquidation, 2, plan.currency.decimal)} {plan.currency.symbol}
              </Text>
            </Flex>
          </AutoLiquidationStatusBox>
        </>
      )}
      {isActivePlan && !defaultedError && (
        <>
          <StyledCard hover>
            <Flex direction="column" gap="5px">
              <Text size="sm" weight="600" color="secondary">
                {`Payment options`}
              </Text>
              <SwitchButtonGroup<PaymentOptions>
                activeBackground={theme.colors.cyan}
                activeTextColor={theme.colors.black}
                borderColor={theme.colors.gray10}
                value={paymentOption}
                onChange={v => {
                  if (isProcessing) return;
                  if (v === PaymentOptions.sell) {
                    getTopBid();
                  }
                  setPaymentOption(v);
                }}
                hover
              >
                <SwitchButton height={switch_btn_height} value={PaymentOptions.regularPay}>{`Payment`}</SwitchButton>
                <SwitchButton
                  height={switch_btn_height}
                  value={PaymentOptions.earlyPay}
                  disabled={!earlyRepayment}
                >{`Pay off loan`}</SwitchButton>
                <SwitchButton height={switch_btn_height} value={PaymentOptions.refinance}>{`Refinance`}</SwitchButton>
                <SwitchButton
                  height={switch_btn_height}
                  value={PaymentOptions.sell}
                  disabled={amountToComplete == null || plan.metadata.tokenType === INftType.ERC1155}
                >{`Sell NFT`}</SwitchButton>
              </SwitchButtonGroup>
            </Flex>
          </StyledCard>
          {paymentOption === PaymentOptions.refinance && amountToComplete && (
            <PlanCreationModal
              planType="pawn"
              currency={plan.currency}
              items={[
                {
                  address: plan.metadata.collectionAddress,
                  tokenId: plan.tokenId,
                  itemType: plan.metadata.tokenType ?? INftType.ERC721,
                  imageUrl: plan.metadata.imageUrl,
                  currency: plan.currency,
                  collectionName: plan.metadata.collection.name,
                  amount: ITEM_AMOUNT_BY_NFT_TYPE[plan.metadata.tokenType ?? INftType.ERC721],
                  price: BigNumber.from(0),
                  existingPlan: {
                    planId: plan.planId,
                    amountToComplete: amountToComplete,
                    totalPay: amountToComplete,
                  },
                },
              ]}
              hideHeader={true}
              pricePlanStep1={isAutoLiquidationEnabled ? pricePawnStep1V2 : pricePawnStep1Deprecated}
              pricePlanStep2={isAutoLiquidationEnabled ? pricePawnStep2V2 : pricePawnStep2Deprecated}
              currentStep={PlanCreationSteps.SelectTerm}
            />
          )}
          {paymentOption === PaymentOptions.regularPay && nextPayment && nextPaymentDate && (
            <Flex direction="column" gap="12px">
              <RegularPayment
                plan={{
                  nextPayment,
                  totalNumOfPaymentsLeft,
                  interestRate: _interestRate,
                  monthlyAmount: plan.monthlyAmount,
                  payments: paymentsWithSplittedRevival,
                  createdAt: plan.createdAt,
                  term: plan.term,
                  currency: plan.currency,
                  planType,
                  planData: plan,
                  totalCost,
                  totalNumberOfPayments: plan.totalNumOfPayments,
                }}
              />
              <>
                {totalNumOfPaymentsLeft === 1 && (
                  <WalletSelectorForNFTRelease
                    selectedWallet={releaseWallet}
                    onReleaseWalletChange={(wallet: string) => setReleaseWallet(wallet)}
                    plans={[plan]}
                    isFullPayment={true}
                    onMainWalletStateChange={disabled => {
                      if (disabled) {
                        setWarning({
                          title: `Release Options Limited`,
                          msg: `One or more items you have selected have an existing staking plan. Releasing to your Main Wallet will become available once the staked items have been removed.`,
                        });
                      } else {
                        setWarning(null);
                      }
                    }}
                  />
                )}
                <AutoRepaymentSwitch
                  autoRepayStatus={autoRepaymentStatus}
                  onChange={handleAutoRepay}
                  disabled={isProcessing}
                />
              </>

              {autoRepaymentStatus === IAutoRepayStatuses.FromCyanWallet && (
                <AutoRepaymentWarning autoRepayStatus={autoRepaymentStatus} />
              )}

              {(autoRepaymentStatus === IAutoRepayStatuses.FromMainWallet || isAutoRepayApprovalRequired) && (
                <AutoRepaymentWarningNonNative
                  isNativeCurrency={isNativeCurrencyPlan}
                  currencySymbol={plan.currency.symbol}
                />
              )}

              {isAutoRepayApprovalRequired && (
                <AutoRepaymentApprove
                  isProcessing={_isProcessing}
                  onPay={() => handleAutoRepayApproval(autoRepaymentStatus)}
                />
              )}

              {autoRepaymentStatus != IAutoRepayStatuses.Disabled ? (
                <AutoRepaymentBox
                  isProcessing={_isProcessing}
                  onPay={handleRegularPay}
                  plan={{
                    amount: nextPayment.currentPayment,
                    currency: plan.currency.symbol,
                    dueDate: nextPaymentDate,
                  }}
                />
              ) : (
                <PayBox
                  onPay={handleRegularPay}
                  isProcessing={_isProcessing}
                  plan={{
                    amount: nextPayment.currentPayment,
                    currency: plan.currency.symbol,
                    dueDate: nextPaymentDate,
                  }}
                />
              )}
            </Flex>
          )}
          {paymentOption === PaymentOptions.earlyPay && earlyRepayment && (
            <Flex direction="column" gap="12px">
              <EarlyRepayment
                plan={{
                  earlyPayment: earlyRepayment,
                  originalLoanAmount: principleAmount,
                  payments: paymentsWithSplittedRevival,
                  createdAt: plan.createdAt,
                  term: plan.term,
                  totalNumOfPayments: plan.totalNumOfPayments,
                  currentNumOfPayments: plan.currentNumOfPayments,
                  interestRate: _interestRate,
                  currency: plan.currency,
                  planType,
                  totalCost,
                }}
              />
              <WalletSelectorForNFTRelease
                selectedWallet={releaseWallet}
                onReleaseWalletChange={(wallet: string) => setReleaseWallet(wallet)}
                plans={[plan]}
                isFullPayment={true}
                onMainWalletStateChange={disabled => {
                  if (disabled) {
                    setWarning({
                      title: `Release Options Limited`,
                      msg: `One or more items you have selected have an existing staking plan. Releasing to your Main Wallet will become available once the staked items have been removed.`,
                    });
                  } else {
                    setWarning(null);
                  }
                }}
              />

              <PayBox
                onPay={handleEarlyPay}
                isProcessing={_isProcessing}
                plan={{
                  amount: earlyRepayment.remainingPayment,
                  currency: plan.currency.symbol,
                  dueDate: earlyRepayment.dueDate,
                }}
              />
            </Flex>
          )}
          {paymentOption === PaymentOptions.sell &&
            amountToComplete &&
            (planTopBidLoading || !planTopBid ? (
              <SellNftDefault
                plan={{
                  payments: paymentsWithSplittedRevival,
                  currency: plan.currency,
                  interestRate: _interestRate,
                  amountToComplete: amountToComplete,
                }}
                isOfferLoading={planTopBidLoading}
              />
            ) : (
              <SellNft
                plan={{
                  payments: paymentsWithSplittedRevival,
                  currency: plan.currency,
                  interestRate: _interestRate,
                  term: plan.term,
                  totalNumberOfPayments: plan.totalNumOfPayments,
                  amountToComplete: amountToComplete,
                  buyOffer: planTopBid,
                  isBnpl: planType === "bnpl",
                }}
                isProcessing={_isProcessing}
                isPlanDueDateClose={isPlanDueDateClose}
                onSell={checkAccountBalanceAndProceedEarlyUnwind}
              />
            ))}
        </>
      )}
      {(isDefaultedPlan || defaultedError) && (
        <>
          <PositionDefaulted />
          <DefaultedPlanMetadata
            plan={{
              totalNumOfPaymentsLeft,
              totalNumOfPayments: plan.totalNumOfPayments,
              defaultedAt: plan.defaultedAt || new Date(),
            }}
          />
        </>
      )}
    </Flex>
  );
};

const StyledCard = styled(Card)`
  padding: 8px;
`;

const AutoLiquidationStatusBox = styled(Box)`
  background-color: ${({ theme }) => theme.colors.yellow};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: 8px;
`;
