import dayjs from "dayjs";
import { BigNumber, ethers } from "ethers";
import { useEffect, useState } from "react";
import styled from "styled-components";

import { CyanWallet } from "@usecyan/cyan-wallet";
import { useCyanWalletContext } from "@usecyan/cyan-wallet/hooks";

import { Box, Button, Flex, Stepper } from "@cyanco/components/theme";
import { Text, useModal } from "@cyanco/components/theme/v3";
import { factories as f } from "@cyanco/contract";
import { factories as factoryDeprecated } from "@cyanco/contract-old";

import { getPlanRevivalSignatureBulk } from "@/apis/revival";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { getPaymentPlanFromChainId } from "@/constants/contracts";
import { useApproval } from "@/hooks/useApproval";
import { ITransaction } from "@/hooks/useTransactions";
import { ICurrency } from "@/types";
import { getChainExplorerURL } from "@/utils";
import { getAccountBalanceOfErc20, getNftTransferFnDataForCyanWallet } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";
import { getPaymentPlanUpgradeExperiment } from "@/utils/experimentList";

import { PlanMetadata } from "../PlanMetadata";
import { BulkRevivalModal, IRevivablePlan } from "./BulkRevivalModal";

type IBulkRepaymentProgress = {
  plans: Array<IRevivablePlan>;
  totalRevivalAmountByCurrency: {
    [key: string]: {
      totalCost: BigNumber;
      currency: ICurrency;
      formatNumber: number;
      isNativeCurrency: boolean;
      totalPenalty: BigNumber;
    };
  };
  removePlan: (plan: { address: string; tokenId: string }) => void;
  onClose: () => void;
  selectedWalletAddress: string;
};

export const BulkRevivalProgressModal = ({
  plans,
  totalRevivalAmountByCurrency,
  removePlan,
  onClose,
  selectedWalletAddress,
}: IBulkRepaymentProgress) => {
  const { setModalContent, unsetModal } = useModal();
  const { experiment } = useAppContext();
  const { chainId, account, provider, signer } = useWeb3React();
  const { cyanWallet } = useCyanWalletContext();
  const { transactions, setTransactions } = useTransactionContext();
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const { getErc20ApprovalFunctionData } = useApproval();
  const [selectedStep, setSelectedStep] = useState<PlanRepaymentSteps>(PlanRepaymentSteps.TokenTransfer);
  const paymentPlanV2 = getPaymentPlanFromChainId(chainId);
  const currencyValues = Object.values(totalRevivalAmountByCurrency);
  const withdrawFromCyanWallet =
    selectedWalletAddress.toLowerCase() === (cyanWallet?.walletAddress ?? "").toLowerCase();
  const isTokenTransferRequired = !withdrawFromCyanWallet && currencyValues.some(item => !item.isNativeCurrency);

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setSelectedStep(PlanRepaymentSteps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);
  useEffect(() => {
    const onStepChange = async (step: PlanRepaymentSteps) => {
      if (!chainId || !account || !signer) return;
      switch (step) {
        case PlanRepaymentSteps.TokenTransfer: {
          const hasEnoughBalance = await checkBalance();
          if (isTokenTransferRequired && hasEnoughBalance) {
            await checkAndTransferErc20();
          }
          setSelectedStep(PlanRepaymentSteps.Reviving);
          return;
        }
        case PlanRepaymentSteps.Reviving: {
          await handleRevival();
        }
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);
  const checkAndTransferErc20 = async () => {
    if (!signer || !cyanWallet) return;
    for (const { currency, totalCost, totalPenalty, isNativeCurrency } of currencyValues) {
      try {
        if (!isNativeCurrency) {
          const sampleErc20 = f.SampleERC20TokenFactory.connect(currency.address, signer);
          const txn = await sampleErc20.transfer(cyanWallet.walletAddress, totalCost.add(totalPenalty));
          await txn.wait();
        }
      } catch (err) {
        openRepaymentModal(err);
        return;
      }
    }
  };
  const checkBalance = async () => {
    if (!chainId || !account || !provider) return;
    for (const { currency, totalCost, totalPenalty } of currencyValues) {
      const { mainWalletBalance, cyanWalletBalance } = await getAccountBalanceOfErc20({
        currencyAddress: currency.address,
        provider,
        mainWallet: account,
        chainId,
      });
      const totalPayAmount = totalCost.add(totalPenalty);
      // checking mainWalletBalance, if it is enough, will be transfered to cyan wallet in next step
      let hasEnoughBalance = mainWalletBalance.gte(totalPayAmount);
      if (currencyValues.length === 1) {
        hasEnoughBalance = withdrawFromCyanWallet
          ? cyanWalletBalance.gte(totalPayAmount)
          : mainWalletBalance.gte(totalPayAmount);
      }
      if (!hasEnoughBalance)
        openRepaymentModal({
          title: `Not enough funds`,
          msg: `Your payment didn’t go through due to lack of funds`,
          description: ` Please double check the amount of crypto in your main wallet. In order to facilitate payments for non-native currency plans, it is imperative that the payment amount be drawn from the Cyan wallet.`,
        });
    }
    return true;
  };
  const createApproveTxn = async (
    signer: ethers.Signer,
    cyanWallet: CyanWallet,
    item: {
      currency: ICurrency;
      requiredAmount: BigNumber;
    },
  ) => {
    return await getErc20ApprovalFunctionData(
      {
        amount: item.requiredAmount,
        currencyAddress: item.currency.address,
        cyanWallet,
      },
      paymentPlanV2,
    );
  };
  const createRevivalTxn = (
    plan: IRevivablePlan,
    signature: string,
    expiryDateInSeconds: number,
    penaltyAmount: BigNumber,
  ) => {
    if (!plan.nextPayment) return;
    const paymentContractWriter = f.PaymentPlanV2Factory.createInterface();
    const paymentContractWriterDeprecated = factoryDeprecated.PaymentPlanV2Factory.createInterface();

    const isPaymentPlanUpdated = experiment.result && experiment.result[getPaymentPlanUpgradeExperiment(chainId)];
    const encodedPayFnData = isPaymentPlanUpdated
      ? paymentContractWriter.encodeFunctionData("revive", [
          plan.planId,
          {
            signature,
            signatureExpiryDate: expiryDateInSeconds,
            penaltyAmount,
          },
        ])
      : paymentContractWriterDeprecated.encodeFunctionData("revive", [
          plan.planId,
          penaltyAmount,
          expiryDateInSeconds,
          signature,
        ]);
    const encodedPayFnDataFormatted = [
      paymentPlanV2,
      plan.nextPayment.isNativeCurrency ? plan.nextPayment.currentPayment.add(plan.nextPayment.penaltyAmount) : 0,
      encodedPayFnData,
    ];
    return encodedPayFnDataFormatted;
  };
  const createBulkTransactions = async (signer: ethers.Signer, cyanWallet: CyanWallet) => {
    const bulkTransactions = [];
    const plansWithTxns = [];
    for (const { currency, totalCost, isNativeCurrency, totalPenalty } of currencyValues) {
      if (!isNativeCurrency) {
        const txn = await createApproveTxn(signer, cyanWallet, {
          currency,
          requiredAmount: totalCost.add(totalPenalty),
        });
        if (txn) bulkTransactions.push(txn);
      }
    }
    const signatures = await getPlanRevivalSignatureBulk(
      plans.map(plan => ({ planId: plan.planId })),
      chainId,
    );
    for (const plan of plans) {
      const planSignature = signatures.find(elm => elm.planId === plan.planId);
      if (!planSignature) {
        openRepaymentModal({
          title: `Couldn't produce revival signature`,
          msg: `Couldn't produce revival signature, plan is may not be revivable. Please contact Cyan team via discord.`,
        });
        return;
      }
      const { signature, expiryDateInSeconds, penaltyAmount } = planSignature;
      if (!plan.nextPayment.penaltyAmount.eq(penaltyAmount)) {
        openRepaymentModal({
          title: `Penalty rate changed`,
          msg: `Please check the penalty amount before proceeding with the plan revival.`,
        });
        return;
      }
      if (dayjs.unix(expiryDateInSeconds).isBefore(dayjs())) {
        openRepaymentModal({
          title: `Revival Signature is expired`,
          msg: `Revival Signature is expired, Please refresh`,
        });
        return;
      }
      const revivalTxn = createRevivalTxn(plan, signature, expiryDateInSeconds, penaltyAmount);
      if (revivalTxn) {
        bulkTransactions.push(revivalTxn);
        plansWithTxns.push(plan);
      }
      if (
        plan.totalNumOfPaymentsLeft === 1 &&
        cyanWallet.walletAddress.toLowerCase() !== selectedWalletAddress.toLowerCase()
      ) {
        const transferTxn = getNftTransferFnDataForCyanWallet({
          tokenType: plan.metadata.tokenType,
          to: selectedWalletAddress,
          from: cyanWallet.walletAddress,
          tokenId: plan.tokenId,
          collectionAddress: plan.metadata.collectionAddress,
        });
        bulkTransactions.push(transferTxn);
      }
    }
    return { bulkTransactions, plansWithTxns };
  };
  const handleRevival = async () => {
    try {
      if (!chainId || !account || !signer || !cyanWallet) return;
      const txns = await createBulkTransactions(signer, cyanWallet);
      const bulkTransactions = txns?.bulkTransactions;
      const plansWithTxns = txns?.plansWithTxns;
      if (!bulkTransactions || bulkTransactions.length === 0 || !plansWithTxns) return;
      const encodedCyanExecuteFnData = cyanWallet.interface.encodeFunctionData("executeBatch", [bulkTransactions]);
      let tx: ethers.ContractTransaction;
      if (currencyValues.length === 1) {
        if (withdrawFromCyanWallet) {
          tx = await signer.sendTransaction({
            to: cyanWallet.walletAddress,
            value: 0,
            data: encodedCyanExecuteFnData,
          });
        } else {
          tx = await signer.sendTransaction({
            to: cyanWallet.walletAddress,
            value: currencyValues[0].isNativeCurrency
              ? currencyValues[0].totalCost.add(currencyValues[0].totalPenalty)
              : "0",
            data: encodedCyanExecuteFnData,
          });
        }
      } else {
        tx = await signer.sendTransaction({
          to: cyanWallet.walletAddress,
          value:
            totalRevivalAmountByCurrency[ethers.constants.AddressZero.toLowerCase()]?.totalCost.add(
              currencyValues[0].totalPenalty,
            ) || 0,
          data: encodedCyanExecuteFnData,
        });
      }
      const newTxns: ITransaction[] = plansWithTxns.map(paymentPlan => ({
        hash: tx.hash,
        type: paymentPlan.planType === "BNPL" ? "bnpl-pay" : "pawn-pay",
        expiresAt: Date.now() + 1000 * 60 * 5, // 5 minutes
        data: {
          planId: paymentPlan.planId,
          tokenId: paymentPlan.tokenId,
          currentNumOfPayments: paymentPlan.currentNumOfPayments,
          contractAddress: paymentPlan.metadata.collectionAddress,
        },
      }));
      setTransactions(oldTxns => [...oldTxns, ...newTxns]);
      setActiveTx(tx.hash);
    } catch (err: any) {
      openRepaymentModal(err);
    }
  };
  const openRepaymentModal = (err: any) => {
    const mappedError = mapAndLogError(err);
    setModalContent({
      title: `Loan Revival`,
      content: <BulkRevivalModal plans={plans} error={mappedError} onClose={onClose} removePlan={removePlan} />,
    });
  };
  return (
    <Flex gap="5x" direction="column">
      <PlanMetadata
        plans={plans.map(plan => ({
          tokenId: plan.tokenId,
          collectionName: plan.metadata.collection.name,
          currency: plan.currency,
          imageUrl: plan.metadata.imageUrl,
          address: plan.metadata.collectionAddress,
          leftAmount: BigNumber.from(0),
          totalAmount: BigNumber.from(0),
          isBnpl: plan.planType === "BNPL",
        }))}
      />
      <Box pb="2rem" pt="1rem">
        <Stepper
          marks={PlanRepaymentStepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === PlanRepaymentSteps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};

enum PlanRepaymentSteps {
  TokenTransfer = 2,
  Reviving = 3,
  Done = 4,
}

const PlanRepaymentStepMarks = [
  {
    value: PlanRepaymentSteps.TokenTransfer,
    title: `Token Transfer`,
    description: `Transfer required amount of tokens to the Cyan Wallet`,
  },
  {
    value: PlanRepaymentSteps.Reviving,
    title: `Processing Revival`,
    description: `View on Etherscan`,
  },
];

const StyledConfirmButton = styled(Button)`
  padding: 1rem 0;
`;
