import { BigNumber } from "ethers";
import { useContext, useMemo } from "react";
import styled from "styled-components";

import { getMarketPlaceLogo } from "@usecyan/shared/utils/marketplaces";
import { SupportedMarketPlaces } from "@usecyan/shared/utils/marketplaces.type";

import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Carousel, NftCardLoading } from "@cyanco/components/theme/v3";

import { AppContext } from "@/components/AppContextProvider";
import { useBargainCheapestNfts } from "@/components/BargainShop/BargainDataContext";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_INFO, CYAN_SUPPORTED_CHAIN_IDS } from "@/constants/chains";
import { usePlanCreation } from "@/hooks";
import { INftBe, INftType } from "@/types";
import { getSampleCollections } from "@/utils";

import { OnSaleNftCard } from "./OnSaleNftCard";

export const CheapestNfts = () => {
  const { chainId } = useWeb3React();
  const { collections, collectionsLoading } = useContext(AppContext);
  const { cheapestNfts: _bargainCheapest, cheapestNftsLoading: bargainCheapestLoaindg } = useBargainCheapestNfts();
  const { showNewPlanModal } = usePlanCreation("bnpl");

  const cheapestNfts = useMemo(() => {
    const featuredCollections = getSampleCollections(chainId);
    const reservoirApiUrl =
      CHAIN_INFO?.[chainId as typeof CYAN_SUPPORTED_CHAIN_IDS[number]]?.reservoirApiUrl ||
      "https://api.reservoir.tools";
    const _nfts = featuredCollections.reduce<INftBe[]>((acc, cur) => {
      const _featuredCollection = collections.find(c => c.address.toLowerCase() === cur.toLowerCase());
      if (!_featuredCollection) return acc;
      const { floorAsk, address, tokenType, name } = _featuredCollection;
      if (!floorAsk.price || !floorAsk.token) return acc;
      const item: INftBe = {
        address: address,
        itemType: tokenType,
        collectionName: name,
        imageUrl: `${reservoirApiUrl}/redirect/tokens/${address}:${floorAsk.token.tokenId}/image/v1?imageSize=small`,
        tokenId: floorAsk.token.tokenId,
        marketName: floorAsk.sourceName,
        currency: {
          address: floorAsk.price.currency.contract,
          decimal: floorAsk.price.currency.decimals,
          symbol: floorAsk.price.currency.symbol,
        },
        marketIcon: getMarketPlaceLogo(floorAsk.sourceDomain),
        price: BigNumber.from(floorAsk.price.amount.raw),
        supply: 1,
        amount: 1,
        remainingSupply: 1,
        rarityRank: 0,
        isFlaggedByOS: false,
        listedAt: new Date(),
        lastSellPrice: null,
        topBidPrice: null,
        isPossibleToBuy: true,
      };
      return [...acc, item];
    }, []);
    return _nfts;
  }, [chainId, collections]);

  const bargainCheapest = useMemo(() => {
    return _bargainCheapest?.map(nft => {
      if (!nft.privateSale) return nft;
      return {
        ...nft,
        address: nft.privateSale?.collectionAddress,
        price: BigNumber.from(nft.privateSale?.price),
        itemType: nft.privateSale?.tokenType,
        amount: nft.privateSale?.tokenAmount,
        marketName: SupportedMarketPlaces.CYANPRIVATESALE,
        privateSaleId: nft.privateSale?.id,
      };
    });
  }, [_bargainCheapest]);

  return (
    <Container>
      <div style={{ position: "relative" }}>
        {bargainCheapestLoaindg && collectionsLoading ? (
          <Carousel>
            {Array.from(Array(3).keys()).map(loader => (
              <div
                key={loader}
                style={{
                  width: "95%",
                }}
              >
                <NftCardLoading actionText={`Buy Now, Pay Later`} />
              </div>
            ))}
          </Carousel>
        ) : (
          (bargainCheapest || cheapestNfts) && (
            <Carousel autoScroll>
              {(bargainCheapest || []).map(nft => (
                <div
                  key={nft.tokenId}
                  style={{
                    width: "95%",
                  }}
                >
                  <OnSaleNftCard
                    nft={nft}
                    key={nft.tokenId}
                    onClick={() =>
                      showNewPlanModal({
                        currency: nft.currency,
                        items: [
                          {
                            ...nft,
                            amount: 0,
                            itemType: INftType.ERC721,
                            price: BigNumber.from(nft.price),
                          },
                        ],
                      })
                    }
                    bargainListing
                    collectionFloorPrice={
                      collections.find(c => c.address.toLowerCase() === nft.address.toLowerCase())?.floorAsk
                    }
                    hideCart
                  />
                </div>
              ))}
              {(cheapestNfts || []).map(nft => (
                <div
                  key={nft.tokenId}
                  style={{
                    width: "95%",
                  }}
                >
                  <OnSaleNftCard
                    nft={nft}
                    key={nft.tokenId}
                    onClick={() =>
                      showNewPlanModal({
                        currency: nft.currency,
                        items: [
                          {
                            ...nft,
                            amount: 0,
                            itemType: INftType.ERC721,
                            price: BigNumber.from(nft.price),
                          },
                        ],
                      })
                    }
                    collectionFloorPrice={
                      collections.find(c => c.address.toLowerCase() === nft.address.toLowerCase())?.floorAsk
                    }
                    hideCart
                  />
                </div>
              ))}
            </Carousel>
          )
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 380px;
  z-index: 20;
  ${getStyleWithMediaQuery("width", "px", [
    { [breakpoints.desktop]: 380 },
    { [breakpoints.laptop]: 340 },
    { [breakpoints.mobile]: 340 },
  ])};
`;
