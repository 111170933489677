import { BigNumber } from "ethers";
import { useMemo } from "react";

import { Flex, SystemMessage } from "@cyanco/components/theme";

import {
  pricePawnStep1Deprecated,
  pricePawnStep1 as pricePawnStep1V2,
  pricePawnStep2Deprecated,
  pricePawnStep2 as pricePawnStep2V2,
} from "@/apis/pricer/index-v2";
import { IPawn } from "@/components/Account/pawn.types";
import { useAppContext } from "@/components/AppContextProvider";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { autoLiquidationExperimentFlag } from "@/components/PlanCreation/utils";
import { PlanCreationModal, PlanCreationSteps } from "@/components/PlanCreation/v2/PlanCreationModal";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { IPlanType } from "@/constants/plans";
import { usePlanRevival } from "@/hooks/usePlanRevival";
import { INftType, ITEM_AMOUNT_BY_NFT_TYPE } from "@/types";
import { IMappedError } from "@/utils/error/msgs";
import { Experiments } from "@/utils/experimentList";

import { DefaultedPlanMetadata, PlanMetadata } from "../../PlanMetadata";
import { PositionDefaulted } from "../../PlanStatuses";
import { RevivalPaymentLoading } from "../RevivalPaymentLoading";

export const RevivalWithRefinance = ({
  plan,
  planType,
  error: _error,
}: {
  plan: IPawn | IBNPL;
  planType: IPlanType;
  error?: IMappedError;
}) => {
  const { planData, error, revivalInfo, defaultedError, amountToComplete, isLoading } = usePlanRevival({
    plan,
    planType,
  });
  const { experiment } = useAppContext();
  const { chainId } = useWeb3React();

  const isAutoLiquidationEnabled = useMemo(() => {
    return (
      experiment.result &&
      experiment.result[autoLiquidationExperimentFlag(chainId)] &&
      experiment.result[Experiments.AUTO_LIQUIDATION_VAULT]
    );
  }, [experiment, chainId]);

  if (isLoading) {
    return (
      <RevivalPaymentLoading
        planType={planData.isBnpl ? "bnpl" : "pawn"}
        plan={plan}
        totalLoanAmount={planData.totalLoanAmount}
        totalLeftAmount={planData.totalLeftAmount}
        bnplOriginalPrice={planData.bnplOriginalPrice}
        totalNumOfPaymentsLeft={planData.totalNumOfPaymentsLeft}
      />
    );
  }

  if (!planData.isRevivalPossible || defaultedError || error) {
    return (
      <Flex gap="18px" direction="column">
        <PlanMetadata
          plans={[
            {
              imageUrl: plan.metadata.imageUrl,
              collectionName: plan.metadata.collection.name,
              tokenId: plan.tokenId,
              totalAmount: planData.totalLoanAmount,
              leftAmount: planData.totalLeftAmount,
              currency: plan.currency,
              address: plan.metadata.collectionAddress,
              purchasedPrice: planData.bnplOriginalPrice,
              isBnpl: planData.isBnpl,
            },
          ]}
        />
        <PositionDefaulted />
        {error && <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />}
        <DefaultedPlanMetadata
          plan={{
            totalNumOfPaymentsLeft: planData.totalNumOfPaymentsLeft,
            totalNumOfPayments: plan.totalNumOfPayments,
            defaultedAt: plan.defaultedAt || new Date(),
          }}
        />
      </Flex>
    );
  }

  return (
    <Flex gap="18px" direction="column">
      <PlanMetadata
        plans={[
          {
            imageUrl: plan.metadata.imageUrl,
            collectionName: plan.metadata.collection.name,
            tokenId: plan.tokenId,
            totalAmount: planData.totalLoanAmount,
            leftAmount: planData.totalLeftAmount,
            currency: plan.currency,
            address: plan.metadata.collectionAddress,
            purchasedPrice: planData.bnplOriginalPrice,
            isBnpl: planData.isBnpl,
          },
        ]}
      />
      {_error && (
        <SystemMessage variant="error" title={_error.title} msg={_error.msg} description={_error.description} />
      )}
      {amountToComplete && !defaultedError && !error && (
        <PlanCreationModal
          planType="pawn"
          currency={plan.currency}
          items={[
            {
              address: plan.metadata.collectionAddress,
              tokenId: plan.tokenId,
              itemType: plan.metadata.tokenType ?? INftType.ERC721,
              imageUrl: plan.metadata.imageUrl,
              currency: plan.currency,
              collectionName: plan.metadata.collection.name,
              amount: ITEM_AMOUNT_BY_NFT_TYPE[plan.metadata.tokenType ?? INftType.ERC721],
              price: BigNumber.from(0),
              existingPlan: {
                planId: plan.planId,
                amountToComplete: amountToComplete.currentPaymentAmount,
                revivalFee: BigNumber.from(revivalInfo?.[0] ? revivalInfo[0].penaltyAmount : planData.penaltyAmount),
                totalPay: amountToComplete.currentPaymentAmount.add(
                  revivalInfo?.[0] ? revivalInfo[0].penaltyAmount : planData.penaltyAmount,
                ),
              },
            },
          ]}
          hideHeader={true}
          pricePlanStep1={isAutoLiquidationEnabled ? pricePawnStep1V2 : pricePawnStep1Deprecated}
          pricePlanStep2={isAutoLiquidationEnabled ? pricePawnStep2V2 : pricePawnStep2Deprecated}
          currentStep={PlanCreationSteps.SelectTerm}
        />
      )}
    </Flex>
  );
};
