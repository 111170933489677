import { isAxiosError } from "@/apis/axios";

import { IMappedError, REQUEST_MORE_INFO } from "./msgs";

enum IBeErrors {
  NOT_SUPPORTED_COLLECTION = "Collection :collection is not supported yet.",
  NOT_SUPPORTED_CURRENCY = "Currency :currency is not supported yet.",
  NOT_SUPPORTED_CHAIN = "Chain :chain is not supported yet.",
  NOT_IMPLEMENTED_FEATURE = ":feature is not implemented yet.",
  BANNED_PERMANENTLY = "Due to a previous defaulted loan, you are permanently restricted from creating another loan or making a purchase.",
  BANNED_TEMPORARILY = "Due to a previous defaulted loan, you are temporarily restricted from creating another loan or purchase until :banExpirationDate.",
  NO_BUY_NOW_PRICE = "Item has no Buy Now price.",
  PRIVATE_SALE_EXPIRED = "The sale listing cannot be located or is not set to your wallet as the buyer",
}

export const pricerPossibleErrors = {
  PriceChanged: `The price of your item has changed, and has been updated. Please confirm the pricing before starting your plan.`,
  AppraisalChanged: `The appraisal value of your NFT has changed due to market circumstances. Please verify the appraisal value before committing to a loan. Thank you for your understanding.`,
  DifferentMarketplace: `Item's cheapest price is in different marketplace. Please confirm before starting your plan.`,
};

const errorPatterns: { [key in IBeErrors]: RegExp } = {
  [IBeErrors.NOT_SUPPORTED_COLLECTION]: /^Collection .+ is not supported yet\.$/,
  [IBeErrors.NOT_SUPPORTED_CURRENCY]: /^Currency .+ is not supported yet\.$/,
  [IBeErrors.NOT_SUPPORTED_CHAIN]: /^Chain .+ is not supported yet\.$/,
  [IBeErrors.NOT_IMPLEMENTED_FEATURE]: /^.+ is not implemented yet\.$/,
  [IBeErrors.BANNED_PERMANENTLY]:
    /^Due to a previous defaulted loan, you are permanently restricted from creating another loan or making a purchase\.$/,
  [IBeErrors.BANNED_TEMPORARILY]:
    /^Due to a previous defaulted loan, you are temporarily restricted from creating another loan or purchase until .+\.$/,
  [IBeErrors.NO_BUY_NOW_PRICE]: /Item has no Buy Now price/,
  [IBeErrors.PRIVATE_SALE_EXPIRED]: /The sale listing cannot be located or is not set to your wallet as the buyer/,
};

const getBeErrorType = (errorMessage: string): IBeErrors | undefined => {
  for (const [errorType, pattern] of Object.entries(errorPatterns)) {
    if (pattern.test(errorMessage)) {
      return errorType as IBeErrors;
    }
  }
  return undefined;
};

export const mapBeError = (error: any): IMappedError | undefined => {
  const errorMsg = error?.response?.data?.message || error?.response?.data || error?.message;
  if (!errorMsg) return;
  const errorType = getBeErrorType(errorMsg);
  if (errorType) {
    switch (errorType) {
      case IBeErrors.NOT_SUPPORTED_COLLECTION:
        return {
          title: "Collection is not supported!",
          msg: errorMsg,
          description: REQUEST_MORE_INFO,
        };
      case IBeErrors.BANNED_TEMPORARILY:
      case IBeErrors.BANNED_PERMANENTLY:
        return {
          title: "Banned!",
          msg: errorMsg,
          description: REQUEST_MORE_INFO,
        };
      case IBeErrors.NOT_SUPPORTED_CHAIN:
        return {
          title: "Chain is not supported!",
          msg: "Please try to switch to Cyan supported chains.",
          description: REQUEST_MORE_INFO,
        };
      case IBeErrors.NOT_IMPLEMENTED_FEATURE:
        return {
          title: "Feature is not implemented!",
          msg: errorMsg,
          description: REQUEST_MORE_INFO,
        };
      case IBeErrors.NO_BUY_NOW_PRICE:
        return {
          title: "Item has no Buy Now price",
          msg: "Some of items has no listing. Please refresh the page and try again",
          description: REQUEST_MORE_INFO,
        };
      case IBeErrors.PRIVATE_SALE_EXPIRED:
        return {
          title: "Private Sale cannot be sold",
          msg: " It is possible the seller either canceled the listing or has set another buyer's address. Please contact the seller for any errors.",
          description: REQUEST_MORE_INFO,
        };
      default:
        return { title: "Error Occured", msg: errorMsg, description: REQUEST_MORE_INFO };
    }
  }
  if (isAxiosError(error)) {
    return {
      title: "Error Occured",
      msg: errorMsg,
      description: REQUEST_MORE_INFO,
    };
  }
};
