import { BigNumber } from "ethers";

import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";
import { Errored, INft, isNonErrored } from "@/types";
import { Override } from "@/utils/types";

import { axios } from "../axios";

export const pricePawnStep2 = async (
  args: IPawnPricerStep2Params,
  isCollectionLtvEnabled: boolean,
): Promise<IResult> => {
  const data = serializeRequestData(args);
  const response = await axios.post<IResponse>(
    isCollectionLtvEnabled ? "/v2/pricer/pawn-step2" : "/v2/pricer/pawn-step2-v2",
    data,
  );
  return serializeResponseData(response.data);
};

export const pricePawnStep2Deprecated = async (
  args: IPawnPricerStep2Params,
  isCollectionLtvEnabled: boolean,
): Promise<IResult> => {
  const data = serializeRequestDataDeprecated(args);
  const response = await axios.post<IResponse>(
    isCollectionLtvEnabled ? "/v2/pricer/pawn-step2" : "/v2/pricer/pawn-step2-v2",
    data,
  );
  return serializeResponseData(response.data);
};

const serializeRequestData = (data: IPawnPricerStep2Params): IRequestData => {
  const chain = CHAIN_IDS_TO_NAMES[data.chainId];
  return {
    chain,
    items: data.items.map(item => ({
      address: item.address,
      tokenId: item.tokenId,
      itemType: item.itemType,
      amount: item.amount,
      existingPlanId: item?.existingPlanId,
      option: [item.term, item.totalNumberOfPayments, item.loanRate],
      isAutoLiquidated: item.isAutoLiquidated,
    })),
    currencyAddress: data.currencyAddress,
    autoRepayStatus: data.autoRepayStatus,
    wallet: data.wallet,
  };
};

const serializeRequestDataDeprecated = (data: IPawnPricerStep2Params): IRequestDataDeprecated => {
  const chain = CHAIN_IDS_TO_NAMES[data.chainId];
  return {
    chain,
    items: data.items.map(item => ({
      address: item.address,
      tokenId: item.tokenId,
      itemType: item.itemType,
      amount: item.amount,
      existingPlanId: item?.existingPlan?.planId,
      option: [item.term, item.totalNumberOfPayments, item.loanRate],
    })),
    currencyAddress: data.currencyAddress,
    autoRepayStatus: data.autoRepayStatus,
    wallet: data.wallet,
  };
};

const serializeResponseData = (data: IResponse): IResult => {
  return {
    expiryDate: data.expiryDate,
    plans: data.plans.map(plan => {
      if (isNonErrored(plan)) {
        return { ...plan, price: BigNumber.from(plan.price) };
      }
      return plan;
    }),
  };
};

type IPawnPricerStep2Params = {
  items: Array<
    INft & {
      existingPlan?: {
        planId: number;
        amountToComplete: BigNumber;
      };
      isAutoLiquidated: boolean;
      term: number;
      loanRate: number;
      totalNumberOfPayments: number;
    }
  >;
  chainId: SupportedChainId;
  currencyAddress: string;
  autoRepayStatus: number;
  wallet: string;
};
type IRequestData = Omit<
  IPawnPricerStep2Params,
  "chainId" | "term" | "totalNumberOfPayments" | "loanRate" | "items"
> & {
  chain: string;
  items: Array<
    INft & {
      option: [number, number, number];
      isAutoLiquidated: boolean;
    }
  >;
};

type IRequestDataDeprecated = Omit<
  IPawnPricerStep2Params,
  "chainId" | "term" | "totalNumberOfPayments" | "loanRate" | "items"
> & {
  chain: string;
  items: Array<
    INft & {
      option: [number, number, number];
    }
  >;
};

type IPlan = {
  planId: number;
  signature: string;
  price: string;
  interestRate: number;
  vaultAddress: string;
  marketName?: string;
};
interface IResponse {
  plans: Errored<IPlan>[];
  expiryDate: number;
}
interface IResult {
  plans: Errored<Override<IPlan, { price: BigNumber }>>[];
  expiryDate: number;
}
