import { BigNumber, constants } from "ethers";
import orderBy from "lodash.orderby";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { ArrowDown, HelpCircle } from "react-feather";
import { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";
import { SupportedCurrenciesType } from "@usecyan/shared/types/currency";

import {
  Box,
  Button,
  Card,
  CurrencyLogo,
  Flex,
  Hidden,
  SkeletonLine,
  SwitchButton,
  SwitchButtonGroup,
  SystemMessage,
  Text,
  Toggler,
  Tooltip,
  TooltipText,
  getCurrencyLogoSrc,
} from "@cyanco/components/theme";
import { ChevronDown } from "@cyanco/components/theme/v3/icons";

import { useAppContext } from "@/components/AppContextProvider";
import {
  AutoRepaymentSwitch,
  AutoRepaymentWarning,
  AutoRepaymentWarningNonNative,
} from "@/components/PlanPayment/PlanAutoRepayment";
import { ImageWrapper, StyledChevronDown, StyledSelect } from "@/components/PrivateSale/CommonComponents";
import { useVaults } from "@/components/Vault/VaultDataProvider";
import { ChevronDownWrapper } from "@/components/Vault/components/VaultDetail/VaultStake";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { usePlanCreation } from "@/hooks";
import { _getPossibilityMap, usePlanCreationForm } from "@/hooks/usePlanCreationForm";
import { useWalletModalToggle } from "@/state/application/hooks";
import { IAutoRepayStatuses, INftType } from "@/types";
import { IsInTestDrive, bigNumToFloat, bigNumToFloatFormatted, displayInUSD, shortenName } from "@/utils";
import { mapAndLogError } from "@/utils/error";
import { pricerPossibleErrors } from "@/utils/error/api";
import { IMappedError, REQUEST_MORE_INFO, defaultError } from "@/utils/error/msgs";

import { PaymentSteps } from "../PaymentsSteps";
import { IPlanCreationModal, PlanCreationDecimalFormatMap } from "../types";
import { autoLiquidationExperimentFlag, getPricerCalculator, hasExpensiveItems } from "../utils";
import { IItemsWithPricer, PlanCreationSteps } from "./PlanCreationModal";
import { PillWrapper } from "./PricerStep1Result";

const switch_btn_height = "22px";
export const PlanCreationSetTerm = ({
  items,
  planType,
  currency,
  loading,
  selectedItem,
  setItemsWithPricer,
  pricePlanStep2,
  pricePlanStep1,
  setCreationStep,
  triggeredError,
  setTriggeredError,
  selectItem,
  pricePlanStep1Result,
  pricerStep2,
  onChangeCurrency,
  supportedCurrencies,
  baseConfig,
  onChangeAutoLiquidationState,
  onClose,
  getAutoLiquidationOptions,
}: IPlanCreationModal) => {
  const theme = useTheme();
  const cyanWallet = useCyanWallet();
  const { usdPrice, experiment } = useAppContext();
  const toggleWalletModal = useWalletModalToggle();
  const { account, chainId } = useWeb3React();
  const { initiatePlanCreationV2, checkAccountBalanceAndProceed } = usePlanCreation(planType);
  const [erc1155Warning, setErc1155Warning] = useState(false);
  const { vaults } = useVaults();
  const hasPossibleConfig = selectedItem.config.length >= 1;
  const isBnplPlan = planType === "bnpl";
  const isAutoLiquidationEnabled = useMemo(() => {
    return experiment.result && experiment.result[autoLiquidationExperimentFlag(chainId)];
  }, [experiment, chainId]);
  const possibilities = useMemo(
    () => _getPossibilityMap(planType, hasPossibleConfig ? selectedItem.config : baseConfig),
    [selectedItem.config, hasPossibleConfig],
  );
  const isVaultAutoLiquidationEditable = useMemo(
    () =>
      vaults
        .filter(v => v.currency === currency.symbol)
        .map(v => {
          const supportedProjects = v.supportedProjects.filter(
            p => (isBnplPlan ? p.isBnplAllowed : p.isPawnAllowed) && p.address === selectedItem.address,
          );
          return supportedProjects.length > 0 ? v.isAutoLiquidated : null;
        })
        .filter(v => v !== null).length === 2,
    [vaults, selectedItem],
  );
  const { duration, term, totalNumberOfPayments, loanRate, setMethod, choices, autoRepayStatus, isAutoLiquidated } =
    usePlanCreationForm(
      planType,
      baseConfig,
      hasPossibleConfig ? selectedItem.config : baseConfig,
      selectedItem?.chosenConfig,
      selectedItem.isAutoLiquidated,
    );
  const getPricingMethod = getPricerCalculator(planType, {
    config: selectedItem.config,
    baseInterestRate: selectedItem.baseInterestRate,
    totalPrice: selectedItem.price,
  });
  const pricerMethod = getPricingMethod({
    term,
    totalNumberOfPayments,
    loanRate,
    autoRepayStatus: IAutoRepayStatuses.Disabled,
  });
  const onFormChange = (...args: Parameters<typeof setMethod>) => {
    setMethod(...args);
  };
  const onAutoLiquidationTogglerChange = (value: boolean) => {
    setItemsWithPricer(
      items.map(item => {
        if (
          item.address === selectedItem.address &&
          item.tokenId === selectedItem.tokenId &&
          item.isCyanWalletAsset === selectedItem.isCyanWalletAsset
        ) {
          return {
            ...item,
            isAutoLiquidated: isAutoLiquidated,
            chosenConfig: {
              duration,
              loanRate,
              totalNumberOfPayments,
              term,
            },
          };
        } else {
          return item;
        }
      }),
    );
    onChangeAutoLiquidationState(selectedItem, value);
  };
  const saveTerm = () => {
    setItemsWithPricer(
      items.map(item => {
        if (
          item.address === selectedItem.address &&
          item.tokenId === selectedItem.tokenId &&
          item.isCyanWalletAsset === selectedItem.isCyanWalletAsset
        ) {
          return {
            ...item,
            isAutoLiquidated: isAutoLiquidated,
            chosenConfig: {
              duration,
              loanRate,
              totalNumberOfPayments,
              term,
            },
          };
        } else {
          return item;
        }
      }),
    );
    const index = items.findIndex(
      item =>
        item.address === selectedItem.address &&
        item.tokenId === selectedItem.tokenId &&
        item.isCyanWalletAsset === selectedItem.isCyanWalletAsset,
    );
    if (index + 1 < items.length) {
      selectItem(items[index + 1]);
    }
  };

  const selectedItemAlOptions = useMemo(() => {
    return getAutoLiquidationOptions(
      selectedItem.address,
      planType === "pawn" ? selectedItem.price.mul(loanRate).div(100_00) : selectedItem.price,
    );
  }, [selectedItem, loanRate]);

  useEffect(() => {
    setMethod({ isAutoLiquidated: selectedItem.isAutoLiquidated });
  }, [selectedItem.isAutoLiquidated]);

  useEffect(() => {
    if (!selectedItem?.chosenConfig) return;
    setMethod({
      duration: selectedItem.chosenConfig.duration,
      loanRate: selectedItem.chosenConfig.loanRate,
    });
  }, [selectedItem?.chosenConfig]);

  const _initiatePlanCreation = (selectedWalletAddress: string, createdItems: IItemsWithPricer) => {
    if (!pricerMethod || !pricerStep2.result || isBulkLoan || !pricePlanStep1Result) return;
    const { pricedItemsWithoutError } = pricerStep2.result;
    initiatePlanCreationV2({
      items: createdItems.map((item, i) => ({
        ...item,
        ...pricedItemsWithoutError[i],
        chosenConfig: {
          loanRate,
          totalNumberOfPayments,
          term,
          duration,
        },
        serviceFeeRate: pricerMethod.serviceFeeRate,
      })),
      autoRepayStatus: pricerMethod.autoRepayStatus,
      expiryDate: pricerStep2.result.expiryDate,
      step1Result: {
        currency,
        pricePlanStep1,
        pricePlanStep2,
        pricerPlan: pricePlanStep1Result,
      },
      selectedWalletAddress,
      onClose,
    });
  };

  const proceedPlanCreation = useCallback(
    ({ selectedWalletAddress }: { selectedWalletAddress: string }) => {
      if (!pricerMethod || !pricerStep2.result) return;
      // Handle if item has errors on DifferentMarketplace and PriceChanged
      // itemsWithoutError will be 0, if they has DifferentMarketplace and PriceChanged error
      const createdItems = items.filter(item => {
        if (item?.error) {
          return [
            pricerPossibleErrors.DifferentMarketplace,
            pricerPossibleErrors.PriceChanged,
            pricerPossibleErrors.AppraisalChanged,
          ].includes(item.error);
        }
        return true;
      });
      if (createdItems.length === 0) {
        setTriggeredError({
          title: defaultError.title,
          msg: "Getting loan with selected items is not possible at the moment.",
          description: REQUEST_MORE_INFO,
        });
        return;
      }
      _initiatePlanCreation(selectedWalletAddress, createdItems);
    },
    [items, pricerMethod, pricerStep2.result],
  );

  const _checkAccountBalanceAndProceed = async () => {
    if (!account) return;
    if ((isBnplPlan || (existingPlanForRefinance && doesUserHasToPayRefinance)) && pricerMethod && cyanWallet) {
      let payAmount = BigNumber.from(0);
      if (isBnplPlan) {
        payAmount = pricerMethod.downpaymentAmount;
      } else if (existingPlanForRefinance && existingPlanForRefinance.totalPay.gt(pricerMethod.loaningAmount)) {
        payAmount = existingPlanForRefinance.totalPay.sub(pricerMethod.loaningAmount);
      }
      if (payAmount.gt(BigNumber.from(0))) {
        await checkAccountBalanceAndProceed({
          currency,
          items,
          payAmount,
          setTriggeredError,
          onNext: proceedPlanCreation,
          planType,
        });
        return;
      }
    }
    proceedPlanCreation({ selectedWalletAddress: account });
  };

  const { execute: onConfirm, loading: onConfirmLoading } = useAsyncCallback(async () => {
    setTriggeredError(undefined);
    if (!account) {
      toggleWalletModal();
      return;
    }
    if (!chainId || !isReadyToPreview) return;
    if (
      planType === "pawn" &&
      items.length > 1 &&
      items.some(item => !item.isCyanWalletAsset && item.itemType === INftType.ERC1155)
    ) {
      if (!erc1155Warning) {
        setErc1155Warning(true);
        return;
      }
    }
    if (pricerStep2.result && !pricerStep2.result.hasError) {
      await _checkAccountBalanceAndProceed();
    } else {
      pricerStep2.execute(autoRepayStatus, { duration, term, totalNumberOfPayments, loanRate });
    }
  });
  useEffect(() => {
    if (!isReadyToPreview || !pricerStep2.result || pricerStep2.result.hasError || isBulkLoan) return;
    // Proceeding plan creation automatically if there is no error
    _checkAccountBalanceAndProceed();
  }, [proceedPlanCreation, pricerMethod, pricerStep2.result]);

  const existingPlanForRefinance = selectedItem.existingPlan;
  const isBulkLoan = items.length > 1;
  const doesUserHasToPayRefinance =
    existingPlanForRefinance &&
    pricerMethod?.loaningAmount &&
    existingPlanForRefinance.totalPay.gt(pricerMethod.loaningAmount);
  const currencyFormatNumber = PlanCreationDecimalFormatMap.get(currency.decimal) || 4;
  const isUserSavedConfig =
    selectedItem?.chosenConfig &&
    selectedItem.chosenConfig.duration === duration &&
    selectedItem.chosenConfig.loanRate === loanRate &&
    selectedItem.chosenConfig.term === term &&
    selectedItem.chosenConfig.totalNumberOfPayments === totalNumberOfPayments &&
    selectedItem.isAutoLiquidated === isAutoLiquidated;
  const isReadyToPreview = items.every(item => item.chosenConfig) || (!isBulkLoan && !!pricerMethod);
  const isNativeCurrencyPlan = currency.address.toLowerCase() === constants.AddressZero.toLowerCase();
  const isPossibleToCreateBnplPlan = items.every(i => i?.isPossibleToBuy !== false);
  const pricerStep2ErrorMapped = pricerStep2.error ? mapAndLogError(pricerStep2.error) : undefined;
  return (
    <>
      {triggeredError && (
        <SystemMessage
          variant="error"
          title={triggeredError.title}
          msg={triggeredError.msg}
          description={triggeredError.description}
        />
      )}
      {pricerStep2ErrorMapped && (
        <SystemMessage
          variant="error"
          title={pricerStep2ErrorMapped.title}
          msg={pricerStep2ErrorMapped.msg}
          description={pricerStep2ErrorMapped.description}
        />
      )}
      {isBulkLoan && selectedItem?.error && (
        <SystemMessage variant="warning" title="Warning" msg={selectedItem?.error} />
      )}
      {isBulkLoan && items.some(i => i?.error) && !selectedItem?.error && (
        <SystemMessage
          variant="warning"
          title="Warning"
          msg={"Please select items with a red border, check their prices, and confirm the results."}
        />
      )}
      {hasExpensiveItems([selectedItem]) && (
        <SystemMessage
          variant="error"
          title={"This item is likely to be rejected"}
          msg={
            <Text size="xs" weight="500" color="black">
              {`Higher priced items may not be approved by the protocol. Please note gas fees to initialize the loan are nonrefundable.`}
            </Text>
          }
          description={REQUEST_MORE_INFO}
        />
      )}
      <Flex gap="12px" direction="column">
        <Text size="lg" weight="600" color="secondary">
          Borrow
        </Text>
        <Flex gap="8px" direction="column">
          <Flex gap="8px" direction="column">
            {isBulkLoan && (
              <Card pt="8px" pb="14px" pl="8px" pr="8px">
                <Flex direction="column" gap="0.2rem">
                  <Text size="sm" weight="too" color="gray0">
                    You collateralise
                  </Text>
                  {loading ? (
                    <SkeletonLine w="100%" h="22px" />
                  ) : (
                    <Flex justifyContent="space-between" alignItems="center">
                      <Text size="lg" weight="600" color="secondary">
                        {shortenName(selectedItem.collectionName, 30)}
                      </Text>
                      <Text size="lg" weight="600" color="secondary">
                        #{shortenName(selectedItem.tokenId, 7, 3, 2)}
                      </Text>
                    </Flex>
                  )}
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text size="xs" weight="500" color="gray0">
                      {isBnplPlan ? "Purchase price" : "Appraisal value"}
                    </Text>
                    <Text size="xs" weight="500" color="gray0">
                      {`${bigNumToFloatFormatted(selectedItem.price, currency.decimal, currencyFormatNumber)} ${
                        currency.symbol
                      }`}
                    </Text>
                  </Flex>
                </Flex>
              </Card>
            )}
            {isBulkLoan && (
              <div style={{ position: "relative" }}>
                <ChevronDownWrapper>
                  <ArrowDown color={theme.colors.secondary} size={22} />
                </ChevronDownWrapper>
              </div>
            )}
          </Flex>
          <Card p="8px">
            <Flex direction="column" gap="0.2rem">
              <Text size="sm" weight="600" color="secondary">
                Loan Length
              </Text>
              <SwitchButtonGroup<number>
                activeBackground={theme.colors.cyan}
                activeTextColor={theme.colors.black}
                borderColor={theme.colors.gray10}
                value={duration}
                onChange={v => onFormChange({ duration: v })}
                hover
              >
                {choices.durations.map(({ label, value }) => (
                  <SwitchButton
                    value={value}
                    key={value}
                    disabled={!possibilities.loanRates[loanRate].has(value) || !hasPossibleConfig}
                    height={switch_btn_height}
                  >
                    {label}
                  </SwitchButton>
                ))}
              </SwitchButtonGroup>
            </Flex>
          </Card>
          <Card p="8px">
            <Flex direction="column" gap="0.2rem">
              <Text size="sm" weight="600" color="secondary">
                {isBnplPlan ? `Down payment` : `Borrow amount`}
              </Text>
              <SwitchButtonGroup<number>
                activeBackground={theme.colors.cyan}
                activeTextColor={theme.colors.black}
                borderColor={theme.colors.gray10}
                value={loanRate}
                onChange={v => onFormChange({ loanRate: v })}
                hover
              >
                {orderBy(choices.loanRates, "value", "desc").map(({ label, value }) => (
                  <SwitchButton
                    value={value}
                    key={value}
                    disabled={
                      !possibilities.durations[duration] ||
                      !possibilities.durations[duration].has(value) ||
                      !hasPossibleConfig
                    }
                    height={switch_btn_height}
                  >
                    {label}
                  </SwitchButton>
                ))}
              </SwitchButtonGroup>
            </Flex>
          </Card>
          {pricerMethod && (
            <Card p="8px">
              <Flex direction="column" gap="0.2rem">
                <Text size="sm" weight="too" color="gray0">
                  You borrow
                </Text>
                <Flex justifyContent="space-between" alignItems="center">
                  {loading ? (
                    <SkeletonLine w="50%" h="22px" />
                  ) : (
                    <Text size="lg" weight="600" color="secondary">
                      {`${bigNumToFloatFormatted(pricerMethod.loaningAmount, currency.decimal, currencyFormatNumber)} ${
                        currency.symbol
                      }`}
                    </Text>
                  )}
                  {isBulkLoan || isBnplPlan ? (
                    <PillWrapper>
                      <CurrencyLogo symbol={currency.symbol as SupportedCurrenciesType} />
                      <Text color="secondary" weight="500" size="md">
                        {currency.symbol}
                      </Text>
                    </PillWrapper>
                  ) : (
                    <PillWrapper
                      style={{
                        paddingRight: "0.2rem",
                      }}
                    >
                      <ImageWrapper>
                        <img
                          height="16px"
                          width="16px"
                          src={getCurrencyLogoSrc(currency.symbol as SupportedCurrenciesType)}
                        />
                      </ImageWrapper>
                      <StyledSelect
                        value={currency.address}
                        onChange={e => {
                          onChangeCurrency(e.target.value);
                        }}
                      >
                        {supportedCurrencies.map(currency => (
                          <option value={currency.address} key={currency.address}>
                            {currency.symbol}
                          </option>
                        ))}
                      </StyledSelect>
                      <StyledChevronDown>
                        <ChevronDown
                          color={theme.theme == "dark" ? theme.colors.secondary : theme.colors.gray80}
                          width="10px"
                        />
                      </StyledChevronDown>
                    </PillWrapper>
                  )}
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Text size="xs" weight="500" color="gray0">
                    {displayInUSD(
                      bigNumToFloat(pricerMethod.loaningAmount, currency.decimal) * (usdPrice[currency.symbol] ?? 0),
                    )}
                  </Text>
                </Flex>
              </Flex>
            </Card>
          )}
          {isAutoLiquidationEnabled && (
            <Card p="8px">
              <Flex justifyContent="space-between" style={{ height: "20px" }}>
                <Flex justifyContent="flex-start" gap="0.5rem" style={{ height: "20px" }}>
                  <Flex alignItems="center" gap="4px">
                    <Text size="sm" weight="600" color={"secondary"}>
                      Auto-liquidations
                    </Text>
                    <Tooltip>
                      <HelpCircle height={16} width={16} color={theme.colors.secondary} />
                      <TooltipText showArrow position="top" top="-105px" right="-70px" style={{ width: "150px" }}>
                        <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                          <div>{`Enabling this monitors the NFT's price and auto-liquidates it if it nears the loan's principal value, resulting in default and loss of the NFT.`}</div>
                        </Text>
                      </TooltipText>
                    </Tooltip>
                  </Flex>
                  {!isAutoLiquidated && selectedItemAlOptions.alVaults.exist && selectedItemAlOptions.alVaults.balance && (
                    <Flex
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      style={{
                        padding: "0 0.5rem",
                        border: `1px solid ${theme.colors.green}`,
                        backgroundColor: `${theme.backgroundColor}`,
                        color: `${theme.colors.green}`,
                        borderRadius: `6px`,
                      }}
                    >
                      <Text size="xxs" weight="600" color="green">
                        <Hidden tabletUp>
                          <p style={{ fontSize: "8px" }}>save bigly in interest!</p>
                        </Hidden>
                        <Hidden tabletDown>
                          <p>save bigly in interest!</p>
                        </Hidden>
                      </Text>
                    </Flex>
                  )}
                </Flex>
                <Toggler
                  size="sm"
                  disabled={!isVaultAutoLiquidationEditable}
                  value={isAutoLiquidated}
                  onChange={onAutoLiquidationTogglerChange}
                ></Toggler>
              </Flex>
            </Card>
          )}
          {pricerMethod && !selectedItem?.existingPlan && (
            <PaymentSteps
              pricerMethod={{
                ...pricerMethod,
                planType,
                currency: currency,
              }}
            />
          )}
          {pricerMethod && selectedItem?.existingPlan && (
            <PaymentSteps
              pricerMethod={{
                ...pricerMethod,
                planType,
                currency: currency,
              }}
              existingPlan={selectedItem.existingPlan}
            />
          )}
          {!isBulkLoan && (
            <>
              <AutoRepaymentSwitch
                autoRepayStatus={autoRepayStatus}
                onChange={v => {
                  onFormChange({ autoRepayStatus: v });
                }}
                disabled={!!!pricerMethod || !hasPossibleConfig}
              />
              {autoRepayStatus === IAutoRepayStatuses.FromCyanWallet && (
                <AutoRepaymentWarning autoRepayStatus={autoRepayStatus} />
              )}
              {autoRepayStatus === IAutoRepayStatuses.FromMainWallet && (
                <AutoRepaymentWarningNonNative
                  isNativeCurrency={isNativeCurrencyPlan}
                  currencySymbol={currency.symbol}
                />
              )}
            </>
          )}
        </Flex>
      </Flex>
      {isBulkLoan && (
        <Flex mt="12px" direction="column" gap="12px">
          <Button
            variant="outlined"
            style={{
              height: "50px",
            }}
            disabled={isUserSavedConfig || loading}
            onClick={saveTerm}
          >
            {isUserSavedConfig ? "Saved" : "Save Terms"}
          </Button>
          <Button
            style={{
              height: "50px",
            }}
            disabled={!isReadyToPreview || loading}
            onClick={() => {
              setTriggeredError(undefined);
              setCreationStep(PlanCreationSteps.Pricer2Result);
            }}
          >
            Preview
          </Button>
        </Flex>
      )}
      {existingPlanForRefinance && (
        <PayBox
          loading={pricerStep2.loading || onConfirmLoading}
          onClick={onConfirm}
          disabled={
            !isReadyToPreview ||
            IsInTestDrive ||
            pricerStep2.loading ||
            !existingPlanForRefinance ||
            loading ||
            !hasPossibleConfig
          }
          amount={
            existingPlanForRefinance && pricerMethod
              ? `${bigNumToFloatFormatted(
                  pricerMethod.loaningAmount.sub(existingPlanForRefinance.totalPay).abs(),
                  currency.decimal,
                  currencyFormatNumber,
                )} ${currency.symbol}`
              : "-"
          }
          title={doesUserHasToPayRefinance ? `Confirm to refinance and pay:` : `Confirm to refinance and receive:`}
          actionText={`Confirm`}
        />
      )}
      {!existingPlanForRefinance && !isBulkLoan && (
        <PayBox
          loading={pricerStep2.loading || onConfirmLoading}
          onClick={() => {
            setTriggeredError(undefined);
            saveTerm();
            setCreationStep(PlanCreationSteps.Pricer2Result);
          }}
          disabled={
            !isReadyToPreview || IsInTestDrive || (isBnplPlan && !isPossibleToCreateBnplPlan) || !hasPossibleConfig
          }
          amount={
            pricerMethod
              ? `${bigNumToFloatFormatted(
                  isBnplPlan ? pricerMethod.downpaymentAmount : pricerMethod.loaningAmount,
                  currency.decimal,
                  currencyFormatNumber,
                )} ${currency.symbol}`
              : "-"
          }
          title={isBnplPlan ? `Down payment due now` : `Amount to borrow`}
          actionText={`Next`}
        />
      )}
    </>
  );
};

export const PlanCreationSetTermLoading = ({
  error,
  isBulkLoan,
  planType,
}: {
  error?: IMappedError;
  isBulkLoan: boolean;
  planType: "bnpl" | "pawn";
}) => {
  const isBnplPlan = planType === "bnpl";
  const theme = useTheme();
  return (
    <>
      {error && <SystemMessage variant="error" title={error.title} msg={error.msg} description={error.description} />}
      <Flex gap="12px" direction="column">
        <Text size="lg" weight="600" color="secondary">
          Borrow
        </Text>
        <Flex gap="8px" direction="column">
          <Flex gap="0.2rem" direction="column">
            {isBulkLoan && (
              <Card pt="8px" pb="14px" pl="8px" pr="8px">
                <Flex direction="column" gap="0.2rem">
                  <Text size="sm" weight="too" color="gray0">
                    You collateralise
                  </Text>
                  <SkeletonLine w="100%" h="22px" />
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text size="xs" weight="500" color="gray0">
                      {isBnplPlan ? "Purchase price" : "Appraisal value"}
                    </Text>
                  </Flex>
                </Flex>
              </Card>
            )}
            {isBulkLoan && (
              <div style={{ position: "relative" }}>
                <ChevronDownWrapper>
                  <ArrowDown color={theme.colors.secondary} size={22} />
                </ChevronDownWrapper>
              </div>
            )}
          </Flex>
          <Card p="8px">
            <Flex direction="column" gap="0.2rem">
              <Text size="sm" weight="600" color="secondary">
                Loan Length
              </Text>
              <SkeletonLine w="100%" h="25px" />
            </Flex>
          </Card>
          <Card p="8px">
            <Flex direction="column" gap="0.2rem">
              <Text size="sm" weight="600" color="secondary">
                {isBnplPlan ? `Down payment` : `Borrow amount`}
              </Text>
              <SkeletonLine w="100%" h="25px" />
            </Flex>
          </Card>
          <Card p="8px">
            <Flex direction="column" gap="0.2rem">
              <Text size="sm" weight="too" color="gray0">
                You borrow
              </Text>
              <Flex justifyContent="space-between" alignItems="center">
                <SkeletonLine w="50%" h="22px" />
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text size="xs" weight="500" color="gray0">
                  -{" "}
                </Text>
              </Flex>
            </Flex>
          </Card>
          <SkeletonLine w="100%" h={isBnplPlan ? "125px" : "102.75px"} borderRadius="10px" />
        </Flex>
      </Flex>
      <Button
        style={{
          height: "50px",
        }}
        disabled
      >
        Preview
      </Button>
    </>
  );
};

const PayBox = ({
  amount,
  title,
  disabled,
  loading,
  onClick,
  actionText,
}: {
  amount: string;
  title: string;
  disabled: boolean;
  loading: boolean;
  actionText: string;
  onClick: () => void;
}) => {
  return (
    <Card p={"15px"}>
      <Flex justifyContent="space-between" w="100%">
        <Flex direction="column" gap="4px" w="100%">
          <Text size="sm" weight="500" color="gray0">
            {title}
          </Text>
          <Text size="lg" weight="500" color="secondary">
            {amount}
          </Text>
        </Flex>
        <Box w="120px">
          <Button disabled={disabled} onClick={onClick} loading={loading}>
            {actionText}
          </Button>
        </Box>
      </Flex>
    </Card>
  );
};
