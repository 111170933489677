import { BigNumber } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useAsync, useAsyncCallback } from "react-async-hook";

import { ArrowBack, CloseX, Flex, SystemMessage, Text, useModal } from "@cyanco/components/theme";
import { factories as f } from "@cyanco/contract";

import { priceBnplStep1, priceBnplStep2, pricePawnStep1, pricePawnStep2 } from "@/apis/pricer/index-v2";
import { IPricerErrored, IPricerResult, IResult, isPricerErrored } from "@/apis/pricer/pricer-pawn-step1-v2";
import { IPricerMethod } from "@/apis/types";
import { useAppContext } from "@/components/AppContextProvider";
import { useVaults } from "@/components/Vault/VaultDataProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { MAX_BNPL_LIMIT, MAX_PAWN_LIMIT } from "@/config";
import { isSupportedChain } from "@/constants/chains";
import { IAutoRepayStatuses, ICurrency, IPlanCreatableNft, isNonErrored } from "@/types";
import { deleteFromArray } from "@/utils";
import { executeBatchRead } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";
import { pricerPossibleErrors } from "@/utils/error/api";
import { Experiments } from "@/utils/experimentList";

import { checkIsPriceChanged } from "../utils";
import { PlanCreationItemsMetadata } from "./PlanCreationItemsMetadata";
import { PlanCreationSetTerm, PlanCreationSetTermLoading } from "./PlanCreationSelectTerm";
import { Pricer1Result } from "./PricerStep1Result";
import { Pricer2Result } from "./PricerStep2Result";

export type IPlanConfig = {
  loanRate: number;
  term: number;
  duration: number;
  totalNumberOfPayments: number;
};
export type IItemWithPricer = IPlanCreatableNft & {
  interestRate: number;
  baseInterestRate: number;
  price: BigNumber;
  config: IPricerMethod[];
  chosenConfig?: IPlanConfig;
  error?: string;
  vaultId?: number;
  isAutoLiquidated: boolean;
};

export type IPlanCreatableNftAutoLiquidated = IPlanCreatableNft & {
  isAutoLiquidated: boolean;
};

export type IItemsWithPricer = Array<IItemWithPricer>;
export type IPlanCreationStep1PropsV2 = {
  currency: ICurrency;
  items: IPlanCreatableNft[];
  currentStep?: PlanCreationSteps;
  planType: "bnpl" | "pawn";
  triggeredError?: any;
  defaultConfig?: IPlanConfig;
  pricePlanStep1Result?: IResult;
  pricePlanStep1: typeof priceBnplStep1 | typeof pricePawnStep1;
  pricePlanStep2: typeof priceBnplStep2 | typeof pricePawnStep2;
  hideHeader?: boolean;
  onClose?: () => void;
};
export const PlanCreationModal: React.FC<IPlanCreationStep1PropsV2> = ({
  currency,
  items: _items,
  pricePlanStep1,
  pricePlanStep2,
  pricePlanStep1Result,
  planType,
  defaultConfig: _defaultConfig,
  triggeredError: _triggeredError,
  currentStep,
  hideHeader,
  onClose,
}) => {
  const { unsetModal } = useModal();
  const { chainId, account, provider } = useWeb3React();
  const { experiment } = useAppContext();
  const [creationStep, setCreationStep] = useState(currentStep ?? PlanCreationSteps.Pricer1Result);
  const [selectedCurrency, setSelectedCurrency] = useState<ICurrency>(currency);
  const { vaults } = useVaults();
  const [vaultBalanceError, setVaultBalanceError] = useState(false);
  const { result: vaultBalances, loading: vaultBalancesLoading } = useAsync(async () => {
    if (!isSupportedChain(chainId) || !provider || !vaults.length) return;
    const iCyanVaultV2 = f.CyanVaultV2Factory.createInterface();
    const supportedVaults = vaults.filter(vault =>
      vault.supportedProjects.some(
        ({ isBnplAllowed, isPawnAllowed, address }) =>
          (planType === "bnpl" ? isBnplAllowed : isPawnAllowed) &&
          _items.some(({ address: collectionAddress }) => collectionAddress.toLowerCase() === address.toLowerCase()),
      ),
    );
    const vaultBalances = await executeBatchRead(
      chainId,
      provider,
      supportedVaults.map(({ contractAddress }) => ({
        contractAddress: contractAddress,
        functionName: "getMaxWithdrawableAmount",
        interface: iCyanVaultV2,
        params: [],
      })),
    );
    return new Map(supportedVaults.map((vault, index) => [vault.id, vaultBalances[index][0]]));
  }, [vaults, chainId, selectedCurrency]);
  let pricerStep1VaultCheckingError = false;

  const getVaultAutoLiquidationOptions = (
    address: string,
    loaningAmount: BigNumber,
  ): {
    alVaults: {
      exist: boolean;
      balance: boolean;
    };
    nonAlVaults: {
      exist: boolean;
      balance: boolean;
    };
  } => {
    const foundVaults = vaults.filter(vault =>
      vault.supportedProjects.some(
        ({ address: collectionAddress, isBnplAllowed, isPawnAllowed }) =>
          collectionAddress.toLowerCase() === address.toLowerCase() &&
          vault.currency === selectedCurrency.symbol &&
          (planType === "bnpl" ? isBnplAllowed : isPawnAllowed),
      ),
    );
    if (foundVaults.length === 0) {
      pricerStep1VaultCheckingError = true;
      return {
        alVaults: {
          exist: false,
          balance: false,
        },
        nonAlVaults: {
          exist: false,
          balance: false,
        },
      };
    } else {
      const alVaults = foundVaults.filter(vault => vault.isAutoLiquidated);
      const isAlVaultsBalanceEnough = alVaults.some(
        vault => vaultBalances?.has(vault.id) && vaultBalances.get(vault.id)?.gte(loaningAmount),
      );
      const noAlVaults = foundVaults.filter(vault => !vault.isAutoLiquidated);
      const isNonAlVaultsBalanceEnough = noAlVaults.some(
        vault => vaultBalances?.has(vault.id) && vaultBalances.get(vault.id)?.gte(loaningAmount),
      );
      setVaultBalanceError(!isAlVaultsBalanceEnough && !isNonAlVaultsBalanceEnough);
      return {
        alVaults: {
          exist: alVaults.length > 0,
          balance: isAlVaultsBalanceEnough,
        },
        nonAlVaults: {
          exist: noAlVaults.length > 0,
          balance: isNonAlVaultsBalanceEnough,
        },
      };
    }
  };

  const [items, setItems] = useState<IPlanCreatableNftAutoLiquidated[]>([]);
  const [itemsWithPricer, setItemsWithPricer] = useState<IItemsWithPricer>([]);
  const [triggeredError, setTriggeredError] = useState(_triggeredError);
  const [selectedItem, setSelectedItem] = useState<IItemWithPricer>();

  useEffect(() => {
    if (!vaultBalances) return;
    setItems(
      deleteFromArray(_items, ["config", "interestRate", "baseInterestRate", "error", "vaultId"]).map(item => {
        const alOptions = getVaultAutoLiquidationOptions(
          item.address,
          planType === "pawn" ? item.price.mul(25_00).div(100_00) : item.price,
        );
        return {
          ...item,
          isAutoLiquidated: alOptions.nonAlVaults.exist ? false : alOptions.alVaults.exist,
        };
      }),
    );
    setItemsWithPricer(
      deleteFromArray(_items, ["config", "interestRate", "baseInterestRate", "error", "vaultId"]).map(item => {
        const alOptions = getVaultAutoLiquidationOptions(
          item.address,
          planType === "pawn" ? item.price.mul(25_00).div(100_00) : item.price,
        );

        return {
          ...item,
          isAutoLiquidated: alOptions.nonAlVaults.exist ? false : alOptions.alVaults.exist,
          config: [],
          interestRate: 0,
          baseInterestRate: 0,
        };
      }),
    );
  }, [vaultBalances]);

  useEffect(() => {
    if (itemsWithPricer.length === 0) return;
    if (!selectedItem) {
      setSelectedItem(itemsWithPricer[0]);
      return;
    }
    const _selectedItem = itemsWithPricer.find(
      item =>
        item.address === selectedItem.address &&
        item.tokenId === selectedItem.tokenId &&
        item.isCyanWalletAsset === selectedItem.isCyanWalletAsset,
    );
    if (!_selectedItem) {
      setSelectedItem(itemsWithPricer[0]);
    } else {
      setSelectedItem(_selectedItem);
    }
  }, [itemsWithPricer]);

  const supportedCurrencies = useMemo(() => {
    if (items.length === 0) return [];
    const currencies = items.map(({ supportedCurrencies }) => supportedCurrencies ?? []);
    return currencies.reduce((acc, val) => acc.filter(x => val.some(y => y.address === x.address)));
  }, [items]);

  const removeItem = (_address: string, _tokenId: string, _privateSaleId?: number, _isCyanWalletAsset?: boolean) => {
    setItems(prev =>
      prev.filter(
        ({ address, tokenId, privateSaleId, isCyanWalletAsset }) =>
          !(
            address === _address &&
            tokenId == _tokenId &&
            privateSaleId == _privateSaleId &&
            _isCyanWalletAsset === isCyanWalletAsset
          ),
      ),
    );
    setItemsWithPricer(prev =>
      prev.filter(
        ({ address, tokenId, privateSaleId, isCyanWalletAsset }) =>
          !(
            address === _address &&
            tokenId == _tokenId &&
            privateSaleId == _privateSaleId &&
            _isCyanWalletAsset === isCyanWalletAsset
          ),
      ),
    );
  };

  const onChangeCurrency = (newAddress: string) => {
    const c = supportedCurrencies.find(({ address }) => address == newAddress);
    if (c) {
      setItems(items =>
        items.map(item => {
          return {
            ...item,
          };
        }),
      );
      setSelectedCurrency(c);
    }
  };

  const onChangeAutoLiquidationState = (selectedItem: IItemWithPricer, isAutoLiquidated: boolean) => {
    setItems(items =>
      items.map(item =>
        item.address === selectedItem.address && item.tokenId === selectedItem.tokenId
          ? { ...item, isAutoLiquidated }
          : item,
      ),
    );
  };

  const pricerStep1 = useAsync<IResult | undefined>(async () => {
    if (items.length === 0) return;
    if (
      pricePlanStep1Result?.items &&
      pricePlanStep1Result.items.length === items.length &&
      currency.address === selectedCurrency.address
    ) {
      const itemsWithBaseInterestRate = items.map((item, index) => {
        if (isPricerErrored(pricePlanStep1Result.items[index])) {
          return {
            ...item,
            error: (pricePlanStep1Result.items[index] as IPricerErrored).error,
            interestRate: 0,
            baseInterestRate: 0,
            price: BigNumber.from(0),
            config: [],
            isAutoLiquidated: false,
          };
        }
        const resultMapped = pricePlanStep1Result.items[index] as IPricerResult;
        return {
          ...item,
          interestRate: 0,
          isAutoLiquidated: item.isAutoLiquidated,
          baseInterestRate: resultMapped.baseInterestRate,
          config: resultMapped.config,
          chosenConfig: itemsWithPricer.find(
            prev =>
              prev.address === item.address &&
              prev.tokenId === item.tokenId &&
              prev.isCyanWalletAsset === item.isCyanWalletAsset,
          )?.chosenConfig,
          vaultId: resultMapped.vaultId,
          currency: selectedCurrency,
        };
      });
      setItemsWithPricer(itemsWithBaseInterestRate);
      return pricePlanStep1Result;
    }
    if (!chainId || items.length > MAX_PLANS_LIMIT) return;
    pricerStep2.reset();
    setTriggeredError(undefined);
    const result = await pricePlanStep1(
      {
        chainId,
        currentCurrencyAddress: currency.address,
        targetCurrencyAddress: selectedCurrency.address,
        items: items.map(item => ({
          ...item,
          isAutoLiquidated: item.isAutoLiquidated,
        })),
        wallet: account,
      },
      experiment.result ? experiment.result[Experiments.COLLECTION_LEVEL_LTV] : false,
    );
    const itemsWithBaseInterestRate = items.map((item, index) => {
      if (isPricerErrored(result.items[index])) {
        return {
          ...item,
          error: (result.items[index] as IPricerErrored).error,
          interestRate: 0,
          baseInterestRate: 0,
          price: BigNumber.from(0),
          config: [],
          isAutoLiquidated: false,
        };
      }
      const resultMapped = result.items[index] as IPricerResult;
      return {
        ...item,
        interestRate: 0,
        isAutoLiquidated: item.isAutoLiquidated,
        baseInterestRate: resultMapped.baseInterestRate,
        price: resultMapped.price,
        config: resultMapped.config,
        chosenConfig: itemsWithPricer.find(
          prev =>
            prev.address === item.address &&
            prev.tokenId === item.tokenId &&
            prev.isCyanWalletAsset === item.isCyanWalletAsset,
        )?.chosenConfig,
        vaultId: resultMapped.vaultId,
        currency: selectedCurrency,
      };
    });
    setItemsWithPricer(itemsWithBaseInterestRate);
    return result;
  }, [chainId, items, selectedCurrency]);

  const pricerStep2 = useAsyncCallback(async (autoRepayStatus: IAutoRepayStatuses, chosenConfig?: IPlanConfig) => {
    // resetting previous result errors
    const _items = deleteFromArray(itemsWithPricer, ["error"]).map(item => ({
      ...item,
      isAutoLiquidated: item.isAutoLiquidated,
      chosenConfig: chosenConfig ?? item.chosenConfig,
    }));
    const isReadyToPreview = _items.every(item => item.chosenConfig);
    if (!isReadyToPreview || !account) return;
    const result = await getPricerStep2Result({
      items: _items as Array<IItemWithPricer & { chosenConfig: IPlanConfig }>,
      chainId,
      currencyAddress: selectedCurrency.address,
      autoRepayStatus,
      wallet: account,
      planType,
      pricePlanStep2,
      isCollectionLtvEnabled: experiment.result ? experiment.result[Experiments.COLLECTION_LEVEL_LTV] : false,
    });
    setItemsWithPricer(result.itemWithPricedPlan);
    if (result?.hasError) {
      setCreationStep(PlanCreationSteps.SelectTerm);
    }
    return result;
  });

  const hasErroredItem = itemsWithPricer.some(
    item => !item.price || item.price.eq(0) || !item?.config || item.config.length === 0,
  );
  const MAX_PLANS_LIMIT = planType === "bnpl" ? MAX_BNPL_LIMIT : MAX_PAWN_LIMIT;
  const isRefinanceInvalid = items.length > 1 && items.some(item => item.existingPlan);
  const isPlanLimitReached = MAX_PLANS_LIMIT < items.length;
  const isPossibleToCreateBnplPlan = items.every(i => i?.isPossibleToBuy !== false);
  const hideBackArrow =
    creationStep === PlanCreationSteps.Pricer1Result ||
    (creationStep === PlanCreationSteps.SelectTerm && items.length === 1) ||
    items.some(item => item?.existingPlan);
  const pricerStep1ErrorMapped = pricerStep1.error ? mapAndLogError(pricerStep1.error) : undefined;
  return (
    <Flex gap="12px" direction="column">
      {!hideHeader && (
        <Flex alignItems="center" pb="1.5rem" justifyContent="space-between" w="100%">
          {!hideBackArrow && <ArrowBack onClick={() => setCreationStep(creationStep - 1)} />}
          <Text weight="700" size="md" color="secondary">
            {creationStep === PlanCreationSteps.Pricer1Result && (items.length > 1 ? "Bulk Loan" : "Loan")}
            {creationStep === PlanCreationSteps.SelectTerm && "Select Terms"}
            {creationStep === PlanCreationSteps.Pricer2Result && "Preview"}
          </Text>
          <div style={{ position: "relative", height: "25px" }}>
            <CloseX onClick={unsetModal} />
          </div>
        </Flex>
      )}
      {!hideHeader && items.length === 1 && (
        <PlanCreationItemsMetadata
          items={itemsWithPricer}
          removeItem={removeItem}
          loading={pricerStep1.loading || vaultBalancesLoading}
          currentStep={creationStep}
          selectedItem={selectedItem}
          selectItem={setSelectedItem}
          planType={planType}
        />
      )}
      {vaultBalanceError && (
        <SystemMessage variant="error" title="Vault Balance" msg="Vaults balance is not enough for this loan" />
      )}
      {isRefinanceInvalid && (
        <SystemMessage variant="error" title="Loan Limit" msg="A maximum of 1 NFTs can be refinanced at a time." />
      )}
      {isPlanLimitReached && (
        <SystemMessage
          variant="error"
          title="Loan Limit"
          msg={`A maximum of ${MAX_PLANS_LIMIT} NFTs can be bulk loaned at a time.`}
        />
      )}
      {pricerStep1VaultCheckingError && (
        <SystemMessage
          variant="error"
          title={"Could not find a suitable vault"}
          msg={"Could not find a vault that supports collection of some of the items"}
        />
      )}
      {pricerStep1ErrorMapped && (
        <SystemMessage
          variant="error"
          title={pricerStep1ErrorMapped.title}
          msg={pricerStep1ErrorMapped.msg}
          description={pricerStep1ErrorMapped.description}
        />
      )}
      {hasErroredItem && !pricerStep1.loading && !pricerStep1ErrorMapped && (
        <SystemMessage
          variant="error"
          title={`No loan options available.`}
          msg={
            items.length > 1
              ? `We apologize, but there are currently no available loan options for some items. Please check red bordered items.`
              : `We apologize, but there are currently no available loan options for this item.`
          }
        />
      )}
      {!hideHeader && items.length > 1 && (
        <PlanCreationItemsMetadata
          items={itemsWithPricer}
          removeItem={removeItem}
          loading={pricerStep1.loading || vaultBalancesLoading}
          currentStep={creationStep}
          selectedItem={selectedItem}
          selectItem={setSelectedItem}
          planType={planType}
        />
      )}
      {creationStep === PlanCreationSteps.Pricer1Result && (
        <Pricer1Result
          planType={planType}
          items={itemsWithPricer}
          selectedCurrency={selectedCurrency}
          onChangeCurrency={onChangeCurrency}
          supportedCurrencies={supportedCurrencies}
          loading={pricerStep1.loading || vaultBalancesLoading}
          hasError={
            isRefinanceInvalid ||
            isPlanLimitReached ||
            hasErroredItem ||
            !selectedItem ||
            (planType === "bnpl" && !isPossibleToCreateBnplPlan)
          }
          onClick={() => {
            setTriggeredError(undefined);
            setCreationStep(PlanCreationSteps.SelectTerm);
          }}
        />
      )}
      {creationStep === PlanCreationSteps.SelectTerm &&
        selectedItem &&
        pricerStep1.result &&
        !vaultBalancesLoading &&
        !pricerStep1.loading &&
        selectedItem?.vaultId && (
          <PlanCreationSetTerm
            planType={planType}
            items={itemsWithPricer}
            getAutoLiquidationOptions={getVaultAutoLiquidationOptions}
            currency={selectedCurrency}
            loading={pricerStep1.loading || vaultBalancesLoading}
            setItemsWithPricer={setItemsWithPricer}
            selectedItem={selectedItem}
            pricePlanStep1={pricePlanStep1}
            pricePlanStep2={pricePlanStep2}
            pricerStep2={pricerStep2}
            setCreationStep={setCreationStep}
            selectItem={setSelectedItem}
            pricePlanStep1Result={pricerStep1.result}
            triggeredError={triggeredError}
            setTriggeredError={setTriggeredError}
            supportedCurrencies={supportedCurrencies}
            onChangeCurrency={onChangeCurrency}
            baseConfig={pricerStep1.result.baseConfig[selectedItem.vaultId]}
            onChangeAutoLiquidationState={onChangeAutoLiquidationState}
            onClose={onClose}
          />
        )}
      {creationStep === PlanCreationSteps.SelectTerm &&
        (vaultBalancesLoading || pricerStep1.loading || (!pricerStep1.loading && pricerStep1.error)) && (
          <PlanCreationSetTermLoading
            error={triggeredError}
            planType={planType}
            isBulkLoan={itemsWithPricer.length > 1}
          />
        )}
      {creationStep === PlanCreationSteps.Pricer2Result && selectedItem && pricerStep1.result && (
        <Pricer2Result
          planType={planType}
          items={itemsWithPricer.filter(i => i?.chosenConfig) as Array<IItemWithPricer & { chosenConfig: IPlanConfig }>}
          currency={selectedCurrency}
          loading={pricerStep1.loading || vaultBalancesLoading}
          setItemsWithPricer={setItemsWithPricer}
          setCreationStep={setCreationStep}
          pricePlanStep1={pricePlanStep1}
          pricePlanStep2={pricePlanStep2}
          pricePlanStep1Result={pricerStep1.result}
          triggeredError={triggeredError}
          setTriggeredError={setTriggeredError}
          pricerStep2={pricerStep2}
          onClose={onClose}
        />
      )}
    </Flex>
  );
};

export enum PlanCreationSteps {
  Pricer1Result = 1,
  SelectTerm = 2,
  Pricer2Result = 3,
}

export const getPricerStep2Result = async ({
  items,
  chainId,
  pricePlanStep2,
  autoRepayStatus,
  currencyAddress,
  wallet,
  planType,
  isCollectionLtvEnabled,
}: {
  planType: "bnpl" | "pawn";
  items: Array<IItemWithPricer & { chosenConfig: IPlanConfig }>;
  chainId: number;
  currencyAddress: string;
  wallet: string;
  autoRepayStatus: IAutoRepayStatuses;
  pricePlanStep2: typeof priceBnplStep2 | typeof pricePawnStep2;
  isCollectionLtvEnabled: boolean;
}) => {
  const result = await pricePlanStep2(
    {
      chainId,
      currencyAddress,
      autoRepayStatus,
      wallet,
      items: items.map(item => ({
        address: item.address,
        amount: item.amount,
        itemType: item.itemType,
        tokenId: item.tokenId,
        loanRate: item.chosenConfig.loanRate,
        totalNumberOfPayments: item.chosenConfig.totalNumberOfPayments,
        term: item.chosenConfig.term,
        privateSaleId: item?.privateSaleId,
        existingPlanId: item?.existingPlan?.planId,
        isAutoLiquidated: item.isAutoLiquidated,
      })),
    },
    isCollectionLtvEnabled,
  );
  const pricedItems = result.plans;
  const pricedItemsWithoutError = pricedItems.filter(isNonErrored);
  const itemWithPricedPlan = items.map((item, index) => {
    const pricedPlan = pricedItems[index];
    if (isNonErrored(pricedPlan)) {
      if (checkIsPriceChanged(item.price, pricedPlan.price, item.currency.address, item.currency.decimal)) {
        return {
          ...item,
          ...pricedPlan,
          error: planType === "pawn" ? pricerPossibleErrors.AppraisalChanged : pricerPossibleErrors.PriceChanged,
        };
      }
    }
    return { ...item, ...pricedPlan };
  });
  const itemsWithoutError = itemWithPricedPlan.filter(i => !!!i?.error);
  const hasError = itemsWithoutError.length !== items.length || pricedItemsWithoutError?.length !== items.length;
  return {
    ...result,
    itemWithPricedPlan,
    hasError,
    itemsWithoutError,
    pricedItemsWithoutError,
  };
};
