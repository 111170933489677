import { BigNumber } from "ethers";
import styled from "styled-components";

import { Flex } from "@cyanco/components/theme";
import { Card, Text } from "@cyanco/components/theme/v3";
import { NoImage } from "@cyanco/components/theme/v3/images";

import { IEarlyPaymentInfo, INextPayment } from "@/apis/types";
import { IPawn } from "@/components/Account/pawn.types";
import { IBNPL } from "@/components/Bnpl/bnpl.types";
import { PlanCreationDecimalFormatMap } from "@/components/PlanCreation/types";
import { IPlanStatus } from "@/constants/plans";
import { ICurrency } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, numberWithCommas, shortenName } from "@/utils";

export const BulkEarlyRepaymentBreakdown = ({
  title,
  plans,
  totalRepaymentAmountByCurrency,
}: {
  title: string;
  plans: Array<(IPawn | IBNPL) & { earlyPayment?: IEarlyPaymentInfo; nextPayment?: INextPayment; hasError?: boolean }>;
  totalRepaymentAmountByCurrency: {
    [key: string]: {
      totalCost: BigNumber;
      totalPenalty?: BigNumber;
      currency: ICurrency;
      formatNumber: number;
    };
  };
}) => {
  return (
    <Card p={"10px 8px"}>
      <Flex direction="column" gap="7px">
        <Flex justifyContent="space-between" alignItems="center">
          <Text size="sm" weight="600" color="secondary">
            {title}
          </Text>
          <Text size="xs" weight="500" color="gray0">
            {`${plans.length} ${`Loans`}`}
          </Text>
        </Flex>
        <StepperContainer>
          {plans.map((plan, index) => (
            <Flex direction="column" key={plan.planId}>
              <Flex justifyContent="space-between" key={plan.planId} alignItems="center">
                <Flex alignItems="center" gap="8px">
                  <DueCircle isPaid isLast={index === plans.length - 1}>
                    <DueCircleImg src={plan.metadata.imageUrl || NoImage} />
                  </DueCircle>
                  <Text size="xs" weight="500" color={plan.hasError ? "red" : "gray0"}>
                    {`${`Loan`} #${shortenName(plan.tokenId)}`}
                  </Text>
                </Flex>
                {plan.earlyPayment && (
                  <Text size="xs" weight="500" color={plan.hasError ? "red" : "gray0"}>
                    {`${numberWithCommas(
                      bigNumToFloat(plan.earlyPayment?.remainingPayment || 0, plan.currency.decimal),
                      PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 4,
                    )} ${plan.currency.symbol}`}
                  </Text>
                )}
                {plan.nextPayment && (
                  <Text size="xs" weight="500" color={plan.hasError ? "red" : "gray0"}>
                    {`${numberWithCommas(
                      bigNumToFloat(plan.nextPayment.currentPayment || 0, plan.currency.decimal),
                      PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 4,
                    )} ${plan.currency.symbol}`}
                  </Text>
                )}
              </Flex>
              {plan.status === IPlanStatus.Defaulted && plan.revivalInfo && (
                <Flex justifyContent="space-between" alignItems="center" pl="2rem">
                  <Flex alignItems="center" gap="8px">
                    <Text size="xs" weight="500" color={plan.hasError ? "red" : "gray0"}>
                      {`${`Penalty amount`}`}
                    </Text>
                  </Flex>
                  <Text size="xs" weight="500" color={plan.hasError ? "red" : "gray0"}>
                    {`${numberWithCommas(
                      bigNumToFloat(plan.revivalInfo.penaltyAmount || 0, plan.currency.decimal),
                      PlanCreationDecimalFormatMap.get(plan.currency.decimal) || 8,
                    )} ${plan.currency.symbol}`}
                  </Text>
                </Flex>
              )}
            </Flex>
          ))}
        </StepperContainer>
        <Flex justifyContent="space-between">
          <Text size="xs" weight="500" color="secondary">
            {`Total payment amount`}
          </Text>
          <Flex direction="column">
            {Object.values(totalRepaymentAmountByCurrency).map(({ totalCost, currency, formatNumber }) => {
              return (
                <Text size="xs" weight="500" color="secondary" key={currency.address} textAlign="right">
                  {`${numberWithCommas(bigNumToFixedStr(totalCost, formatNumber, currency.decimal), formatNumber)} 
                ${currency.symbol}`}
                </Text>
              );
            })}
          </Flex>
        </Flex>
        {Object.values(totalRepaymentAmountByCurrency).some(({ totalPenalty }) => totalPenalty) && (
          <Flex justifyContent="space-between">
            <Text size="xs" weight="500" color="secondary">
              {`Total penalty amount`}
            </Text>
            <Flex direction="column">
              {Object.values(totalRepaymentAmountByCurrency)
                .filter(({ totalPenalty }) => totalPenalty)
                .map(({ totalPenalty, currency, formatNumber }) => {
                  if (!totalPenalty) return null;
                  return (
                    <Text size="xs" weight="500" color="secondary" key={currency.address} textAlign="right">
                      {`${numberWithCommas(
                        bigNumToFixedStr(totalPenalty, formatNumber, currency.decimal),
                        formatNumber,
                      )} 
                ${currency.symbol}`}
                    </Text>
                  );
                })}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-left: none;
  border-right: none;
  padding: 9px 0;
  margin-top: 3px;
  gap: 8px;
`;

const DueCircleImg = styled.img`
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  border-radius: 50%;
`;
const DueCircle = styled.div<{ isLast?: boolean; isNextPayment?: boolean; isPaid?: boolean }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: relative;
  :before {
    position: absolute;
    content: "";
    display: ${({ isLast }) => (isLast ? "none" : "block")};
    width: 1px;
    height: 22px;
    border-left: 1px solid;
    border-color: ${({ theme }) => theme.colors.gray0};
    bottom: -155%;
    left: 6px;
  }
`;
