import orderBy from "lodash.orderby";
import { useEffect, useMemo, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import { Edit } from "react-feather";
import styled, { useTheme } from "styled-components";

import { StyledCheckbox } from "@cyanco/components/theme";
import { Flex } from "@cyanco/components/theme/Flex";
import { breakpoints, getStyleWithMediaQuery } from "@cyanco/components/theme/config";
import { Loader, SkeletonLine, SubTitle, Text } from "@cyanco/components/theme/v3";
import { CollectioNoImage } from "@cyanco/components/theme/v3/images";

import { requestProjectsOfVault, updateSupportedProjects } from "@/apis/vault/admin";
import { IRequestedProjectStatus, IVault, IVaultRequestedProject } from "@/apis/vault/types";
import { useAuthContext } from "@/components/AuthContext/AuthContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { CHAIN_IDS_TO_NAMES, SupportedChainId } from "@/constants/chains";

import { divideArrayByN, jumpToLink, shortenName } from "../../../../../utils";
import { CollectionSearch } from "./CollectionSearch";
import { CancelSettings, SaveSettings } from "./PoolManagement";
import { getVaultAdminSignatureExpiry, signRequestedProjectsUpdate, signSupportedProjectUpdate } from "./utils";

export type IProject = {
  name: string;
  address: string;
  imageUrl: string;
  isBnplAllowed: boolean;
  isPawnAllowed: boolean;
  isNew?: boolean;
  status?: IRequestedProjectStatus;
};

export const SupportedCollections = ({
  vault,
  requestedProjects,
  refreshProjects,
}: {
  vault: IVault;
  requestedProjects: IVaultRequestedProject[];
  refreshProjects: () => Promise<void>;
}) => {
  const theme = useTheme();
  const { signInWallet } = useAuthContext();
  const { signer, account } = useWeb3React();
  const [isEditing, setIsEditing] = useState(false);
  const [collectionsMerged, setCollections] = useState<Array<IProject>>([]);

  useEffect(() => {
    setCollections([
      ...vault.supportedProjects.map(item => ({
        name: item.name,
        address: item.address,
        imageUrl: item.imageUrl,
        isBnplAllowed: item.isBnplAllowed,
        isPawnAllowed: item.isPawnAllowed,
      })),
      ...requestedProjects.map(item => ({
        name: item.name,
        address: item.address,
        imageUrl: item.imageUrl,
        status: item.status,
        isBnplAllowed: item.isBnplAllowed,
        isPawnAllowed: item.isPawnAllowed,
      })),
    ]);
  }, [vault.supportedProjects, requestedProjects]);

  const collectionCountPerColumn = Math.round(collectionsMerged.length / 2);
  const collectionDivided: Array<Array<IProject>> = divideArrayByN(
    orderBy(collectionsMerged, [item => item.name.toLowerCase()]),
    collectionCountPerColumn,
  );

  const handleCollectionFlag = (
    collectionAddress: string,
    value: boolean,
    target: "isBnplAllowed" | "isPawnAllowed",
  ) => {
    const data = [...collectionsMerged];
    const index = data.findIndex(item => item.address === collectionAddress);
    if (index !== -1) {
      data[index][target] = value;
      setCollections(data);
    }
  };

  const addNewCollection = (collection: IProject) => {
    setCollections([...collectionsMerged, collection]);
  };

  const flagChangedCollections = useMemo(() => {
    return collectionsMerged.filter(item =>
      vault.supportedProjects.some(
        project =>
          project.address === item.address &&
          (project.isBnplAllowed !== item.isBnplAllowed || project.isPawnAllowed !== item.isPawnAllowed),
      ),
    );
  }, [collectionsMerged, vault.supportedProjects]);

  const flagChangedPendingCollections = useMemo(() => {
    return collectionsMerged.filter(item =>
      requestedProjects.some(
        project =>
          project.address === item.address &&
          (project.isBnplAllowed !== item.isBnplAllowed || project.isPawnAllowed !== item.isPawnAllowed),
      ),
    );
  }, [collectionsMerged, requestedProjects]);

  const newRequestingCollection = useMemo(() => {
    return collectionsMerged.filter(item => item.isNew);
  }, [collectionsMerged]);

  const hasChanged = useMemo(() => {
    return (
      flagChangedCollections.length > 0 ||
      flagChangedPendingCollections.length > 0 ||
      newRequestingCollection.length > 0
    );
  }, [flagChangedCollections, flagChangedPendingCollections, newRequestingCollection]);

  const {
    loading: loading,
    error,
    execute: updateCollection,
  } = useAsyncCallback(async () => {
    if (!signer || !account) return;
    const { token } = await signInWallet();
    if (newRequestingCollection.length > 0 || flagChangedPendingCollections.length > 0) {
      const expiryDate = getVaultAdminSignatureExpiry();
      const merged = [...newRequestingCollection, ...flagChangedPendingCollections];
      const signature = await signRequestedProjectsUpdate(
        merged.map(({ address, isBnplAllowed, isPawnAllowed }) => ({
          address,
          isBnplAllowed,
          isPawnAllowed,
        })),
        vault.contractAddress,
        expiryDate,
        signer,
      );
      await requestProjectsOfVault({
        vaultAddress: vault.contractAddress,
        token,
        projects: merged.map(project => ({
          name: project.name,
          address: project.address,
          isBnplAllowed: project.isBnplAllowed,
          isPawnAllowed: project.isPawnAllowed,
          imageUrl:
            !project.imageUrl || project.imageUrl === "" ? "https://usecyan.com/no-image.svg" : project.imageUrl,
        })),
        signature,
        expiryDate,
      });
    }

    if (flagChangedCollections.length > 0) {
      const expiryDate = getVaultAdminSignatureExpiry();
      const signature = await signSupportedProjectUpdate(
        flagChangedCollections.map(({ address, isBnplAllowed, isPawnAllowed }) => ({
          address,
          isBnplAllowed,
          isPawnAllowed,
        })),
        vault.contractAddress,
        expiryDate,
        signer,
      );
      await updateSupportedProjects({
        vaultAddress: vault.contractAddress,
        token,
        projects: flagChangedCollections.map(project => ({
          address: project.address,
          isBnplAllowed: project.isBnplAllowed,
          isPawnAllowed: project.isPawnAllowed,
        })),
        signature,
        expiryDate,
      });
    }
    await refreshProjects();
  });

  return (
    <Container direction="column" gap="2.5rem" mt="4.5rem">
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <SubTitle>{`Supported Collections`}</SubTitle>
        {isEditing ? (
          <CollectionSearch chainId={vault.chainId} addNewCollection={addNewCollection} />
        ) : (
          <SaveSettings
            onClick={() => {
              setIsEditing(true);
            }}
          >
            <Flex alignItems="center" gap="5px">
              <Edit color={theme.colors.primary} size={18} />
              <Text color="primary" weight="700">{`Edit`}</Text>
            </Flex>
          </SaveSettings>
        )}
      </Flex>
      <VaultContainer w="100%">
        {collectionDivided.map((collections, index) => {
          return (
            <Flex direction="column" gap="1rem" key={index} w="100%">
              <Flex justifyContent="flex-end" style={{ visibility: isEditing ? "visible" : "hidden" }}>
                <Flex gap="1rem" justifyContent="center" w="100px">
                  <Text size="sm" color="secondary">
                    BNPL
                  </Text>
                  <Text size="sm" color="secondary">
                    Loans
                  </Text>
                </Flex>
              </Flex>
              {collections.map(collection => {
                const slug = vault.supportedProjects.find(
                  item => item.address === collection.address && item.isBnplAllowed,
                )?.slug;
                const url = slug ? `/bnpl/${CHAIN_IDS_TO_NAMES[vault.chainId as SupportedChainId]}/${slug}` : undefined;
                return (
                  <Collection
                    isEditing={isEditing}
                    collection={collection}
                    key={collection.address}
                    handleCollectionFlag={handleCollectionFlag}
                    shopUrl={url}
                  />
                );
              })}
            </Flex>
          );
        })}
      </VaultContainer>
      {isEditing ? (
        <Flex gap="10px">
          <SaveSettings
            onClick={async () => {
              if (isEditing) {
                await updateCollection();
              }
              setIsEditing(!isEditing);
            }}
            disabled={loading || !hasChanged}
          >
            {loading ? (
              <Flex gap="5px">
                <Text color="primary" weight="700" lineHeight={18}>{`Done`}</Text>
                <Loader size="18px" stroke={theme.colors.primary} />
              </Flex>
            ) : (
              <Text color="primary" weight="700" lineHeight={18}>{`Done`}</Text>
            )}
          </SaveSettings>
          <CancelSettings
            onClick={async () => {
              setIsEditing(false);
              setCollections([
                ...vault.supportedProjects.map(item => ({
                  name: item.name,
                  address: item.address,
                  imageUrl: item.imageUrl,
                  isBnplAllowed: item.isBnplAllowed,
                  isPawnAllowed: item.isPawnAllowed,
                })),
                ...requestedProjects.map(item => ({
                  name: item.name,
                  address: item.address,
                  imageUrl: item.imageUrl,
                  status: item.status,
                  isBnplAllowed: item.isBnplAllowed,
                  isPawnAllowed: item.isPawnAllowed,
                })),
              ]);
            }}
          >
            <Text color="secondary" weight="700" lineHeight={18}>{`Cancel`}</Text>
          </CancelSettings>
        </Flex>
      ) : (
        <SaveSettings
          onClick={() => {
            setIsEditing(true);
          }}
        >
          <Flex alignItems="center" gap="5px">
            <Edit color={theme.colors.primary} size={18} />
            <Text color="primary" weight="700">{`Edit`}</Text>
          </Flex>
        </SaveSettings>
      )}
      {error && (
        <Text color="red" size="sm">
          {error.message}
        </Text>
      )}
    </Container>
  );
};

const Collection = ({
  collection,
  shopUrl,
  isEditing,
  handleCollectionFlag,
}: {
  collection: IProject;
  shopUrl?: string;
  isEditing?: boolean;
  handleCollectionFlag: (collectionAddress: string, value: boolean, target: "isBnplAllowed" | "isPawnAllowed") => void;
}) => {
  const [imageLoading, setImageLoading] = useState(true);
  return (
    <Flex alignItems="center" gap="8px" w="100%" justifyContent="space-between">
      <CollectionWrapper
        gap="8px"
        isClickable={!!shopUrl && !isEditing}
        onClick={() => {
          if (shopUrl) jumpToLink(shopUrl);
        }}
      >
        {imageLoading && <SkeletonLine borderRadius="50%" w="24px" h="24px" />}
        <StyledImg
          src={collection.imageUrl ?? CollectioNoImage}
          alt={collection.name}
          onLoad={() => setImageLoading(false)}
          style={{
            display: imageLoading ? "none" : "block",
            opacity: collection.isBnplAllowed || collection.isPawnAllowed ? 1 : 0.7,
          }}
        />
        <Flex gap="5px" alignItems="flex-end">
          <CollectionText
            color={collection.isBnplAllowed || collection.isPawnAllowed ? "secondary" : "gray0"}
            size="lg"
          >
            {shortenName(collection.name, 25)}
          </CollectionText>
          {collection?.status === IRequestedProjectStatus.Pending && <Text color="cyan" size="xxs">{`Pending`}</Text>}
          {collection?.isNew && <Text color="green" size="xxs">{`New`}</Text>}
        </Flex>
      </CollectionWrapper>
      <Flex justifyContent="space-around" gap="1rem" w="100px">
        {isEditing && (
          <StyledCheckbox
            checked={collection.isBnplAllowed}
            id={`${collection.address}-bnpl`}
            onChange={() => handleCollectionFlag(collection.address, !collection.isBnplAllowed, "isBnplAllowed")}
          />
        )}
        {isEditing && (
          <StyledCheckbox
            checked={collection.isPawnAllowed}
            id={`${collection.address}-pawn`}
            onChange={() => handleCollectionFlag(collection.address, !collection.isPawnAllowed, "isPawnAllowed")}
          />
        )}
      </Flex>
    </Flex>
  );
};

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  border-radius: 50%;
`;

const CollectionText = styled(Text)`
  transition: opacity 0.2s;
`;

const CollectionWrapper = styled(Flex)<{
  isClickable?: boolean;
}>`
  width: fit-content;
  cursor: ${({ isClickable }) => (isClickable ? "pointer" : "default")};
  &:hover ${CollectionText} {
    opacity: ${({ isClickable }) => (isClickable ? 0.7 : 1)};
  }
`;

const Container = styled(Flex)`
  padding: 0 18px;
  ${getStyleWithMediaQuery("padding", "", [{ [breakpoints.tablet]: "0 5px" }])}
`;

const VaultContainer = styled(Flex)`
  flex-direction: row;
  gap: 5rem;
  ${getStyleWithMediaQuery("flex-direction", "", [{ [breakpoints.tablet]: "column" }])};
  ${getStyleWithMediaQuery("gap", "rem", [{ [breakpoints.tablet]: 1 }])};
`;
