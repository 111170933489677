import { BigNumber } from "ethers";

import { factories as f } from "@cyanco/contract";

import { IPrivateSale } from "./apis/private-sale";

export enum INftType {
  ERC721 = 1,
  ERC1155 = 2,
  CryptoPunks = 3,
}
export const ITEM_AMOUNT_BY_NFT_TYPE: { [key: number]: number } = {
  [INftType.ERC721]: 0,
  [INftType.ERC1155]: 1,
  [INftType.CryptoPunks]: 0,
};
export type INft = {
  address: string;
  tokenId: string;
  amount: number;
  itemType: INftType;
  privateSaleId?: number;
  existingPlanId?: number;
};

export type ICurrency = {
  symbol: string;
  address: string;
  decimal: number;
};

// TODO this type must be generated by intersection of IListedNft | IUserNft
export type IPlanCreatableNft = INft & {
  planType?: "bnpl" | "pawn";
  price: BigNumber;
  collectionName: string;
  imageUrl: string | null;
  currency: ICurrency;
  isFlaggedByOS?: boolean;
  marketName?: string;
  rarityStat?: string;
  supportedCurrencies?: Array<ICurrency>;
  isCyanWalletAsset?: boolean;
  isPossibleToBuy?: boolean;
  owner?: string;
  existingPlan?: {
    planId: number;
    amountToComplete: BigNumber;
    // if revivalFee is not null, it means the plan is defaulted
    revivalFee?: BigNumber;
    totalPay: BigNumber;
  };
};

export type INftBe = IPlanCreatableNft & {
  isPrivate?: boolean;
  listedAt: Date;
  topBidPrice: {
    amount: BigNumber;
    currency: {
      symbol: string;
      decimal: number;
    };
  } | null;
  lastSellPrice: {
    amount: BigNumber;
    currency: {
      symbol: string;
      decimal: number;
    };
  } | null;
  marketIcon: string;
  isFlaggedByOS: boolean;
  rarityRank: number;
  marketName: string;
  privateSale?: IPrivateSale | null;
  supply: number;
  remainingSupply: number;
};

export type Errored<T> = T | { error: string };
export const isNonErrored = <T>(i: Errored<T>): i is T => !("error" in i);

export type ICreatePlanParams = {
  item: f.PaymentPlanV2Contract["ItemStruct"];
  plan: f.PaymentPlanV2Contract["PlanStruct"];
  planId: number;
  currency: ICurrency;
  expiryDate: number;
  signature: string;
};

export enum IAutoRepayStatuses {
  Disabled = 0,
  FromCyanWallet = 1,
  FromMainWallet = 2,
}
