import { ContractTransaction, constants as ethConsts, utils } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { ArrowRight } from "react-feather";
import { useTheme } from "styled-components";

import { useCyanWallet } from "@usecyan/cyan-wallet";
import { SupportedCurrencies } from "@usecyan/shared/types/currency";

import { Box, Flex } from "@cyanco/components/theme";
import { Stepper, Text, getCurrencyLogoSrc, useModal } from "@cyanco/components/theme/v3";
import { CyanC } from "@cyanco/components/theme/v3/images";
import { factories as f } from "@cyanco/contract";

import { IVault } from "@/apis/vault/types";
import { useAppContext } from "@/components/AppContextProvider";
import { useTransactionContext } from "@/components/TransactionContextProvider";
import { useWeb3React } from "@/components/Web3ReactProvider";
import { apeCoinContract } from "@/config";
import { useApproval } from "@/hooks/useApproval";
import { getChainExplorerTextForTxn, getChainExplorerURL, numberWithCommas } from "@/utils";
import { predictCyanWalletAddress } from "@/utils/contract";
import { mapAndLogError } from "@/utils/error";

import { useVaultPositions } from "../../VaultDataProvider";
import { VaultContractAbiNames, VaultDecimalFormatMap } from "../../types";
import { StakingModal } from "./StakingModal";
import { StyledConfirmButton, StyledImg, VaultImage, VaultImageWrapper } from "./common";

export const StakingModalProgress = ({
  stakingAmount,
  vault,
  selectedWallet,
  setStakingAmount,
}: {
  stakingAmount: number;
  vault: IVault;
  selectedWallet: string;
  setStakingAmount: (a: string) => void;
}) => {
  const cyanWallet = useCyanWallet();
  const { chainId, account, signer, provider } = useWeb3React();
  const { giveErc20Approval } = useApproval(true);
  const { usdPrice, setFireConfetti } = useAppContext();
  const theme = useTheme();
  const { setModalContent, unsetModal } = useModal();
  const { addTransaction, transactions } = useTransactionContext();
  const { fetchPositions } = useVaultPositions();
  const [selectedStep, setSelectedStep] = useState<Steps>(Steps.Approval);
  const [txnFinal, setTxnFinal] = useState<string | null>(null);
  const [activeTx, setActiveTx] = useState<string | null>(null);
  const formatNumber = useMemo(() => {
    return VaultDecimalFormatMap.get(vault.decimals) || 5;
  }, [vault.decimals]);
  const stakingAmountInToken = useMemo(() => {
    const amount = (stakingAmount / vault.priceUsd) * usdPrice[vault.currency] * (1 - vault.transactionFee / 10000);
    return Math.trunc(amount * Math.pow(10, formatNumber)) / Math.pow(10, formatNumber);
  }, [stakingAmount, vault]);

  const checkAndApproveNonNativeCurrency = async (currencyAddress: string) => {
    if (currencyAddress.toLowerCase() === ethConsts.AddressZero.toLowerCase()) return;
    const amount = utils.parseUnits(stakingAmount.toString(), vault.decimals);
    if (cyanWallet && cyanWallet.walletAddress.toLowerCase() === selectedWallet.toLowerCase()) {
      await giveErc20Approval(
        {
          currencyAddress,
          amount,
          cyanWallet,
        },
        vault.contractAddress,
      );
    }
    if (account && account.toLowerCase() === selectedWallet.toLowerCase()) {
      await giveErc20Approval(
        {
          currencyAddress,
          amount,
        },
        vault.contractAddress,
      );
    }
  };

  useEffect(() => {
    if (!activeTx) return;
    const intervalId = setInterval(() => {
      if (!transactions.find(({ hash }) => hash === activeTx)) {
        clearInterval(intervalId);
        setTxnFinal(activeTx);
        setActiveTx(null);
        setStakingAmount("");
        fetchPositions();
        setFireConfetti(true);
        setSelectedStep(Steps.Done);
      }
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [activeTx, transactions]);
  useEffect(() => {
    const onStepChange = async (step: Steps) => {
      if (!chainId || !signer || !account || !provider) return;
      const vaultContractWriter = f.CyanVaultV2Factory.connect(vault.contractAddress, signer);
      const currencyAddress =
        vault.abiName.toLowerCase() === VaultContractAbiNames.ApeCoinVaultV1.toLowerCase()
          ? apeCoinContract
          : await vaultContractWriter.getCurrencyAddress();
      try {
        switch (step) {
          case Steps.Approval: {
            await checkAndApproveNonNativeCurrency(currencyAddress);
            setSelectedStep(Steps.Staking);
            return;
          }
          case Steps.Staking: {
            let tx: ContractTransaction;
            const amount = utils.parseUnits(stakingAmount.toString(), vault.decimals);
            switch (vault.abiName) {
              case VaultContractAbiNames.CyanVaultV2:
              case VaultContractAbiNames.CyanVaultV2_1: {
                const vaultContractWriter = f.CyanVaultV2Factory.connect(vault.contractAddress, signer);
                const isNativeCurrency = currencyAddress === ethConsts.AddressZero;
                if (cyanWallet && cyanWallet.walletAddress.toLowerCase() === selectedWallet.toLowerCase()) {
                  const encodedFnVault = vaultContractWriter.interface.encodeFunctionData("deposit", [amount]);
                  const encodedFnCyan = cyanWallet.interface.encodeFunctionData("execute", [
                    vault.contractAddress,
                    isNativeCurrency ? amount : 0,
                    encodedFnVault,
                  ]);
                  tx = await signer.sendTransaction({
                    to: cyanWallet.walletAddress,
                    data: encodedFnCyan,
                  });
                } else {
                  tx = await vaultContractWriter.deposit(utils.parseUnits(stakingAmount.toString(), vault.decimals), {
                    value: isNativeCurrency ? amount : 0,
                  });
                }
                break;
              }
              case VaultContractAbiNames.ApeCoinVaultV1: {
                const apeVaultContractWriter = f.CyanApeCoinVaultV1Factory.connect(vault.contractAddress, signer);
                if (cyanWallet && cyanWallet.walletAddress.toLowerCase() === selectedWallet.toLowerCase()) {
                  const encodedFnVault = apeVaultContractWriter.interface.encodeFunctionData("deposit", [
                    {
                      recipient: cyanWallet.walletAddress,
                      amount,
                    },
                  ]);
                  const encodedFnCyan = cyanWallet.interface.encodeFunctionData("execute", [
                    vault.contractAddress,
                    0,
                    encodedFnVault,
                  ]);
                  tx = await signer.sendTransaction({
                    to: cyanWallet.walletAddress,
                    data: encodedFnCyan,
                  });
                } else {
                  const predictedCyanWalletAddress = await predictCyanWalletAddress({ provider, mainWallet: account });
                  tx = await apeVaultContractWriter.deposit({
                    recipient: predictedCyanWalletAddress,
                    amount: amount,
                  });
                }
                break;
              }
              default:
                throw new Error(`Invalid vault abi ${vault.abiName}`);
            }
            addTransaction({ type: "vault-stake", hash: tx.hash });
            setActiveTx(tx.hash);
          }
        }
      } catch (err) {
        const mappedError = mapAndLogError(err, account);
        setModalContent({
          title: `Confirm Stake`,
          content: (
            <StakingModal
              stakingAmount={stakingAmount}
              vault={vault}
              selectedWallet={selectedWallet}
              triggeredError={mappedError}
              setStakingAmount={setStakingAmount}
            />
          ),
        });
      }
    };
    onStepChange(selectedStep);
  }, [selectedStep, cyanWallet]);
  const stepMarks = useMemo(() => {
    return StepMarks.map(step => {
      switch (step.value) {
        case Steps.Approval:
          return {
            ...step,
            title: `Set approval for ${vault.currency}`,
          };
        case Steps.Staking:
          return { ...step, title: `Swapping ${vault.currency} for ${vault.symbol}` };

        case Steps.Success:
          return {
            ...step,
            title: `${vault.symbol} successfully settled`,
            description: getChainExplorerTextForTxn(chainId),
          };
        default:
          return step;
      }
    });
  }, [vault.currency, vault.symbol, chainId]);
  return (
    <Flex gap="2rem" direction="column">
      <Flex alignItems="center" gap="0.8rem">
        <Flex gap="0.4rem">
          <StyledImg src={getCurrencyLogoSrc(vault.symbol as SupportedCurrencies)} alt={vault.name} />
          <Text weight="400" color="secondary" size="md">
            {`${numberWithCommas(stakingAmount, 2)} ${vault.currency}`}
          </Text>
        </Flex>
        <ArrowRight color={theme.colors.secondary} size={15} />
        <Flex gap="0.4rem">
          <VaultImageWrapper style={{ background: vault.colorCode }}>
            <VaultImage src={CyanC} alt={vault.name} />
          </VaultImageWrapper>{" "}
          <Text weight="400" color="secondary" size="md">
            {`${numberWithCommas(stakingAmountInToken, 2)} ${vault.symbol}`}
          </Text>
        </Flex>
      </Flex>
      <Box pb="1.8rem" pt="1rem">
        <Stepper
          marks={stepMarks}
          selectedStep={selectedStep}
          txUrl={txnFinal ? `${getChainExplorerURL(chainId)}/tx/${txnFinal}` : ""}
        />
      </Box>
      {selectedStep === Steps.Done && (
        <StyledConfirmButton onClick={unsetModal}>
          <Text color="black" size="sm" weight="700">
            {`Close`}
          </Text>
        </StyledConfirmButton>
      )}
    </Flex>
  );
};

enum Steps {
  Approval = 1,
  Staking = 2,
  Success = 3,
  Done = 4,
}
const StepMarks = [
  {
    value: Steps.Approval,
    title: `Set approval for APE`,
    description: `A small amount of gas is required to approve`,
  },
  {
    value: Steps.Staking,
    title: `Swapping APE for CV11`,
  },
  {
    value: Steps.Success,
    description: `View on Etherscan`,
    title: `CV11 successfully settled`,
  },
];
