import { BigNumber } from "ethers";
import { useMemo, useState } from "react";
import { HelpCircle, MinusCircle, PlusCircle } from "react-feather";
import styled, { useTheme } from "styled-components";

import { Flex, Tooltip, TooltipText } from "@cyanco/components/theme";
import { Card, Text } from "@cyanco/components/theme/v3";
import { FilledChevronDown, MaskForBreakdownLoan } from "@cyanco/components/theme/v3/icons";

import { ICurrency } from "@/types";
import { bigNumToFixedStr, bigNumToFloat, numberWithCommas } from "@/utils";

import { useAuthContext } from "../AuthContext/AuthContextProvider";
import { PaymentStep } from "../PlanPayment/RegularPayment";
import { PlanCreationDecimalFormatMap } from "./types";
import { getInterestRate, getPaymentInterval, getPlanApr } from "./utils";

export enum BREAKDOWN_COLOR {
  dueNow = "#00492E",
  borrow = "#008C5A",
  interest = "#00FFA3",
}
type IProps = {
  pricerMethod: {
    planType: "bnpl" | "pawn";
    term: number;
    serviceFeeRate: number;
    interestRate: number;
    loanRate: number;
    totalPrice: BigNumber;
    loaningAmount: BigNumber;
    downpaymentRate: number;
    downpaymentAmount: BigNumber;
    monthlyAmount: BigNumber;
    totalNumberOfPayments: number;
    currency: ICurrency;
    totalInterestFee: BigNumber;
    totalServiceFee: BigNumber;
  };
  existingPlan?: {
    planId: number;
    amountToComplete: BigNumber;
    revivalFee?: BigNumber;
    totalPay: BigNumber;
  };
};

export const INTEREST_RATE_SPACE = 5;

export const PaymentSteps: React.FC<IProps> = ({ pricerMethod, existingPlan }) => {
  const {
    planType,
    term,
    downpaymentAmount,
    monthlyAmount,
    totalNumberOfPayments,
    currency,
    totalPrice, // bnpl price or pawn appraisal
    loaningAmount,
    downpaymentRate,
    totalInterestFee,
    totalServiceFee,
  } = pricerMethod;
  const theme = useTheme();
  const { user } = useAuthContext();
  const [openDetail, setOpenDetail] = useState(false);
  const isBnpl = planType === "bnpl";
  const downPaymentWithoutFee = totalPrice.mul(downpaymentRate).div(100_00);
  const totalNumOfPaymentsByPlanType = isBnpl ? totalNumberOfPayments - 1 : totalNumberOfPayments;
  const totalCost = monthlyAmount.mul(totalNumOfPaymentsByPlanType).add(isBnpl ? downpaymentAmount : 0);
  const extraAmountUserPaying = totalCost.sub(isBnpl ? totalPrice : loaningAmount);
  const totalRepayAmount = loaningAmount.add(totalInterestFee).add(totalServiceFee);
  const _interestRate = getInterestRate({
    principleAmount: loaningAmount,
    payAmount: totalRepayAmount,
    decimals: currency.decimal,
  });
  const borrowingPercentOfTotal =
    (bigNumToFloat(loaningAmount, currency.decimal) * 100) / bigNumToFloat(totalCost, currency.decimal);
  const purchaseAmountPercentOfTotal =
    (bigNumToFloat(totalPrice, currency.decimal) * 100) / bigNumToFloat(totalCost, currency.decimal);
  const formatNumber = useMemo(() => {
    if (bigNumToFloat(totalPrice, currency.decimal) < 1) {
      return 5;
    }
    return PlanCreationDecimalFormatMap.get(currency.decimal) || 4;
  }, [currency.decimal]);
  const apr = useMemo(() => {
    return getPlanApr({
      term,
      interestRate: _interestRate,
      totalNumberOfPayments: totalNumOfPaymentsByPlanType,
    });
  }, [_interestRate, term]);
  const isUserReceiving = existingPlan && existingPlan.amountToComplete.lte(loaningAmount);
  return (
    <Flex direction="column">
      <Card p={"10px 8px"}>
        <Flex direction="column" gap="7px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text size="sm" weight="600" color="secondary">
              {`Payment schedule`}
            </Text>
            <ExpandButton onClick={() => setOpenDetail(!openDetail)}>
              {!openDetail ? (
                <PlusCircle size={14} color={theme.colors.secondary} />
              ) : (
                <MinusCircle size={14} color={theme.colors.secondary} />
              )}
            </ExpandButton>
          </Flex>
          <StepperContainer>
            {isBnpl && (
              <PaymentStep
                isNextPayment
                text={`Due Today`}
                paymentAmount={downpaymentAmount}
                fixNumber={formatNumber}
                currency={currency}
                color="secondary"
              />
            )}
            {existingPlan && (
              <>
                <PaymentStep
                  isNextPayment
                  text={`New loan amount`}
                  paymentAmount={loaningAmount}
                  fixNumber={formatNumber}
                  currency={currency}
                  color="secondary"
                  valueColor="cyan"
                />
                <PaymentStep
                  isDeducting
                  text={`Owed on current loan`}
                  paymentAmount={existingPlan.amountToComplete}
                  fixNumber={formatNumber}
                  currency={currency}
                  color="secondary"
                  valueColor="yellow"
                />
                {existingPlan.revivalFee && (
                  <PaymentStep
                    isMissed
                    text={`Revival fee`}
                    paymentAmount={existingPlan.revivalFee}
                    fixNumber={formatNumber}
                    currency={currency}
                    color="red"
                    valueColor="red"
                  />
                )}
              </>
            )}
            {Array.from(Array(totalNumOfPaymentsByPlanType).keys()).map(paymentNumber => (
              <PaymentStep
                isLast={totalNumOfPaymentsByPlanType === paymentNumber + 1}
                key={paymentNumber}
                text={`${!isBnpl ? "due " : ""}in ${getPaymentInterval(term * (paymentNumber + 1))}`}
                paymentAmount={monthlyAmount}
                fixNumber={formatNumber}
                currency={currency}
                color="gray0"
              />
            ))}
          </StepperContainer>
          <Flex
            justifyContent="space-between"
            style={{
              borderBottom: openDetail ? `1px solid ${theme.colors.gray20}` : "0px",
              paddingTop: "2px",
              paddingBottom: openDetail ? `9px` : "0px",
            }}
          >
            <Text size="xs" weight="500" color="secondary">
              {user?.config?.showAPR ? `${apr.toFixed(2)}% APR` : `${(_interestRate / 100).toFixed(2)}% interest`}
            </Text>
            {existingPlan ? (
              <Text size="xs" weight="500" color="secondary">
                {isUserReceiving ? `You receive today: ` : `Amount to pay today: `}
                <Text size="xs" weight="500" color={isUserReceiving ? "cyan" : "yellow"}>
                  {`${numberWithCommas(
                    bigNumToFloat(existingPlan.totalPay.sub(loaningAmount).abs(), currency.decimal),
                    formatNumber,
                  )} ${currency.symbol}`}
                </Text>
              </Text>
            ) : (
              <Text size="xs" weight="500" color="secondary">
                {`Total: ${numberWithCommas(
                  bigNumToFixedStr(totalCost, formatNumber, currency.decimal),
                  formatNumber,
                )} ${currency.symbol}`}
              </Text>
            )}
          </Flex>
          <DetailContainer direction="column" open={openDetail} gap="9px">
            <Text size="sm" weight="600" color="secondary">
              {`Breakdown`}
            </Text>
            {planType === "bnpl" && (
              <Flex style={{ position: "relative", minHeight: "32px" }} w="100%" justifyContent="flex-end">
                <PurchaseWrapper left={100 - downpaymentRate / 100 - INTEREST_RATE_SPACE}>
                  <Flex alignItems="center" direction="column">
                    <Text color="gray0" size="xxs" weight="500" textWrap={false}>
                      Due today
                    </Text>
                    <Text color="gray0" size="xxs" weight="500" textWrap={false}>
                      {`${numberWithCommas(bigNumToFloat(downpaymentAmount, currency.decimal), 4)} ${currency.symbol}`}
                    </Text>
                    <Flex mt="3px">
                      <FilledChevronDown color={theme.colors.gray0} />
                    </Flex>
                  </Flex>
                </PurchaseWrapper>

                <PurchaseWrapper left={100 - purchaseAmountPercentOfTotal}>
                  <Flex alignItems="center" direction="column">
                    <Text color="gray0" size="xxs" weight="500" textWrap={false}>
                      Purchase price
                    </Text>
                    <Text color="gray0" size="xxs" weight="500" textWrap={false}>
                      {`${numberWithCommas(bigNumToFloat(totalPrice, currency.decimal), formatNumber)} ${
                        currency.symbol
                      }`}
                    </Text>
                    <Flex mt="3px">
                      <FilledChevronDown color={theme.colors.gray0} />
                    </Flex>
                  </Flex>
                </PurchaseWrapper>
              </Flex>
            )}
            <Flex direction="row" w="100%" style={{ position: "relative" }}>
              {planType === "bnpl" && (
                <>
                  <BreakdownBar color={BREAKDOWN_COLOR.dueNow} width={downpaymentRate / 100} zIndex={5} />
                  <BreakdownMask left={downpaymentRate / 100} zIndex={4}>
                    <MaskForBreakdownLoan color={theme.colors.gray10} />
                  </BreakdownMask>
                </>
              )}
              <BreakdownBar
                color={BREAKDOWN_COLOR.borrow}
                width={borrowingPercentOfTotal - INTEREST_RATE_SPACE}
                zIndex={3}
                hasGap={planType === "bnpl"}
              />
              <BreakdownMask
                left={
                  downpaymentRate / 100 + borrowingPercentOfTotal - INTEREST_RATE_SPACE - (planType === "bnpl" ? 2 : 0)
                }
                zIndex={2}
              >
                <MaskForBreakdownLoan color={theme.colors.gray10} />
              </BreakdownMask>
              <BreakdownBar
                color={BREAKDOWN_COLOR.interest}
                width={
                  100 -
                  borrowingPercentOfTotal -
                  downpaymentRate / 100 +
                  INTEREST_RATE_SPACE +
                  (planType === "bnpl" ? 4 : 2)
                }
                zIndex={1}
                hasGap
              />
            </Flex>
            <Flex direction="column" gap="7px">
              {planType === "bnpl" && (
                <Flex alignItems="center" justifyContent="space-between" w="100%">
                  <Flex alignItems="center" gap="5px">
                    <BreakdownCircle bg={BREAKDOWN_COLOR.dueNow} />
                    <Flex alignItems="center" gap="5px">
                      <Text size="xs" weight="500" color="secondary">
                        {`Down payment`}
                      </Text>
                      <Tooltip>
                        <HelpCircle height={15} width={15} color={theme.colors.secondary} />
                        <TooltipText position="top" top="-85px" right="-58px" style={{ width: "115px" }} showArrow>
                          <Flex direction="column" gap="7px">
                            <Text size="xxs" color="primary" weight="500" lineHeight={12}>
                              {`The down payment differs from today's due amount because of platform fees included in the 'Interest' bar.`}
                            </Text>
                          </Flex>
                        </TooltipText>
                      </Tooltip>
                    </Flex>
                  </Flex>
                  <Text size="xs" weight="500" color="secondary">
                    {`${numberWithCommas(bigNumToFloat(downPaymentWithoutFee, currency.decimal), formatNumber)} ${
                      currency.symbol
                    }`}
                  </Text>
                </Flex>
              )}
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Flex alignItems="center" gap="5px">
                  <BreakdownCircle bg={BREAKDOWN_COLOR.borrow} />
                  <Text size="xs" weight="500" color="secondary">
                    {`Borrow amount`}
                  </Text>
                </Flex>
                <Text size="xs" weight="500" color="secondary">
                  {`${numberWithCommas(bigNumToFloat(loaningAmount, currency.decimal), formatNumber)} ${
                    currency.symbol
                  }`}
                </Text>
              </Flex>
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Flex alignItems="center" gap="5px">
                  <BreakdownCircle bg={BREAKDOWN_COLOR.interest} />
                  <Text size="xs" weight="500" color="secondary">
                    {`Interest`}
                  </Text>
                </Flex>
                <Text size="xs" weight="500" color="secondary">
                  {`${numberWithCommas(bigNumToFloat(extraAmountUserPaying, currency.decimal), formatNumber)} ${
                    currency.symbol
                  }`}
                </Text>
              </Flex>
            </Flex>
            <DetailContainerBottom>
              <Flex justifyContent="space-between" alignItems="center">
                <Text size="xs" weight="500" color="secondary">
                  {`Total cost`}
                </Text>
                <Text size="xs" weight="500" color="secondary">
                  {` ${numberWithCommas(bigNumToFloat(totalCost, currency.decimal), formatNumber)} ${currency.symbol}`}
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text size="xs" weight="500" color="secondary">
                  {`Interest rate`}
                </Text>
                <Text size="xs" weight="500" color="secondary">
                  {(_interestRate / 100).toFixed(2)}%
                </Text>
              </Flex>
              <Flex justifyContent="space-between" alignItems="center">
                <Text size="xs" weight="500" color="secondary">
                  {`APR`}
                </Text>
                <Text size="xs" weight="500" color="secondary">
                  {apr.toFixed(2)}%
                </Text>
              </Flex>
            </DetailContainerBottom>
          </DetailContainer>
        </Flex>
      </Card>
    </Flex>
  );
};

export const DetailContainer = styled(Flex)<{ open: boolean }>`
  overflow: hidden;
  height: fit-content;
  max-height: ${({ open }) => (open ? "270px" : "0px")};
  padding-top: ${({ open }) => (open ? "0.5rem" : "0")};
  margin-bottom: ${({ open }) => (open ? "0px" : "-7px")};
  transition: 0.2s ease-in-out;
  position: relative;
`;
export const DetailContainerBottom = styled(Flex)`
  border-top: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  flex-direction: column;
  padding-top: 9px;
  gap: 7px;
`;
export const BreakdownCircle = styled.div<{ bg: string }>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${({ bg }) => bg};
`;
export const ExpandButton = styled.div`
  cursor: pointer;
  height: 14px;
  transition: 0.2s ease-in-out;
  :hover {
    opacity: 0.8;
  }
`;
export const PurchaseWrapper = styled(Flex)<{ left: number }>`
  position: absolute;
  margin-right: ${({ left }) => `${left}%`};
  justify-content: flex-end;
`;
export const StepperContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-color: ${({ theme }) => theme.colors.gray20};
  border-left: none;
  border-right: none;
  padding: 9px 0;
  margin-top: 3px;
  gap: 8px;
`;

export const BreakdownBar = styled.div<{
  width: number;
  color: string;
  zIndex: number;
  hasGap?: boolean;
}>`
  height: 15px;
  border-radius: 100px;
  width: ${({ width }) => `${width}%`};
  background: ${({ color }) => color};
  z-index: ${({ zIndex }) => zIndex};
  margin-left: ${({ hasGap }) => (hasGap ? "-2%" : "0")};
`;

export const BreakdownMask = styled.div<{ left: number; zIndex: number }>`
  position: absolute;
  top: -1px;
  left: ${({ left }) => `${left - 4}%`};
  z-index: ${({ zIndex }) => zIndex};
`;
